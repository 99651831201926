import { cloneDeep, isEmpty, isNil, join } from 'lodash';

import User from '@/models/User';
import { difference } from '@/utils/diffTools';
import { userService } from '@/services';
import utils from '@/utils/utils';

const alertSaved = {
  id: 'user-saved',
  icon: 'check',
  type: 'valid',
  message: 'notifications.update.success',
};

const alertFailed = {
  id: 'user-saved',
  icon: 'close',
  type: 'error',
  message: 'notifications.update.error',
};

const alertRemoved = {
  id: 'user-removed',
  icon: 'check',
  type: 'valid',
  message: 'notifications.delete.success',
};

const alertRemovedFailed = {
  id: 'program-removed',
  icon: 'close',
  type: 'error',
  message: 'notifications.delete.error',
};

const alertNewslettersFailed = {
  id: 'user-saved',
  icon: 'close',
  type: 'error',
  message: 'notifications.newsletters.error',
};

const state = {
  users: [],
  all: [],
  currentPage: 1,
  currentSearch: '',
  currentSort: '',
  currentNewUser: {},
  savedUser: {},
  errors: {},
  loading: false,
};

const actions = {
  refresh({ state, dispatch }) {
    if (isNil(state.savedUser.id)) {
      return Promise.resolve();
    }
    const config = {
      id: state.savedUser.id,
      refresh: true,
    };
    return dispatch('get', config).then(() => {
      return Promise.resolve();
    });
  },

  getAll({ commit, state }, params) {
    commit('getAllRequest');

    let page = params?.page ?? 1;
    let sort = params?.sort ?? '';
    let facet = params?.facet ?? '';
    let query = isNil(page)
      ? state.currentSearch
      : params?.query && params.query != ''
        ? '&query=' + params.query
        : '';

    sort = isNil(sort) ? state.currentSort : sort;

    if (query != '') {
      commit('setPage', page);
      commit('setSearch', query);
      commit('setSort', sort);
    }
    let queryString = utils.makeQueryString(facet, sort, page);

    return userService
      .searchAll(queryString + query)
      .then(
        (users) => {
          commit('getAllSuccess', users);
          return Promise.resolve(users);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        commit('getAllFailure');
        return Promise.reject(error);
      });
  },

  get({ commit, dispatch }, { id, refresh = false, isSubscribed = true }) {
    commit('getRequest');
    if (refresh) {
      dispatch('displayLoader', true, {
        root: true,
      });
    }
    return userService
      .get(id)
      .then(
        async (user) => {
          commit('getSuccess', user);
          if (isSubscribed) {
            await dispatch('newsletters/isSubscribed', user.email, {
              root: true,
            }).catch(() => {
              dispatch('displayAlert', alertNewslettersFailed, { root: true });
            });
          } if (refresh) {
            dispatch('displayLoader', false, {
              root: true,
            });
          }
          return Promise.resolve(user);
        },
        (error) => {
          commit('getFailure', error);
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        dispatch('displayAlert', error.message, { root: true });
        return Promise.reject(error);
      });
  },

  create({ commit }, user) {
    commit('getRequest');
    return userService.create(user).then(
      (users) => {
        commit('getCreateSuccess', users);
        return Promise.resolve(users);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },

  update({ commit, dispatch }) {
    let userId = state.savedUser.id;
    const diffUser = difference(state.currentNewUser, state.savedUser);

    if (isNil(diffUser) || isEmpty(diffUser)) {
      return Promise.resolve();
    }

    return userService.update(diffUser, userId).then(
      (user) => {
        commit('updateSuccess');
        dispatch('displayAlert', alertSaved, { root: true });
        return Promise.resolve(user);
      },
      (error) => {
        commit('getFailure', error);

        if (error?.errors?.email?.[0] === 'unique') {
          dispatch(
            'displayAlert',
            {
              ...alertFailed,
              message: 'error.user.email.update',
            },
            { root: true }
          );
        } else {
          dispatch('displayAlert', alertFailed, { root: true });
        }
        return Promise.reject(error);
      }
    );
  },

  deleteOne({ commit, dispatch }, itemId) {
    commit('deleteRequest');
    return userService.deleteOne(itemId).then(
      (response) => {
        commit('deleteSuccess');
        dispatch('displayAlert', alertRemoved, { root: true });
        return Promise.resolve();
      },
      (err) => {
        dispatch('displayAlert', alertRemovedFailed, { root: true });
        return Promise.reject(err);
      }
    );
  },

  // import users
  importUsers({ commit, dispatch, rootState }, payload) {
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    const userId = rootState.auth?.authUser?.id;
    const _payload = { ...payload, user_id: userId};
    return userService.importUsers(_payload, personalToken).then(
      () => {
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  // export users
  exportUsers({ commit, rootState, dispatch }) {
    const payload = {
      user_id: rootState.auth?.authUser?.id,    
    };
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    return userService.exportUsers(payload, personalToken).then(
      () => {
        const alert = {
          id: 'export-users-request-accepted',
          icon: 'check',
          type: 'valid',
          message: 'users.search.export.alert.accepted',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'export-users-request-rejected',
          icon: 'close',
          type: 'error',
          message: 'users.search.export.alert.rejected',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  reset({ commit }) {
    commit('resetOne');
    commit('resetAll');
    return true;
  },

  resetOne({ commit }) {
    commit('resetOne');
    return true;
  },

  resetAll({ commit }) {
    commit('resetAll');
    return true;
  },

  resetSearch({ commit }) {
    commit('resetSearch');
    return true;
  },

  setNewPicture({ commit }, url) {
    commit('setNewPicture', url);
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },

  setPage(state, page) {
    state.currentPage = page;
  },

  setSearch(state, search) {
    state.currentSearch = search;
  },

  setSort(state, sort) {
    state.currentSort = sort;
  },

  setPassword(state, newPassword) {
    state.currentNewUser.password = newPassword;
    state.currentNewUser.password_confirmation = newPassword;
  },

  setNewPicture(state, url) {
    state.currentNewUser.setNewPicture(url);
  },

  resetSearch(state) {
    state.currentSearch = '';
  },
  //---------------------------- ALL users -----------------

  resetAll(state) {
    state.all = [];
    state.errors = {};
    state.currentPage = 1;
    state.currentSearch = '';
  },

  getAllRequest(state) {
    state.loading = true;
  },

  getAllSuccess(state, users) {
    let retval = cloneDeep(users);
    retval.data = retval.data.map(function (element) {
      let retval = new User(element);
      return retval;
    });

    if (!isNil(retval.meta.pagination.current_page)) {
      state.currentPage = retval.meta.pagination.current_page;
    }

    state.all = retval;
    state.loading = false;
  },

  getAllFailure(state, errors = null) {
    state.all = [];
    state.errors = errors;
    state.loading = false;
  },

  //---------------------------- ONE user -----------------

  resetOne(state) {
    state.currentNewUser = {};
    state.savedUser = {};
    state.errors = {};
  },

  getRequest(state) {
    state.loading = true;
    state.currentNewUser = {};
    state.savedUser = {};
  },

  getCreateSuccess(state, user) {
    state.currentNewUser = user;
    state.loading = false;
  },

  getSuccess(state, user) {
    const newUser = new User(user);
    state.savedUser = newUser;
    state.currentNewUser = cloneDeep(newUser);
    state.loading = false;
  },

  updateSuccess(state) {
    state.loading = false;
  },

  getFailure(state, errors) {
    var retval = [];
    for (var element in errors) {
      retval[element] = errors[join(element)];
      // for (const element of Array.from(errors)) {
    }
    state.errors = retval;
    state.loading = false;
  },
  deleteRequest(state) {
    state.loading = true;
  },

  deleteSuccess(state) {
    state.loading = false;
    state.currentNewUser = {};
    state.savedUser = {};
  },
};

const getters = {
  shouldSaved: (state) => {
    const diffUser = difference(state.currentNewUser, state.savedUser);

    if (isNil(diffUser) || isEmpty(diffUser)) {
      return false;
    }

    return true;
  },
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
