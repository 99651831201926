<template>
  <div class="flex space-x-10">
    <div
      class="w-[350px] border-r border-solid border-skin-ui bg-skin-ui shadow-lg shadow-skin-ui fixed left-0 top-0 bottom-0"
    >
      <div
        class="flex flex-col absolute left-[72px] top-[60px] bottom-0 w-[278px] h-full overflow-y-auto"
      >
        <div class="p-4">
          <input
            v-model="search"
            class="border leading-none h-6 w-full py-2 text-xxs text-ui-900 border-solid border-skin-ui bg-skin-ui px-2 rounded-lg placeholder:text-ui-900 placeholder:text-xxs hover:border-skin-ui-hover focus:outline-none"
            type="search"
            name="search"
            placeholder="Recherche..."
          />
        </div>
        <div class="border-b border-solid border-skin-ui"></div>
        <div class="flex-1" v-if="componentsFiltered.length">
          <div
            v-for="component in componentsFiltered"
            :key="component.id"
            @click="updatePage(component)"
            class="p-4 border-b border-r border-solid border-skin-ui bg-skin-ui hover:bg-skin-ui-hover transition-all duration-200 cursor-pointer"
          >
            <p class="text-xs text-ui-900 truncate font-semibold">
              {{ component.__name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="itemRender"
      class="flex flex-col space-y-10 absolute left-[350px] right-10 flex-1 mt-10"
    >
      <div class="flex flex-col space-y-2">
        <h1 class="font-semibold text-skin-accent text-xl">
          {{ itemRender.name }}
        </h1>
        <div class="flex items-center space-x-4">
          <p v-if="itemRender.__file" class="text-sm text-ui-500">
            {{
              itemRender.__file
                .substring(itemRender.__file.indexOf('components'))
                .replaceAll('/', ' > ')
            }}
          </p>
          <button
            type="button"
            class="border-solid border border-ui-900 hover:border-ui-600 focus:outline-none rounded-ui-component p-2 inline-flex items-center justify-center"
          >
            <svg
              class="w-4 h-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 7H7V5H13V7Z" fill="currentColor" />
              <path d="M13 11H7V9H13V11Z" fill="currentColor" />
              <path d="M7 15H13V13H7V15Z" fill="currentColor" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                class="fill-ui-900"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="bg-skin-ui rounded-ui-component p-ui-component">
        <h1 class="font-semibold text-ui-900 uppercase">Composant</h1>
        <p class="text-ui-900 text-opacity-50 text-xs w-96 mt-2">
          la représentation par défaut du composant
          {{ itemRender.__name }}
        </p>

        <div class="mt-8 bg-skin-background p-2">
          <component :is="itemRender"></component>
        </div>
      </div>
      <div class="bg-skin-ui rounded-ui-component p-ui-component">
        <h1 class="font-semibold text-ui-900 uppercase">props</h1>
        <p class="text-ui-900 text-opacity-50 text-xs w-96 mt-2">
          la liste des props disponibles pour le composant
          {{ itemRender.__name }}
        </p>
        <table
          v-if="itemRender.props && Object.entries(itemRender.props).length"
          class="mt-8 min-w-full leading-normal"
        >
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-skin-ui bg-ui-50 text-left text-xs font-semibold text-ui-900 uppercase tracking-wider"
              >
                Nom
              </th>
              <th
                class="px-5 py-3 border-b-2 border-skin-ui bg-ui-50 text-left text-xs font-semibold text-ui-900 uppercase tracking-wider"
              >
                Type
              </th>
              <th
                class="px-5 py-3 border-b-2 border-skin-ui bg-ui-50 text-left text-xs font-semibold text-ui-900 uppercase tracking-wider"
              >
                Valeur par défaut
              </th>
            </tr>
          </thead>
          <tbody v-if="Object.entries(itemRender.props).length">
            <tr
              v-for="item in Object.entries(itemRender.props).map(([k, v]) => [
                k,
                v,
              ])"
              :key="item.id"
            >
              <td
                class="px-5 py-5 border-b border-solid font-semibold border-gray-200 bg-white text-sm"
              >
                {{ item[0] }}
              </td>
              <td
                class="px-5 py-5 border-b border-solid border-gray-200 bg-white text-xs uppercase"
              >
                <div
                  class="px-2 py-1 bg-black rounded-full text-xxs font-bold text-white inline"
                >
                  {{ typeof item[1].default }}
                </div>
              </td>
              <td
                class="px-5 py-5 border-b border-solid border-gray-200 bg-white text-sm"
              >
                <div
                  class="border border-red-500 text-red-500 text-xs px-2 py-1 inline rounded-md border-dashed"
                >
                  {{ item[1].default }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-else
          class="flex flex-col justify-center items-center w-full my-16"
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z"
              fill="currentColor"
            />
            <path
              d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
              fill="currentColor"
            />
          </svg>
          <p class="mt-4">Le composant n'est pas équipé de props.</p>
        </div>
      </div>
      <div class="bg-skin-ui rounded-ui-component p-ui-component w-full">
        <h1 class="font-semibold text-ui-900 uppercase">events</h1>
        <p class="text-ui-900 text-opacity-50 text-xs w-96 mt-2 mb-8">
          la liste des events disponibles pour le composant
          {{ itemRender.__name }}
        </p>
        <tables v-if="itemRender.emits" class="mt-8 min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-skin-ui bg-ui-50 text-left text-xs font-semibold text-ui-900 uppercase tracking-wider"
              >
                Nom
              </th>
            </tr>
          </thead>
          <tbody v-if="itemRender.emits.length">
            <tr v-for="item in itemRender.emits" :key="item.id">
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="font-semibold text-ui-900">{{ item }}</p>
              </td>
            </tr>
          </tbody>
        </tables>
        <div
          v-else
          class="flex flex-col justify-center items-center w-full my-16"
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z"
              fill="currentColor"
            />
            <path
              d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
              fill="currentColor"
            />
          </svg>
          <p class="mt-4">Le composant n'est pas équipé d'events.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesignSystemView',
  data() {
    return {
      search: null,
      components: [],
      itemRender: null,
    };
  },
  computed: {
    componentsFiltered() {
      let filtered = [];

      if (this.components.length && this.search) {
        return this.components.filter((x) =>
          x.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.components.filter((x) => x);
      }
    },
    itemRender: {
      get() {
        return this.itemRender;
      },
      set(newValue) {
        this.itemRender = newValue;
      },
    },
  },
  mounted() {
    this.getComponents();
  },
  methods: {
    getComponents() {
      const modules = import.meta.glob('../components-v2/**/*.vue');
      for (const path in modules) {
        modules[path]().then((mod) => {
          console.log(mod);
          if (mod.default && mod.default.__name) {
            this.components.push(mod.default);
          }
        });
      }
    },
    updatePage(newComponent) {
      this.itemRender = newComponent;
    },
  },
};
</script>
