import {
  buttonColorClasses,
  dashedButtonColorClasses,
  fontSizeClasses,
  fontWeightClasses,
  inputCaretSize,
  inputFieldAddonColorClasses,
  inputFieldButtonColorClasses,
  inputIconSize,
  justifyContentClasses,
  nudeButtonColorClasses,
  outlinedButtonColorClasses,
  sizeClasses,
  textAlignClasses,
  textDecorationClasses,
  themeClasses,
} from '@/utils/styles/styles.js';
import { cloneDeep } from 'lodash';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useUiElement(props = {}) {
  const route = useRoute();
  const uiElementVariant = computed(() => {
    return props.variant ?? null;
  });

  const isDashed = computed(() => {
    return props.dashed || props.buttonType === 'dashed';
  });

  const getUiElementOptionClass = (
    multiple = false,
    active = false,
    selected = false,
    disabled = false,
    title = false
  ) => {
    return [
      'px-4 py-3 md:py-1 first:md:mt-2 last:md:mb-2 cursor-pointer w-full focus-visible:bg-skin-ui-hover focus-visible:weight-bold hover:bg-skin-ui-hover',
      active && 'bg-ui-100 weight-bold',
      props.buttonType === 'outline' && 'justify-center text-center',
      !multiple && selected && 'font-bold',
      title && 'font-light italic text-skin-muted',
      disabled && 'cursor-not-allowed',
    ];
  };

  const uiElementTheme = computed(() => {
    return (
      (props.error && 'danger') ||
      (!(props.withTheme === false) &&
        (props.buttonType ?? route?.matched[0]?.meta?.theme ?? 'primary'))
    );
  });

  const uiElementTextStylesClass = computed(() => {
    if (props.styles) {
      return [
        props.styles.bold && 'font-bold',
        props.styles.italic && 'italic',
        props.styles.fontSize &&
        fontSizeClasses[props.styles.fontSize.split('-')[2]],
      ];
    }
    return [];
  });

  const uiElementSizeClass = computed(() => {
    return props.size && sizeClasses[props.size];
  });

  const uiElementThemeClass = computed(() => {
    return themeClasses[uiElementTheme.value];
  });

  const uiElementFieldClass = computed(() => {
    return [
      'transition transition-colors ease-in-out otto-ui-field disabled:bg-skin-action-disabled bg-skin-action-background group-[.input-group]:last:-ml-px group-[.input-group]:first:-mr-px',
      !props.nude && 'peer-focus:ring-skin-ui-hover peer-focus:ring-4',
      ['tab', 'nude'].includes(props.buttonType) && 'border-transparent',
      isDashed.value ? 'border-dashed' : 'border-solid',
      uiElementThemeClass,
      !['tab', 'nude'].includes(props.buttonType) &&
      (isDashed.value
        ? dashedButtonColorClasses
        : inputFieldButtonColorClasses),
      props.square && 'text-center aspect-square !flex-0 !grow-0',
      props.error
        ? '!bg-danger-50 !border-skin-action-accent'
        : 'bg-skin-action-background',
    ];
  });

  const uiElementAddOnClass = computed(() => {
    return inputFieldAddonColorClasses;
  });

  const uiElementIconSize = computed(() => {
    return props.size && inputIconSize[props.size];
  });

  const uiElementLabelWeight = computed(() => {
    return props.weight ?? 'normal';
  });

  const uiElementTitle = computed(() => {
    return props.title ? props.label ?? '' : '';
  });

  const uiElementCaretSize = computed(() => {
    return inputCaretSize[props.size];
  });

  const uiElementCharacterCounter = computed(() => {
    if (
      typeof props.maxlength !== 'undefined' &&
      typeof props.modelValue !== 'undefined'
    ) {
      const content = cloneDeep(props.modelValue);
      // rich text editor initialize textarea with '<div><br></div>' so it needs to be ignored by character counter
      const clearedContent = content
        ? content.replace('<div><br></div>', '')
        : '';
      return (
        (clearedContent === null ? 0 : clearedContent.length) +
        '/' +
        props.maxlength
      );
    } else {
      return '';
    }
  });

  const uiElementCharacterCounterIsShown = computed(() => {
    return props.showCounter === 'dynamic'
      ? props.modelValue && props.modelValue.length > props.maxlength
      : props.showCounter === true;
  });

  const uiElementCharacterCounterClass = computed(() => {
    if (uiElementCharacterCounter.value !== '' && props.modelValue) {
      return props.modelValue.length > props.maxlength
        ? 'text-danger-500'
        : props.modelValue.length / props.maxlength > 0.75
          ? 'text-warning-500'
          : 'text-skin-muted';
    }
    return 'text-skin-muted';
  });

  const uiElementTagSize = computed(() => {
    switch (props.size) {
      case 'sm':
        return 'xxs';
      case 'md':
        return 'xs';
      case 'lg':
        return 'sm';
      case 'xl':
        return 'sm';
      default:
        return 'xs';
    }
  });

  const uiElementButtonClass = computed(() => {
    return [
      props.textAlign && textAlignClasses[props.textAlign],
      props.square && 'aspect-square px-0 flex-0',
      props.fullWidth && 'w-full',
      props.link && '!px-0',
      props.link && props.withTheme && 'text-skin-accent',
      props.justify && justifyContentClasses[props.justify],
      isDashed.value && dashedButtonColorClasses,
      isDashed.value ? 'border-dashed' : 'border-solid',
      props.fontWeight && fontWeightClasses[props.fontWeight],
      props.buttonStyle && `font-style-${props.buttonStyle}`,
      props.decoration && textDecorationClasses[props.decoration],
      props.editable && 'btn-editable',
      props.rounded && 'rounded-full',
      props.field && 'disabled:bg-skin-action-disabled',
      props.underline && 'underline',
      props.dashed && 'hover:underline',
      props.removable && 'btn-removable hover:ring-skin-base',
      props.type && props.type === 'tab' && 'first:rounded-b-none',
      props.nude && props.hoverable && 'hover:bg-skin-ui-hover',
      props.nude || props.link
        ? 'border-transparent'
        : 'border-solid peer-focus:ring-skin-ui-hover peer-focus:ring-4 focus:ring-skin-ui-hover focus:ring-4',
      !props.dashed && uiElementThemeClass,
      props.outline && outlinedButtonColorClasses,
      (props.nude || props.link) && nudeButtonColorClasses,
      !props.nude && props.field && inputFieldButtonColorClasses,
      !(
        props.field ||
        props.link ||
        props.nude ||
        props.dashed ||
        props.outline ||
        !props.withTheme
      ) && buttonColorClasses,
      props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      props.fontSize && fontSizeClasses[props.fontSize],
    ];
  });

  return {
    getUiElementOptionClass,
    uiElementIconSize,
    uiElementLabelWeight,
    uiElementFieldClass,
    uiElementVariant,
    uiElementTheme,
    uiElementThemeClass,
    uiElementTitle,
    uiElementSizeClass,
    uiElementTextStylesClass,
    uiElementAddOnClass,
    uiElementButtonClass,
    uiElementCaretSize,
    uiElementTagSize,
    uiElementCharacterCounter,
    uiElementCharacterCounterIsShown,
    uiElementCharacterCounterClass,
  };
}
