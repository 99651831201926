<template>

  <input
    ref="formInputField"
    v-maska="mask"
    :disabled="disabled"
    :readonly="readonly"
    class="otto-base-ui-component peer leading-[22px] transition-all grow read-only:bg-gray-100 read-only:hover:border-gray-300"
    :class="[
      {
        'tabular-nums': inputType === 'number',
      },
      ...uiElementFieldClass,
      ...uiElementTextStylesClass,
      uiElementSizeClass,
      uiElementThemeClass,
      square && 'max-w-[2rem] max-h-[2rem] !px-0',
      !widthAuto && '!w-auto',
      !square && 'max-w-full',
    ]"
    :placeholder="placeholder"
    :value="modelValue"
    :required="required"
    :minlength="minlength > 0 ? minlength : 0"
    :maxlength="maxlength > 0 ? maxlength : 1e6"
    :min="min"
    :max="max"
    :step="step"
    v-bind="$attrs"
    :type="inputType"
    @input="update($event.target.value)"
    @keyup="handleKeyup"
    @focus="focus($event)"
    @blur="update($event.target.value, true)"
  />

  <div
    v-if="displayCounter && maxlength > 0"
    class="text-xs ml-auto pr-2"
    :class="uiElementCharacterCounterClass"
  >
    {{ uiElementCharacterCounter }}
  </div>

  <div
    v-if="isValid"
    class="px-4 inline-flex items-center min-w-fit border absolute right-0"
    :class="[
      {
        'rounded-r': !($slots['footer'] && $slots['button']),
      },
    ]"
  >
    <Icon icon="check" color="success" />
  </div>
  <div
    v-if="passwordInputIconAfter && disclosable"
    class="px-4 inline-flex items-center min-w-fit border absolute right-0"
    @click="toggleReveal()"
  >
    <Icon :icon="passwordInputIconAfter" color="black" size="md" />
  </div>
</template>
<script setup>
import { directive as VueInputAutowidth } from 'vue-input-autowidth';
import { computed, onMounted, ref, watch } from 'vue';
import { useAttrs } from 'vue';
import { useUiElement } from '@/composables/useUiElement.js';
import Icon from '@/components/icon/Icon.vue';
const attrs = useAttrs();

const emit = defineEmits([
  'focus',
  'blur',
  'reset',
  'update:modelValue',
  'keyup',
  'enter',
]);

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: [String, Number],
  readonly: Boolean,
  required: Boolean,
  optional: Boolean,
  size: {
    type: String,
    default: 'md',
  },
  autofocus: Boolean,
  ghostLabel: Boolean,
  minlength: { type: Number, default: null },
  maxlength: { type: Number, default: null },
  displayCounter: { type: Boolean, default: true, required: false },
  disclosable: { type: Boolean, default: false, required: false },
  square: { type: Boolean, default: false, required: false },
  step: [String, Number],
  width: [String, Number],
  error: [String, Function, Boolean],
  displayError: { type: Boolean, default: true, required: false },
  valid: {
    type: Boolean,
    default: false,
  },
  loading: Boolean,
  widthAuto: { type: Boolean, default: false, required: false },
  placeholder: String,
  withTextOptions: Boolean,
  styles: Object,
  textOptionsFull: {
    type: Boolean,
    default: true,
  },
  mask: { type: [String, Array], default: null },
  hideCrossOnError: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    required: false,
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
});

let hasFocus = ref(false);

const formInputField = ref(null);

const disclosedPassword = ref(false);

const hasError = computed(() => {
  return props.error;
});
const isValid = computed(() => {
  return props.valid;
});
const inputType = computed(() => {
  return disclosedPassword.value
    ? attrs.type === 'password'
      ? 'text'
      : attrs.type ?? 'text'
    : attrs.type ?? 'text';
});

const focused = computed(() => {
  return props.autofocus;
});

/**
 * Element get blur
 * @returns void
 * @author Jody JUSTINE <jjustine@vodfactory.com>
 **/
const blur = () => {
  hasFocus = false;
  emit('blur');
};
/**
 * Element get focus
 * @returns void
 * @author Jody JUSTINE <jjustine@vodfactory.com>
 **/
const focus = ($event) => {
  hasFocus = true;
  emit('focus', $event);
};
const handleKeyup = ($event) => {
  if ($event.keyCode === 13) {
    emit('enter', $event.target.value);
  }
  emit('keyup', $event.target.value);
};
const update = (value, emitBlur = false) => {
  emit('update:modelValue', value);
  if (emitBlur) {
    emit('blur', value);
  }
};
const resetInput = () => {
  emit('reset');
};

const passwordInputIconAfter = computed(() => {
  return disclosedPassword.value ? 'password-eye-slash' : 'password-eye';
});

const toggleReveal = () => {
  disclosedPassword.value = !disclosedPassword.value;
};

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      formInputField.value?.focus();
    }, 200);
  }
});

const {
  uiElementSizeClass,
  uiElementFieldClass,
  uiElementThemeClass,
  uiElementCharacterCounter,
  uiElementCharacterCounterClass,
  uiElementTextStylesClass,
  uiElementAddOnClass,
} = useUiElement(props);

watch(focused, () => {
  if (focused.value) {
    setTimeout(() => {
      formInputField.value?.focus();
    });
  }
});
</script>

<style lang="scss">
//@import 'form_input';
input:-internal-autofill-selected {
  background: transparent;
}
</style>
