import { devicesType } from '@/data/devices.js';
import { picturesNames } from '@/data/pictures';
import { Pages } from '@/data/adsConfigs.js';

const allDevices = [
  devicesType.DESKTOP,
  devicesType.TABLET,
  devicesType.MOBILE,
  devicesType.ORANGE,
  devicesType.FREE,
  devicesType.APPLE_TV,
  devicesType.ANDROID_TV,
  devicesType.SAMSUNG_TV
];

const SETTINGS = 'AnimationBlockSettings';
const CONTENTS = 'AnimationBlockContent';

/**
 * Array of block types used in animations.
 * @typedef {Object} BlockType
 * @property {string} id - The ID of the block type.
 * @property {string} title - The title of the block type.
 * @property {string} desc - The description of the block type.
 * @property {string} modalSettingsTitle - The title of the modal settings for the block type.
 * @property {Object} components - The components used in the block type.
 * @property {string} components.settings - The settings component used in the block type.
 * @property {string} components.contents - The contents component used in the block type.
 * @property {string} components.preview - The preview component used in the block type.
 * @property {Object} format - The format of the block type.
 * @property {Array} templateTypeNames - The names of the template types for the block type.
 * @property {string} image - The URL of the image for the block type.
 * @property {boolean} wall - Indicates if the block type is a wall.
 * @property {Object} defaultSettings - The default settings for the block type.
 * @property {Object} defaultSettings.show - The default settings to show for the block type.
 * @property {boolean} defaultSettings.show.carousels - Indicates if carousels are shown for the block type.
 * @property {boolean} defaultSettings.show.infos - Indicates if infos are shown for the block type.
 * @property {boolean} defaultSettings.show.colors - Indicates if colors are shown for the block type.
 * @property {boolean} defaultSettings.show.templates - Indicates if templates are shown for the block type.
 * @property {boolean} defaultSettings.show.spacings - Indicates if spacings are shown for the block type.
 * @property {boolean} defaultSettings.show.types - Indicates if types are shown for the block type.
 * @property {string} defaultSettings.open - The default settings to open for the block type.
 * @property {Array} categories - The categories of the block type.
 * @property {Array} availableDevices - The available devices for the block type.
 * @property {boolean} isMultiDeviceAtOnce - Indicates if multiple devices can be selected at once for the block type.
 * @property {boolean} isSingleSelect - Specify that only one element is selected.
 * @property {boolean} isDifferenceToChild - Indicates if there is a difference to the child block.
 * @property {Object} slidesPerView - The number of slides per view for the block type.
 * @property {string} placeholder - The URL of the placeholder image for the block type.
 * @property {number} ratio - The ratio of the block type.
 * @property {Array} templatesOptions - The options for the templates of the block type.
 * @property {boolean} canEnable - Indicates if the block type can be enabled.
 * @property {boolean} unique - Indicates if the block type is unique.
 */


const blockTypes = [
  {
    id: 'ad-row-banners',
    title: 'animation.block.types.adBanners.title',
    desc: 'animation.block.types.adBanners.description',
    modalSettingsTitle: 'animation.block.types.adBanners.settings.title',
    components: {
      settings: SETTINGS,
      contents: null,
      preview: 'AnimationPreviewAdBanner',
    },
    format: picturesNames.PICTURE_TYPE_AVOD_BANNERS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      `../images/animation/templates/a_banner.png`,
      import.meta.url
    ).href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: false,
        colors: true,
        templates: false,
        spacings: false,
        types: false
      },
      open: 'block-color'
    },
    categories: ['ad'],
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'ad-row-paves',
    title: 'animation.block.types.adPaves.title',
    desc: 'animation.block.types.adPaves.description',
    modalSettingsTitle: 'animation.block.types.adPaves.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewAdBlock',
    },
    format: picturesNames.PICTURE_TYPE_AVOD_BLOCKS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(`../images/animation/templates/a_block.png`, import.meta.url)
      .href,
    wall: true,
    categories: ['ad'],
    defaultSettings: {
      show: {
        carousels: false,
        infos: false,
        colors: true,
        templates: false,
        spacings: false,
        types: false
      },
      open: 'block-color'
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'fullscreen',
    title: 'animation.block.types.fullscreen.title',
    desc: 'animation.block.types.fullscreen.description',
    modalSettingsTitle: 'animation.block.types.fullscreen.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewCarousel',
    },
    format: picturesNames.PICTURE_TYPE_FULLSCREENS,
    templateTypeNames: [
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/fullscreen.png',
      import.meta.url
    ).href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: true,
        infos: false,
        colors: false,
        templates: false,
        spacings: false,
        types: false
      },
      open: 'aspect-section'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
    isSingleSelect: true,
    isDifferenceToChild: true,
  },
  {
    id: 'carousel',
    title: 'animation.block.types.carousel.title',
    desc: 'animation.block.types.carousel.description',
    modalSettingsTitle: 'animation.block.types.carousel.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewCarousel',
    },
    format: picturesNames.PICTURE_TYPE_BACKDROPS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/carousel.jpg',
      import.meta.url
    ).href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: true,
        infos: false,
        colors: false,
        templates: false,
        spacings: false,
        types: false
      },
      open: 'height-section'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
    isDifferenceToChild: true,
  },
  {
    id: 'video',
    title: 'animation.block.types.video.title',
    desc: 'animation.block.types.video.description',
    modalSettingsTitle: 'animation.block.types.video.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewVideo',
    },
    format: picturesNames.PICTURE_TYPE_BACKDROPS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL('../images/animation/templates/video.png', import.meta.url)
      .href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: true,
        infos: false,
        colors: false,
        templates: false,
        spacings: false,
        types: false
      },
      open: 'height-section'
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
    isSingleSelect: true,
  },
  {
    id: 'argument',
    title: 'animation.block.types.argument.title',
    desc: 'animation.block.types.argument.description',
    modalSettingsTitle: 'animation.block.types.argument.settings.title',
    components: {
      settings: SETTINGS,
      contents: 'AnimationFormArgumentsContents',
      preview: 'AnimationPreviewArguments',
    },
    format: picturesNames.PICTURE_TYPE_ROUNDEDS,
    templateTypeNames: [
      Pages.LANDING,
      Pages.PAGE,
      Pages.ACCOUNT
    ],
    image: new URL(
      '../images/animation/templates/arguments.jpg',
      import.meta.url
    ).href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'plan',
    title: 'animation.block.types.plan.title',
    desc: 'animation.block.types.plan.description',
    modalSettingsTitle: 'animation.block.types.plan.settings.title',
    components: {
      settings: SETTINGS,
      contents: 'AnimationFormPlansContents',
      preview: 'AnimationPreviewPlans',
    },
    slidesPerView: {
      mobile: 2,
      tablet: 2,
      desktop: 3,
      orange: 3,
      free: 3,
      apple_tv: 3,
      android_tv: 3,
      samsung_tv: 3,
    },
    format: picturesNames.PICTURE_TYPE_THUMBNAILS,
    templateTypeNames: [
      Pages.LANDING,
      Pages.PAGE,
      Pages.ACCOUNT
    ],
    image: new URL('../images/animation/templates/plans.jpg', import.meta.url)
      .href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: null
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: false,
  },
  {
    id: 'multiscreen',
    title: 'animation.block.types.multiscreen.title',
    desc: 'animation.block.types.multiscreen.description',
    modalSettingsTitle: 'animation.block.types.multiscreen.settings.title',
    components: {
      settings: SETTINGS,
      contents: null,
      preview: 'AnimationPreviewMultiScreens',
    },
    templatesOptions: [
      {
        id: 'left',
        desc: 'animation.block.sections.template.options.pictureLeft',
        image: new URL(
          `../images/animation/form/multiscreen/multiscreen-img-left.jpg`,
          import.meta.url
        ).href,
      },
      {
        id: 'right',
        desc: 'animation.block.sections.template.options.pictureRight',
        image: new URL(
          `../images/animation/form/multiscreen/multiscreen-img-right.jpg`,
          import.meta.url
        ).href,
      },
    ],
    format: picturesNames.PICTURE_TYPE_THUMBNAILS,
    templateTypeNames: [
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/multiscreen.jpg',
      import.meta.url
    ).href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: true,
        spacings: true,
        types: false
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-full-banners',
    title: 'animation.block.types.banner.title',
    desc: 'animation.block.types.banner.description',
    modalSettingsTitle: 'animation.block.types.banner.settings.title',
    components: {
      settings: null,
      contents: 'AnimationBlockContent',
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-full-banners.png',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 1,
      tablet: 1,
      desktop: 1,
      orange: 1,
      free: 1,
      apple_tv: 1,
      android_tv: 1,
      samsung_tv: 1,
    },
    format: picturesNames.PICTURE_TYPE_BANNERS,
    placeholder: new URL(
      '../images/image-banners-placeholder.png',
      import.meta.url
    ).href,
    ratio: 6.11,
    wall: true,
    defaultSettings: {
      show: null,
      open: null
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
    isSingleSelect: true,
  },
  {
    id: 'banner',
    title: 'animation.block.types.text.title',
    desc: 'animation.block.types.text.description',
    modalSettingsTitle: 'animation.block.types.text.settings.title',
    components: {
      settings: SETTINGS,
      contents: null,
      preview: 'AnimationPreviewBanner',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE,
      Pages.ACCOUNT,
      Pages.CATALOG,
      Pages.STATICPAGES
    ],
    image: new URL('../images/animation/templates/text.png', import.meta.url)
      .href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: null
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-posters',
    title: 'animation.block.types.posters.title',
    desc: 'animation.block.types.posters.description',
    modalSettingsTitle: 'animation.block.types.posters.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-posters.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 3,
      tablet: 5,
      desktop: 7,
      orange: 11,
      free: 11,
      apple_tv: 11,
      android_tv: 11,
      samsung_tv: 11,
    },
    format: picturesNames.PICTURE_TYPE_POSTERS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 0.6667,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-thumbnails',
    title: 'animation.block.types.thumbnails.title',
    desc: 'animation.block.types.thumbnails.description',
    modalSettingsTitle: 'animation.block.types.thumbnails.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-thumbnails.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 2,
      tablet: 4,
      desktop: 5,
      orange: 8,
      free: 8,
      apple_tv: 8,
      android_tv: 8,
      samsung_tv: 8,
    },
    format: picturesNames.PICTURE_TYPE_THUMBNAILS,
    placeholder: new URL(
      '../images/image-thumbnails-placeholder.png',
      import.meta.url
    ).href,
    ratio: 16 / 9,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-posters-xl',
    title: 'animation.block.types.postersXl.title',
    desc: 'animation.block.types.postersXl.description',
    modalSettingsTitle: 'animation.block.types.postersXl.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-posters-xl.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 2,
      tablet: 3,
      desktop: 4,
      orange: 5,
      free: 5,
      apple_tv: 5,
      android_tv: 5,
      samsung_tv: 5,
    },
    format: picturesNames.PICTURE_TYPE_XL_POSTERS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 0.75,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-thumbnails-xl',
    title: 'animation.block.types.thumbnailsXl.title',
    desc: 'animation.block.types.thumbnailsXl.description',
    modalSettingsTitle: 'animation.block.types.thumbnailsXl.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-thumbnails-xl.png',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 1,
      tablet: 2,
      desktop: 2,
      orange: 2,
      free: 2,
      apple_tv: 2,
      android_tv: 2,
      samsung_tv: 2,
    },
    format: picturesNames.PICTURE_TYPE_XL_THUMBNAILS,
    placeholder: new URL(
      '../images/image-thumbnails-xl-placeholder.png',
      import.meta.url
    ).href,
    ratio: 16 / 9,
    wall: true,
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-banners',
    title: 'animation.block.types.halfBanners.title',
    desc: 'animation.block.types.halfBanners.description',
    modalSettingsTitle: 'animation.block.types.halfBanners.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-banners.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 1,
      tablet: 1,
      desktop: 2,
      orange: 2,
      free: 2,
      apple_tv: 2,
      android_tv: 2,
      samsung_tv: 2,
    },
    format: picturesNames.PICTURE_TYPE_HALF_BANNERS,
    placeholder: new URL(
      '../images/image-half-banners-placeholder.png',
      import.meta.url
    ).href,
    ratio: 3.41,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-roundeds',
    title: 'animation.block.types.roundeds.title',
    desc: 'animation.block.types.roundeds.description',
    modalSettingsTitle: 'animation.block.types.roundeds.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-roundeds.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 3,
      tablet: 6,
      desktop: 7,
      orange: 9,
      free: 9,
      apple_tv: 9,
      android_tv: 9,
      samsung_tv: 9,
    },
    format: picturesNames.PICTURE_TYPE_ROUNDEDS,
    placeholder: new URL(
      '../images/image-roundeds-placeholder.png',
      import.meta.url
    ).href,
    ratio: 1,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-mosaic-posters',
    title: 'animation.block.types.mosaicPosters.title',
    desc: 'animation.block.types.mosaicPosters.description',
    modalSettingsTitle: 'animation.block.types.mosaicPosters.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-mosaic-posters.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 3,
      tablet: 5,
      desktop: 7,
      orange: 12,
      free: 12,
      apple_tv: 12,
      android_tv: 12,
      samsung_tv: 12,
    },
    format: picturesNames.PICTURE_TYPE_POSTERS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 0.75,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-mosaic-thumbnails',
    title: 'animation.block.types.mosaicThumbnails.title',
    desc: 'animation.block.types.mosaicThumbnails.description',
    modalSettingsTitle: 'animation.block.types.mosaicThumbnails.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-mosaic-thumbnails.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 2,
      tablet: 4,
      desktop: 5,
      orange: 9,
      free: 9,
      apple_tv: 9,
      android_tv: 9,
      samsung_tv: 9,
    },
    format: picturesNames.PICTURE_TYPE_THUMBNAILS,
    placeholder: new URL(
      '../images/image-thumbnails-placeholder.png',
      import.meta.url
    ).href,
    ratio: 16 / 9,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: {
        infos: true,
      }
    },
    availableDevices: allDevices,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-posters-xl-with-txt',
    title: 'animation.block.types.postersXlWithTxt.title',
    desc: 'animation.block.types.postersXlWithTxt.description',
    modalSettingsTitle: 'animation.block.types.postersXlWithTxt.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-posters-xl-with-txt.png',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 1,
      tablet: 'auto',
      desktop: 2,
      orange: 3,
      free: 3,
      apple_tv: 3,
      android_tv: 3,
      samsung_tv: 3,
    },
    format: picturesNames.PICTURE_TYPE_XL_POSTERS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 0.75,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    spacings: true,
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-thumbnails-xl-with-txt',
    title: 'animation.block.types.thumbnailsXlWithTxt.title',
    desc: 'animation.block.types.thumbnailsXlWithTxt.description',
    modalSettingsTitle:
      'animation.block.types.thumbnailsXlWithTxt.settings.title',
    components: {
      settings: SETTINGS,
      contents: CONTENTS,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-thumbnails-xl-with-txt.png',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 1,
      tablet: 1,
      desktop: 1,
      orange: 2,
      free: 2,
      apple_tv: 2,
      android_tv: 2,
      samsung_tv: 2,
    },
    format: picturesNames.PICTURE_TYPE_XL_THUMBNAILS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 16 / 9,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: true
      },
      open: 'block-info'
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-playbacks',
    title: 'animation.block.types.playbacks.title',
    desc: 'animation.block.types.playbacks.description',
    modalSettingsTitle: 'animation.block.types.playbacks.settings.title',
    components: {
      settings: SETTINGS,
      contents: null,
      preview: 'AnimationPreviewContents',
    },
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-posters.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 3,
      tablet: 5,
      desktop: 7,
      orange: 11,
      free: 11,
      apple_tv: 11,
      android_tv: 11,
      samsung_tv: 11,
    },
    format: picturesNames.PICTURE_TYPE_POSTERS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 0.6667,
    wall: false,
    unique: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: 'block-info'
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'row-recommendations',
    title: 'animation.block.types.recommendations.title',
    desc: 'animation.block.types.recommendations.description',
    modalSettingsTitle: 'animation.block.types.recommendations.settings.title',
    components: {
      settings: SETTINGS,
      contents: null,
      preview: 'AnimationPreviewContents',
    },
    templatesOptions: [
      {
        id: 1,
        desc: 'animation.block.sections.template.options.posters',
        image: new URL(
          `../images/animation/form/movies/programs-settings-template-1.png`,
          import.meta.url
        ).href,
        label: 'animation.block.types.posters.title',
      },
      {
        id: 2,
        desc: 'animation.block.sections.template.options.thumbnails',
        image: new URL(
          `../images/animation/form/movies/programs-settings-template-2.png`,
          import.meta.url
        ).href,
        label: 'animation.block.types.thumbnails.title',
      },
    ],
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL(
      '../images/animation/templates/row-posters.jpg',
      import.meta.url
    ).href,
    slidesPerView: {
      mobile: 3,
      tablet: 5,
      desktop: 7,
      orange: 11,
      free: 11,
      apple_tv: 11,
      android_tv: 11,
      samsung_tv: 11,
    },
    format: picturesNames.PICTURE_TYPE_POSTERS,
    placeholder: new URL(
      '../images/image-posters-placeholder.png',
      import.meta.url
    ).href,
    ratio: 0.6667,
    wall: false,
    unique: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: true,
        spacings: true,
        types: false
      },
      open: 'block-info'
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
      devicesType.APPLE_TV,
      devicesType.ANDROID_TV,
      devicesType.SAMSUNG_TV
    ],
    isMultiDeviceAtOnce: true,
  },
  {
    id: 'gift-card',
    title: 'animation.block.types.giftCard.title',
    desc: 'animation.block.types.giftCard.description',
    modalSettingsTitle: 'animation.block.types.giftCard.settings.title',
    components: {
      settings: SETTINGS,
      contents: null,
      preview: 'AnimationPreviewGiftCard',
    },
    format: picturesNames.PICTURE_TYPE_BACKDROPS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL('../images/animation/templates/gift-card.jpg', import.meta.url)
      .href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: null
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
    ],
    isMultiDeviceAtOnce: true,
    canEnable: true,
  },
  {
    id: 'form',
    title: 'animation.block.types.form.title',
    desc: 'animation.block.types.form.description',
    modalSettingsTitle: 'animation.block.types.form.settings.title',
    components: {
      settings: SETTINGS,
      contents: 'AnimationFormCustomContents',
      preview: 'AnimationPreviewForm',
    },
    format: picturesNames.PICTURE_TYPE_BACKDROPS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL('../images/animation/templates/form.jpg', import.meta.url)
      .href,
    wall: true,
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
    ],
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: null
    },
    isMultiDeviceAtOnce: true,
    canEnable: true,
  },
  {
    id: 'faq',
    title: 'animation.block.types.faq.title',
    desc: 'animation.block.types.faq.description',
    modalSettingsTitle: 'animation.block.types.faq.settings.title',
    components: {
      settings: SETTINGS,
      contents: 'AnimationFormFAQContents',
      preview: 'AnimationPreviewFAQ',
    },
    format: picturesNames.PICTURE_TYPE_BACKDROPS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL('../images/animation/templates/FAQ.jpg', import.meta.url)
      .href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: null
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
    ],
    isMultiDeviceAtOnce: true,
    canEnable: true,
  },
  {
    id: 'newsletter',
    title: 'animation.block.types.newsletter.title',
    desc: 'animation.block.types.newsletter.description',
    modalSettingsTitle: 'animation.block.types.newsletter.settings.title',
    components: {
      settings: SETTINGS,
      contents: 'AnimationFormCustomContents',
      preview: 'AnimationPreviewForm',
    },
    format: picturesNames.PICTURE_TYPE_BACKDROPS,
    templateTypeNames: [
      Pages.HOME,
      Pages.LANDING,
      Pages.PAGE
    ],
    image: new URL('../images/animation/templates/newsletter.jpg', import.meta.url)
      .href,
    wall: true,
    defaultSettings: {
      show: {
        carousels: false,
        infos: true,
        colors: true,
        templates: false,
        spacings: true,
        types: false
      },
      open: null
    },
    availableDevices: [
      devicesType.DESKTOP,
      devicesType.TABLET,
      devicesType.MOBILE,
    ],
    isMultiDeviceAtOnce: true,
    canEnable: true,
  },
];

export default blockTypes;