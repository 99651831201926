<template>
  <span v-if="icon" class="icon inline-block" :class="iconClass">
    <svg
      v-if="iconConfig && icon !== 'picture-flat'"
      class="icon-main"
      :class="iconMainClass"
      version="1.1"
      :fill="fill"
      :color="colorHex"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :viewBox="`${iconConfig.x} ${iconConfig.y} ${iconConfig.width} ${iconConfig.height}`"
      v-html="path"
    />
    <svg
      v-if="actionIconConfig"
      class="icon-action"
      :class="iconActionClass"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :viewBox="`0 0 ${actionIconConfig.width} ${actionIconConfig.height}`"
      v-html="actionIconConfig.block"
    />
  </span>
</template>

<script>
import { isNil } from 'lodash';
import { themeClasses } from '@/utils/styles/styles.js';
import iconsLibrary from '@/components/icon/icons.json';
import { iconColorClasses, iconsSizes } from '@/utils/styles/styles.js';

export default {
  name: 'Icon',
  props: {
    action: String,
    actionColor: {
      type: String,
    },
    actionSize: String,
    mainClass: String,
    actionClass: String,
    color: {
      type: String,
    },
    clickable: Boolean,
    colorHex: String,
    fill: String,
    stroke: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
    heightAuto: {
      type: Boolean,
      default: false,
    },
    icon: String,
  },
  emits: ['click'],
  computed: {
    icons() {
      return iconsLibrary;
    },
    theme() {
      return this.$route.matched[0]?.meta?.theme ?? 'primary';
    },
    iconClass() {
      return [
        `icon-${this.icon}`,
        this.actionIconConfig && 'relative',
        this.clickable ? 'clickable' : '',
      ];
    },
    iconMainClass() {
      return [
        this.mainClass && this.mainClass,
        this.color && iconColorClasses[this.color],
        this.size ? iconsSizes[this.size] : iconsSizes.md,
        this.widthAuto && '!w-auto',
        this.heightAuto && '!h-auto',
      ];
    },
    iconActionClass() {
      return [
        'transition-all ease-in-out',
        'text-skin-accent',
        this.action && this.action,
        'absolute',
        'bottom-0',
        'right-0',
        'translate-x-1/2',
        'translate-y-1/2',
        this.actionColor && iconColorClasses[this.actionColor],
        this.theme && themeClasses[this.theme],
        this.actionSize && iconsSizes[this.actionSize],
        this.actionClass && this.actionClass,
      ];
    },
    iconConfig() {
      if (isNil(this.icons[this.icon.toLowerCase()])) {
        console.log('Cannot find icon : ' + this.icon + '.');
        return null;
      }
      let config = this.icons[this.icon.toLowerCase()];

      if (!('x' in config)) {
        config.x = 0;
      }

      if (!('y' in config)) {
        config.y = 0;
      }

      return config;
    },
    path() {
      if (this.colorHex) {
        const stroke =
          this.colorHex === '#FFFFFF' && this.stroke ? '#BDBDBD' : 'none';
        const pathStart = `<path fill="${this.colorHex}" stroke="${stroke}"`;
        const pathEnd = this.iconConfig.block.split('<path')[1];
        return `${pathStart}${pathEnd}`;
      } else {
        return this.iconConfig.block;
      }
    },
    actionIconConfig() {
      return this.action && this.icons[this.action.toLowerCase()];
    },
  },
};
</script>

<style lang="scss" scoped>
//@import 'icon.scss';
</style>
