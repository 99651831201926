<template>
  <div>
    <header id="header" class="main-header">
      <div class="main-header-logo">
        <router-link to="/">
          <img alt="Otto logo" :src="getEnvLogo" class="main-header-logo-img" />
        </router-link>
      </div>
    </header>
    <div class="page login-page login-page--login">
      <div class="login-page-form">
        <div v-if="!isRegistered" class="login-page-form-container">
          <header class="page-header">
            <h1 class="page-title">
              Bienvenue sur<br />Otto x {{ getCurrentPlatformName }}
            </h1>
          </header>

          <form @keydown="errors.clear($event.target.name)" @submit.prevent>
            <fieldset>
              <FormInput
                id="email"
                v-model="auth.email"
                label="Votre identifiant"
                name="email"
                :error="errors.get('email')"
                disabled
                @reset="resetInput('email')"
              />
            </fieldset>
            <hr class="spacer" />
            <fieldset>
              <legend>Choisissez votre mot de passe :</legend>
              <FormInput
                id="password"
                v-model="auth.password"
                type="password"
                label="Mot de passe"
                name="password"
                :error="errors.get('password')"
                hint="Le mot de passe doit comporter 6 caractères minimum"
                disclosable
                autofocus
                @reset="resetInput('password')"
              />
              <FormInput
                id="password_confirmation"
                v-model="auth.password_confirmation"
                type="password"
                label="Confirmation mot de passe"
                name="password_confirmation"
                :error="errors.get('password_confirmation')"
                disclosable
                @reset="resetInput('password_confirmation')"
              />
            </fieldset>
            <div class="form-input">
              <div class="form-input-container">
                <BaseButton
                  :label="$t('button.validate')"
                  size="lg"
                  :disabled="isFormInvalid"
                  :loading="isLoading"
                  @click.prevent="handleSubmit()"
                />
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <header class="page-header">
            <h1 class="page-title">Félicitations !</h1>
          </header>
          <p class="page-description">Votre compte a bien été créé.</p>
          <BaseButton
            label="Me connecter"
            size="lg"
            @click.prevent="goToLogin()"
          />
        </div>
      </div>
      <div
        class="login-page-illustration"
        :class="{
          'login-page-illustration--logging': !isRegistered,
          'login-page-illustration--success': isRegistered,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Errors from '@/utils/Errors';
import FormInput from '@/components/form/FormInput.vue';

export default {
  name: 'Register',
  components: {
    FormInput,
    BaseButton,
  },
  data() {
    return {
      auth: {
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
      },
      registered: false,
      errors: new Errors(),
    };
  },
  computed: {
    getEnvLogo() {
      return `/logos/otto.${import.meta.env.VITE_ENV}.png`;
    },
    isFormInvalid() {
      return (
        !this.auth.email ||
        !this.auth.password ||
        !this.auth.password_confirmation ||
        this.errors.any()
      );
    },
    ...mapGetters({
      isLoading: 'auth/isLoading',
      isRegistered: 'auth/isRegistered',
      getCurrentPlatformName: 'platforms/getCurrentPlatformName',
    }),
  },
  methods: {
    handleSubmit() {
      this.register(this.auth).catch((error) => {
        this.errors.format(error);
      });
    },
    resetInput(field) {
      this.auth[field] = '';
      this.errors.clear(field);
    },
    goToLogin() {
      this.$router.push('/').catch((e) => console.log(e));
    },
    ...mapActions('auth', ['register']),
  },
};
</script>

<style lang="scss">
@import '../components/header/header.scss';
@import 'login.scss';
</style>
