<template>
  <div
    class="flex gap-2"
    :class="[
      display === 'row' && 'justify-center',
      display === 'column' && 'items-center',
    ]"
  >
    <MouseHover
      :message="itemMouseHover.message"
      :cursor="itemMouseHover.cursor"
      :active="itemMouseHover.active"
      :disable="itemMouseHover.disable"
      full
    >
      <label
        :for="id"
        class="flex gap-2 items-center w-full"
        :class="checkboxClass"
      >
        <div
          v-if="$slots['header']"
          class="peer-disabled:text-skin-muted text-sm w-full"
          :class="[disabled && 'text-skin-muted']"
        >
          <slot name="header" v-bind="$props" />
        </div>
        <div
          class="flex flex-row gap-2 items-center peer-disabled:text-skin-muted text-sm cursor-pointer"
        >
          <input
            :id="id"
            v-model="checked"
            :readonly="readonly"
            type="checkbox"
            :disabled="disabled"
            class="hidden"
            @click="$emit('click')"
          />
          <Checkbox :checked="checked" :theme="themeColor"/>
          <span
            v-if="label && !image"
            class="peer-disabled:text-skin-muted"
            :class="[
              disabled && 'text-skin-muted',
              noWrap ? 'whitespace-nowrap' : 'whitespace-normal',
            ]"
            >{{ $t(labelFormatted) }}</span
          >
          <Tooltip 
            v-if="tooltip"
            :icon="tooltip.icon"
            :message="tooltip.message"
            :position="tooltip.position"
          />
          <template v-if="$slots['suffix']">
            <slot name="suffix" v-bind="$props" />
          </template>
        </div>
        <div
          v-if="$slots['footer']"
          class="peer-disabled:text-skin-muted text-sm"
        >
          <slot name="footer" v-bind="$props" />
        </div>
      </label>
    </MouseHover>
    <FormHint v-if="hint">
      {{ $t(hint) }}
    </FormHint>
  </div>
</template>

<script>
import { fontSizeClasses } from '@/utils/styles/styles.js';
import Checkbox from '@/components/atoms/Checkbox.vue';
import FormHint from '@/components/form/FormHint.vue';
import Icon from '@/components/icon/Icon.vue';
import MouseHover from '@/components/mouse/MouseHover.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'FormCheckbox',
  components: {
    Checkbox,
    FormHint,
    Icon,
    MouseHover,
    Tooltip,
  },
  props: {
    id: {
      type: [String, Number],
      default: () => `id_${Math.floor(Math.random() * 10000)}`,
    },
    size: String,
    label: String,
    display: {
      type: String,
      default: 'row',
    },
    align: {
      type: String,
      default: 'left',
    },
    labelSize: {
      type: String,
      default: 'md',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array, Boolean, Object, Number],
      default: undefined,
    },
    name: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
    disabled: Boolean,
    image: String,
    loading: {
      type: Boolean,
      default: false,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    hint: String,
    illustration: Object,
    icon: String,
    maxCharacters: Number,
    theme: {
      type: String,
      default: null
    },
    tooltip: {
      type: Object,
      default: null,
    },
    mouseHover: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue', 'click'],
  data() {
    return {
      uniqueId: '',
    };
  },
  computed: {
    checked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    themeColor() {
      return this.theme
      ? this.theme
      : this.$route.matched[0].meta.theme ?? 'primary';
    },
    checkboxClass() {
      return [
        this.display === 'row' && 'flex-row',
        this.display === 'row' && this.align === 'right' && 'justify-end',
        this.display === 'column' && 'flex-col',
        this.size && `size-${this.size}`,
        this.themeColor && `theme-${this.themeColor}`,
        this.disabled && `form-checkbox--disabled`,
      ];
    },
    labelClass() {
      return [this.size && fontSizeClasses[this.size]];
    },
    labelFormatted() {
      // cuts text length and adds ellipsis
      if (this.maxCharacters && this.label.length > this.maxCharacters) {
        return this.label.substring(0, this.maxCharacters) + '&#8230;'; // &#8230; == '...'
      }
      return this.label;
    },
    itemMouseHover() {
      return this.mouseHover
      ? this.mouseHover
      : {
        message: '',
        cursor: 'pointer',
        active: false,
        disable: false
      } 

    }
  },
};
</script>

<style lang="scss">
//@import 'form_checkbox.scss';
</style>
