<template>
  <article
    class="transition-all ease-in-out transition-shadow hover:duration-200 duration-500"
    :class="cardClass"
  >
    <fieldset :disabled="disabled">
      <RemoveButton
        v-if="removable"
        class="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2"
        @click="$emit('remove')"
      />
      <div v-if="$slots['title']" class="mb-4 text-skin-accent">
        <h2 class="text-xl font-medium leading-tight">
          <slot name="title" />
        </h2>
      </div>
      <div :class="cardContentClass">
        <slot />
      </div>
    </fieldset>
  </article>
</template>
<script setup>
import {
  blockPaddingClasses,
  dashedColorClasses,
} from '@/utils/styles/styles.js';
import { computed, provide } from 'vue';
import RemoveButton from '@/components/button/RemoveButton.vue';
import useAccordion from '@/composables/useAccordion.js';

const props = defineProps({
  initialItemKey: {
    type: [Number, String, Array],
    default: undefined,
    required: false,
  },
  wrapper: Boolean,
  withTabs: Boolean,
  spacer: {
    type: String,
    default: 'lg',
  },
  type: {
    type: String,
  },
  accordion: {
    type: Boolean,
    default: false,
  },
  contentClass: {
    type: String,
  },
  removable: Boolean,
  multiple: Boolean,
  disabled: {
    type: Boolean,
    default: false
  }
});

if (props.accordion) {
  const { $activeKey, toggleItemKey } = useAccordion(props);
  provide('$activeItemKey', $activeKey);
  provide('toggleItemKey', toggleItemKey);
  provide('$multiple', props.multiple);
}

const cardClass = computed(() => {
  return [
    'rounded-ui-component',
    !props.wrapper && 'card',
    !props.wrapper &&
      (props.type === 'dashed' ? 'card-dashed theme-dashed' : 'bg-skin-ui'),
    !props.wrapper &&
      (props.type === 'slim'
        ? 'p-0 overflow-hidden'
        : props.withTabs
        ? 'p-0 relative'
        : blockPaddingClasses[props.spacer]),
    !props.wrapper &&
      (props.type === 'dashed'
        ? dashedColorClasses
        : 'shadow-sm shadow-skin-ui'),
    props.withTabs && 'rounded-tl-none rounded-tr-none card--with-tabs',
    props.removable && 'relative',
    props.type === 'button' &&
      'hover:shadow-md hover:shadow-skin-ui active:shadow active:shadow-skin-ui',
  ];
});
const cardContentClass = computed(() => {
  return [
    props.type === 'slim' && 'overflow-auto',
    props.accordion && 'card-accordion divide-y divide-solid divide-skin-ui',
    props.contentClass,
  ];
});
</script>
