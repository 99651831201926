<template>
  <div
    class="error-wrapper"
    :class="{
      'position-left': error.tooltipPosition === 'left',
      'position-right': error.tooltipPosition === 'right',
      'error-wrapper--active': show && !valid,
    }"
    @click="handleClick"
  >
    <Tooltip
      v-if="show && !valid"
      :message="message"
      :align="error.align"
      :icon="error.icon"
      :position="error.position"
      class="error-wrapper-tooltip"
    />
    <slot />
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip.vue';
export default {
  name: 'ErrorWrapper',
  components: {
    Tooltip,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    show() {
      return (
        this.$route.matched[0].path.indexOf('/animation/') > -1 &&
        this.$route.matched[0].path.indexOf('/animation/Pages') < -1
      );
    },
    message() {
      return (
        this.error.message ??
        `Ce champ a présenté une erreur lors de l'enregistrement de votre page`
      );
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss">
@import 'error_wrapper.scss';
</style>
