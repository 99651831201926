<template>
  <BaseButton
    size="xl"
    nude
    :with-theme="false"
    class="flex flex-col gap-2 md:py-2 md:px-6 cursor-pointer h-auto"
    @click="$emit('click')"
  >
    <Icon
      :icon="icon"
      :size="iconSize"
      :color="color"
      :action="actionIcon"
      class="transition-all ease-in-out"
      :action-color="actionColor"
      :action-size="actionIconSize"
      main-class="group-hover:scale-[1.02] drop-shadow-sm  transition-all scale-100 w-14 h-14"
      :action-class="['group-hover:scale-110 transition-all scale-100 group-hover:delay-100 group-hover:drop-shadow-md', actionClass]"
    />
    <span
      class="font-weight-normal"
      :class="textClass"
      style="white-space: pre"
    >
      <slot />
    </span>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';

export default {
  name: 'PictoButton',
  components: {
    BaseButton,
    Icon,
  },
  inject: ['$isMobile', '$isTablet', '$isDesktop'],
  props: {
    icon: String,
    color: {
      type: String,
    },
    actionIcon: String,
    actionColor: {
      type: String,
    },
    actionClass: {
      type: String,
      default: null,
    }
  },
  emits: ['click'],
  computed: {
    iconSize() {
      if (this.$isMobile) {
        return 'xl';
      } else if (this.$isTablet) {
        return 'xl';
      } else {
        return 'xxl';
      }
    },
    actionIconSize() {
      if (this.$isMobile) {
        return 'md';
      } else if (this.$isTablet) {
        return 'lg';
      } else {
        return 'lg';
      }
    },
    fontSize() {
      if (this.$isMobile) {
        return 'text-xs';
      } else if (this.$isTablet) {
        return 'text-sm';
      } else {
        return 'text-sm';
      }
    },
    lineHeight() {
      if (this.$isMobile) {
        return 'leading-none';
      } else if (this.$isTablet) {
        return 'leading-normal';
      } else {
        return 'leading-normal';
      }
    },
    textClass() {
      return [this.fontSize, this.lineHeight];
    },
  },
};
</script>
