import DataFromMultiTypeAccessor from '@/utils/DataFromMultiTypeAccessor';

export default {
  methods: {
    /**
     * Copy link
     *
     * @param {bool}   select
     * @param {string} url
     * @returns @void
     * @author  Helene TELLIEZ <htelliez@vodfactory.com>
     */
    copyCodeLink(select = true, url = '') {
      if (select) {
        const codeLink = this.$refs.codeLink;
        let range, selection;

        if (window.getSelection) {
          selection = window.getSelection();
          range = document.createRange();
          range.selectNodeContents(codeLink);
          selection.removeAllRanges();
          selection.addRange(range);
        }

        if (selection) {
          document.execCommand('copy');
          const alert = {
            id: 'copy-success',
            icon: 'check',
            type: 'valid',
            message: this.$t('link.alert.success'),
          };
          this.$store.dispatch('displayAlert', alert);
          selection.removeAllRanges();
        }
      } else {
        navigator.clipboard.writeText(url).then(
          () => {
            /* clipboard successfully set */
            const alert = {
              id: 'copy-success',
              icon: 'check',
              type: 'valid',
              message: this.$t('link.alert.success'),
            };
            this.$store.dispatch('displayAlert', alert);
          },
          (error) => {
            console.debug(error);
            /* clipboard write failed */
            const alert = {
              id: 'copy-failed',
              icon: 'close',
              type: 'error',
              message: 'error.generic',
            };
            this.$store.dispatch('displayAlert', alert);
          }
        );
      }
    },
    /**
     * Get item full web url
     *
     * @param {object} item
     * @returns string url
     * @author  Jody JUSTINE <jjustine@vodfactory.com>
     */
    getItemUrlByObject(type, item) {
      let path = '';
      switch (type) {
        case 'template':
          if (typeof item !== 'object') {
            console.error('Get Item Url By Object : Bad type.');
            path = '#error-bad-item';
          } else if ('landing' === item.template_type_name && item.is_default) {
            path = 'discover';
          } else if ('home' === item.template_type_name && item.is_default) {
            path = 'home';
          } else {
            path = 'page/' + item.url;
          }
          break;
        default:
          console.error('Get Item Url By Object : type not set.');
          break;
      }
      return this.getFrontBaseUrlWithSlash + path;
    },

    getItemPath(
      type,
      slug = '',
      id = 0,
      metadataDetailSlug = null,
      metadataDetailId = null
    ) {
      let path = '';

      switch (type) {
        case 'program':
        case 'live':
          path = '/content/' + slug + '-' + id;
          break;
        case 'collection':
          path = '/collection/' + slug + '-' + id;
          break;
        case 'selections':
          path = '/selection';
          break;
        case 'serie':
        case 'season':
        case 'category':
        case 'country':
        case 'gear':
        case 'format':
        case 'custom':
        case 'level':
        case 'ratinglevel':
        case 'tag':
        case 'version':
        case 'selection':
          id = id ?? 1;
          slug = slug ?? 'preview';
          path = `/${type}/${slug}-${id}`;
          break;
        case 'template':
          if (slug === '' && id === 0) {
            path = '/page/';
          } else {
            return this.getItemUrlByObject(type, slug);
          }
          break;
        case 'coupon':
          path = '/coupon/' + id;
          break;
        case 'legal':
          path = '/legal' + '-' + slug;
          break;
        case 'parametersSiteTabCataloguePage':
          path = '/content';
          break;
        case 'metadata':
          path = '/metadatas/' + slug + '-' + id;
          break;

        case 'person':
          path =
            '/person/' +
            metadataDetailSlug +
            '-' +
            metadataDetailId +
            '/' +
            slug +
            '-' +
            id;
          break;
        case 'subscribe':
          path = id === 0 ? '/subscribe' : '/subscribe/' + id;
          break;
        default:
          path = 'Not set';
          break;
      }

      return this.getFrontBaseUrl + path;
    },
    getItemUrl(item) {
      let slug = item.slug;
      if (item.program && item.program.slug && item.program.id) {
        let folder = '';
        let id = '';
        let slug;
        switch (item.program.type) {
          case 'serie':
            folder = '/serie/';
            slug = item.program.slug;
            id = item.program.serie_id;
            break;
          case 'season':
            folder = '/season/';
            slug = item.program.slug;
            id = item.program.season_id;
            break;
          case 'episode':
            if (item.program.season_id) {
              folder = '/season/';
              slug = item.program?.season?.slug ?? 'xxxx'; // TODO: Constaté pendant la correction et les tests du fix OTTO-11508, l'API content a changé a changé et ne renvoie plus les informations de la serie liée dans l'objet program épisode et génère des erreurs d'affichage, (pour ne pas casser le fonctionnel du BO je remplace le slug par 'xxxx')
              id = item.program.season_id;
            } else {
              folder = '/serie/';
              slug = item.program?.serie?.slug ?? 'xxxx'; // TODO: Constaté pendant la correction et les tests du fix OTTO-11508, l'API content a changé a changé et ne renvoie plus les informations de la serie liée dans l'objet program épisode et génère des erreurs d'affichage, (pour ne pas casser le fonctionnel du BO je remplace le slug par 'xxxx')
              id = item.program.serie_id;
            }
            break;
          case 'collection':
            folder = '/collection/';
            slug = item.program.slug;
            id = item.program.id;
            break;
          default:
            folder = '/content/';
            id = item.program.id;
            slug = item.program.slug;
        }
        return `${this.getFrontBaseUrl}${folder}${slug}-${id}`;
      } else if (item.selection && item.selection.slug && item.selection.id) {
        return `${this.getFrontBaseUrl}/selection/${item.selection.slug}-${item.selection.id}`;
      } else if (DataFromMultiTypeAccessor.isMetadata(item)) {
        const itemMetadata = DataFromMultiTypeAccessor.getMetadataItem(item);
        const metadataType =
          item.type !== 'metadata' ? item.type : itemMetadata.type;
        return this.getMetadataUrl(itemMetadata, metadataType);
      } else {
        return item.cta_url;
      }
    },
    getMetadataUrl(itemMetadata, metadataType) {
      if (itemMetadata.external_link && itemMetadata.external_link.url) {
        return itemMetadata.external_link.url;
      }
      return `${this.getFrontBaseUrl}/${metadataType}/${itemMetadata.slug}-${itemMetadata.id}`;
    },
    /**
     * Get front url with or without trailing slashed
     * @param {bool} withSlash
     * @returns string
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    getFrontUrl(withSlash = false) {
      let baseUrl =
        this.$store?.state?.platforms?.currentPlatform?.front_url ??
        'https://votresite.com';
      baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
      return withSlash ? baseUrl + '/' : baseUrl;
    },
  },
  computed: {
    /**
     * Get front url without trailing slashed
     * @returns string
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    getFrontBaseUrl() {
      return this.getFrontUrl();
    },
    /**
     * Get front url with trailing slashed
     * @returns string
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    getFrontBaseUrlWithSlash() {
      return this.getFrontUrl(true);
    },
    getBlockLinkUrl() {
      switch (this.$options.name) {
        case 'AnimationFormBannerSettings':
          if (this.block.items && this.block.items.length !== 0) {
            return this.getItemUrl(this.block.items[0]);
          } else {
            return this.block.cta_url;
          }
        case 'AnimationBlockContentItemCustom':
        case 'AnimationFormCarouselContentsItemCustom':
          return this.getItemUrl(this.formItem);
        default:
          return 'N/A';
      }
    },
  },
};
