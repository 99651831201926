<template>
  <MouseHover 
    :active="disabled"
    :message="tooltip"
    cursor="pointer"
    content-class="w-full justify-center"
  >
    <span class="otto-base-ui-component" :class="tagClass" :title="tagTitle">
      <slot />
    </span>
  </MouseHover>
</template>

<script>
import {
  buttonColorClasses,
  inputFieldButtonColorClasses,
  outlinedButtonColorClasses,
  sizeClasses,
  themeClasses,
} from '@/utils/styles/styles.js';
import Icon from '@/components/icon/Icon.vue';
import MouseHover from '@/components/mouse/MouseHover.vue';

export default {
  name: 'Tag',
  components: {
    Icon,
    MouseHover
  },
  props: {
    label: String,
    title: Boolean,
    size: {
      type: String,
      default: 'md',
    },
    rounded: Boolean,
    themed: Boolean,
    weight: String,
    variant: String,
    tagStyle: String,
    fontSize: String,
    decoration: String,
    type: {
      type: String,
      validator: (value) => {
        return ['filled', 'outline', 'field'].includes(value);
      },
    },
    underline: Boolean,
    ghost: Boolean,
    disabled: Boolean,
    tooltip: {
      type: String,
      default: ''
    }
  },
  computed: {
    tagType() {
      return this.type ? this.type : this.theme;
    },
    theme() {
      return this.variant ?? this.$route.matched[0].meta.theme ?? 'primary';
    },
    tagTitle() {
      return this.title ? this.label : '';
    },
    tagClass() {
      return [
        'inline-flex',
        'items-center',
        'gap-1',
        'flex-nowrap',
        sizeClasses[this.size],
        this.themed && themeClasses[this.theme],
        this.type === 'outline' && outlinedButtonColorClasses,
        this.type === 'filled' && buttonColorClasses,
        this.type === 'field' && inputFieldButtonColorClasses,
        this.type !== 'nude' && 'border border-solid',
        this.type === 'nude' && 'px-0',
        this.rounded && 'rounded-full',
      ];
    },
  },
};
</script>
