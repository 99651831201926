<template>
  <div
    id="app-container"
    class="flex flex-col flex-1 w-full transition text-skin-base ease-in-out transition-colors duration-300"
    :class="appClass"
    :style="appStyle"
  >
    <component
      :is="layout"
      :loader-visible="loaderVisible"
      :nav-expanded="withNavExpanded"
      :pending-visible="pendingVisible"
      :initialized="initialized"
    >
      <router-view
        v-slot="{ Component }"
        :key="routerViewKey"
        @login="initPlatforms"
      >
        <Transition
          appear
          appear-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
          appear-from-class="opacity-0"
          appear-to-class="opacity-100"
          enter-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
          mode="out-in"
        >
          <component :is="Component" />
        </Transition>
      </router-view>
    </component>
    <modals-container />
    <Alerts />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { themeClasses } from '@/utils/styles/styles.js';
import Alerts from '@/components/alerts/Alerts.vue';
import ModalVideosUpload from '@/components/modal/ModalVideosUpload.vue';
import utils from '@/utils/utils.js';

const default_layout = 'default';

export default {
  name: 'App',
  components: {
    Alerts,
    ModalVideosUpload,
  },
  provide: function () {
    return {
      togglePageLoader: this.togglePageLoader,
    };
  },
  data() {
    return {
      routesWithNavExpanded: ['home'],
      initialized: false,
    };
  },
  computed: {
    routerViewKey() {
      return this.$route.matched && this.$route.matched.length > 0
        ? this.$route.matched[0].path
        : this.$route.fullPath;
    },
    theme() {
      return this.$route?.meta?.theme ?? 'primary';
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
    ...mapFields([
      'platforms.selectedPlatformID',
      'auth.authUser'
    ]),
    withNavExpanded() {
      let currentRoute = this.$route.name;
      return this.routesWithNavExpanded.indexOf(currentRoute) > -1;
    },
    appClass() {
      return [
        {
          'pt-[75px] md:pt-[60px]': this.layout !== 'no-navbar-layout' ,
          'md:pl-[213px]':
            this.withNavExpanded && 
            !this.maintenanceMode && 
            this.layout !== 'no-navbar-layout',
          'md:pl-[72px]':
            !this.withNavExpanded &&
            !this.maintenanceMode &&
            this.layout !== 'no-navbar-layout',
          'p-0 flex h-screen flex-col': this.layout === 'no-navbar-layout',
        },
        this.theme && themeClasses[this.theme],
      ];
    },
    maintenanceMode() {
      return (
        this.currentPlatform?.content_config?.bo_maintenance_mode &&
        !this.can('tech.access')
      );
    },
    appStyle() {
      let style = {};
      if (this.modalVisible) {
        style.paddingRight =
          window.innerWidth - document.documentElement.clientWidth + 'px';
      }
      return style;
    },
    ...mapFields(['loaderVisible', 'pendingVisible']),
    ...mapState({
      modalVisible: (state) => state.modalVisible,
      currentPlatform: (state) => state.platforms.currentPlatform,
    }),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      can: 'auth/can',
    }),
  },
  methods: {
    showAdBlockerAlert() {
      if (typeof this.$confirm === 'function') {
        this.$confirm({
          icon: 'warning-filled',
          iconColor: 'yellow',
          title: this.$t('generic.modal.adblock.title'),
          message: this.$t('generic.modal.adblock.message'),
          button: {
            yes: this.$t('button.acknowledge'),
          },
          size: 'md',
          callback: (confirm) => {
            if (confirm) {
              localStorage.setItem('acknowledgedadblocker', 1);
              window.location.reload();
            }
            return;
          },
        });
      }
    },
    ...mapActions('references', { getAllReferences: 'getAll' }),
    ...mapActions('platforms', {
      updateSelectedPlatform: 'updateSelectedPlatform',
      getUserPlatforms: 'getUserPlatforms',
      getCurrentPlatform: 'get',
    }),
    ...mapActions('frontProfiles', {
      resetFrontProfiles: 'reset',
    }),
    ...mapActions('auth', {
      getUserPermissions: 'getUserPermissions',
    }),
    ...mapMutations(['showModal', 'hideModal']),
    ...mapMutations({
      resetAllCategories: 'categories/getAllRequest',
      resetAllCountries: 'countries/getAllRequest',
      resetAllCustoms: 'customs/getAllRequest',
      resetAllGears: 'gears/getAllRequest',
      resetAllLevels: 'levels/getAllRequest',
      resetAllMetadataDetails: 'metadataDetails/resetAll',
      resetAllRatings: 'ratings/getAllRequest',
      resetAllReferences: 'references/getAllRequest',
      resetAllTags: 'tags/getAllRequest',
      resetAllVersions: 'versions/getAllRequest',
      resetAllIngests: 'ingests/getAllRequest',
      resetUserPermissions: 'auth/resetUserPermissions',
    }),
    async initPlatforms() {
      this.togglePageLoader(true);
      const promises = [];
      this.resetPlatformSpecificMetas();
      promises.push(this.resetFrontProfiles());
      promises.push(this.getUserPermissions(true));
      promises.push(this.getAllReferences());
      await Promise.all(promises)
        .catch(() => {
          if (this.$route.name !== 'login') {
            this.showModal({
              componentName: 'ModalGenericConfirm',
              size: 'sm',
              closeOnClickOutside: false,
              componentParams: {
                showCloseButton: false,
                image: '/src/images/loader/jump.gif',
                imageWidth: '220',
                catchphrase: this.$t('error.app.catchphrase'),
                subcatchphrase: this.$t('error.app.subcatchphrase'),
                hasCancel: false,
                confirm: this.$t('error.app.logout'),
                confirmAction: () => this.logout(),
              },
            });
          }
        });
      this.initZendesk();
      this.initJimo();
      this.initialized = true;
      this.togglePageLoader(false);
    },
    resetPlatformSpecificMetas() {
      this.resetAllCategories();
      this.resetAllCountries();
      this.resetAllCustoms();
      this.resetAllGears();
      this.resetAllLevels();
      this.resetAllMetadataDetails();
      this.resetAllRatings();
      this.resetAllReferences();
      this.resetAllTags();
      this.resetAllVersions();
      this.resetAllIngests();
    },
    togglePageLoader(active, saveDisplay = false) {
      if (saveDisplay) {
        this.pendingVisible = active;
      } else {
        this.loaderVisible = active;
      }
    },
    handleVisibilityChange() {
      if (
        !document.hidden &&
        this.selectedPlatformID !== null &&
        !isNaN(this.selectedPlatformID)
      ) {
        localStorage.setItem('platform', this.selectedPlatformID);
      }
    },
    initZendesk() {
      if (
        this.layout === 'default-layout' &&
        this.currentPlatform?.content_config?.feature.bo_zendesk === true
      ) {
        if (!this.$zendesk.isLoaded()) {
          this.$zendesk.load();
        } else {
          this.$zendesk.show();
        }
      } else if (this.$zendesk.isLoaded()) {
        this.$zendesk.hide();
      }
    },
    initJimo() {
      if (
        this.layout === 'default-layout' &&
        this.currentPlatform?.content_config?.feature?.bo_jimo
      ) {
        this.$jimo.load(); 
      }
    },
    addInfoToJimo() {
      this.$jimo.addUserId(this.authUser?.id);
      this.$jimo.addUserEmail(this.authUser?.email);
      const firstname = this.authUser?.firstname ?? '';
      const lastname = this.authUser?.lastname ?? '';
      const fullName = firstname + ' ' + lastname; 
      this.$jimo.addUserName(fullName);
    },
    logout() {
      const platformID = this.currentPlatform?.id;
      const userEmail = this.authUser?.email;
      return this.$store.dispatch('auth/logout')
        .then(() => {
          return this.$store.dispatch('platforms/logoutPlatform')
            .then(() => {
              this.hideModal('ModalGenericConfirm');
              this.$router.push({
                path: '/login',
                query: { userEmail, platformID },
              });
              this.$store.dispatch('references/resetAll');
            });
        }).catch(() => {
          this.hideModal('ModalGenericConfirm');
        });
    }
  },
  watch: {
    selectedPlatformID(value) {
      if (this.$route.name !== 'login') {
        if (this.$route.name === 'passwordReset') {
          const platformId = parseInt(this.$route.params.platformId);
          this.getUserPlatforms({ platforms: [platformId] });
        } else if (value) {
          this.updateSelectedPlatform().then(() => {
            if (this.isAuthenticated) {
              this.initPlatforms();
            }
          });
        }
      }
    },
  },
  mounted() {
    utils.detectAdblock().then((canLoadAd) => {
      const localStorageAcknowledge = localStorage.getItem(
        'acknowledgedadblocker'
      );
      if (localStorageAcknowledge === null && !canLoadAd) {
        this.showAdBlockerAlert();
      }
    });
  },
  created() {
    if (this.isAuthenticated) {
      this.initPlatforms();
      this.addInfoToJimo();
    }
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false
    );
    window.addEventListener('focus', this.handleVisibilityChange, false);
  },
};
</script>
<script setup>
import { computed, provide } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { useFavicon } from '@vueuse/core';

const appEnv = computed(() => {
  return import.meta.env.VITE_ENV;
});
const favicon = computed(() => {
  return `dist/favicons/otto.${appEnv.value}.png`;
});
useFavicon(favicon, {
  baseUrl: '/',
  rel: 'icon',
});

const breakpoints = useBreakpoints({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
});

const mobile = breakpoints.smaller('sm');
const tablet = breakpoints.between('sm', 'lg');
const desktop = breakpoints.greater('lg');
provide('$isMobile', mobile);
provide('$isTablet', tablet);
provide('$isDesktop', desktop);
</script>

<style lang="scss">
@import 'styles/utilities';
@import 'styles/resets';
@import 'styles/elements';
@import 'styles/components';
//@import 'styles/grid';
@import 'styles/pages';
</style>
