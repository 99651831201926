<template>
  <label
    class="form-label"
    :class="labelClass"
    :for="labelFor"
    :aria-hidden="ghost"
  >
    <slot name="custom">
      <Icon 
        v-if="iconBefore" 
        :icon="iconBefore" 
        :size="iconBeforeSize"
        class="form-label-icon" 
      />
      <span class="form-label-text">
        <slot />
        <span v-if="required">* </span>
        <span v-if="optional" class="form-label-optional">
          ({{ $t(optionalText) }})
        </span>
        <span v-if="autoColons && !ghost" class="form-label-colon"> :</span>
      </span>
      <Icon v-if="custom" icon="custom" class="form-label-icon" />
      <span v-if="tooltip" class="flex">
        <Tooltip
          icon="info-circle"
          :color="tooltip.color"
          :message="tooltip.message && $t(tooltip.message)"
          :position="tooltip.position"
          :align="tooltip.align"
          :image="tooltip.image"
        />
      </span>
    </slot>
  </label>
</template>

<script>
import { fontSizeClasses, fontWeightClasses } from '@/utils/styles/styles.js';
import Icon from '@/components/icon/Icon.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'FormLabel',
  components: {
    Icon,
    Tooltip,
  },
  props: {
    required: Boolean,
    optional: Boolean,
    custom: Boolean,
    noMargin: Boolean,
    iconBefore: String,
    iconBeforeSize: String,
    labelFor: String,
    size: {
      type: String,
      default: 'md',
    },
    weight: {
      type: String,
      default: 'normal',
    },
    tooltip: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    ghost: Boolean,
    autoColons: {
      type: Boolean,
      default: true,
    },
    optionalText: {
      type: String,
      default: 'generic.input.optional',
    },
  },
  computed: {
    labelClass() {
      return [
        this.disabled && 'text-skin-muted',
        fontSizeClasses[this.size],
        fontWeightClasses[this.weight],
        this.ghost && 'invisible',
      ];
    },
  },
};
</script>

<style lang="scss">
@import 'form_label.scss';
</style>
