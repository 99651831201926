<template>
  <div
    class="form-radio"
    :class="radioClass"
    @click="selectRadio(id)"
    @mouseover="tooltipIsHover = true"
    @mouseleave="tooltipIsHover = false"
  >
    <div class="form-radio-container" :class="display">
      <div
        v-if="$slots['header']"
        class="form-radio-header grid grid-cols-1 place-content-center"
        :class="display === 'column' && 'w-full'"
      >
        <slot name="header" v-bind="{ ...$props, option, isChecked }" />
      </div>
      <div :class="size && `flex size-${size}`">
        <input
          :id="id"
          class="form-radio-field"
          type="radio"
          :value="id"
          :checked="checked"
          :disabled="disabled && !checked"
          tabindex="0"
        />
        <label
          v-if="label || hint"
          class="form-radio-label items-center"
          :class="{ checked: checked, disabled: disabled && !checked }"
          :for="id"
          @click.prevent
        >
          <span
            v-if="type !== 'button'"
            class="relative block w-3.5 h-3.5 aspect-square rounded-full border border-solid after:inset-px after:absolute content-none after:rounded-full after:transform transition-colors after:transition-all duration-75 after:duration-75"
            :class="[
              !checked && 'border-skin-action-muted after:scale-75',
              checked &&
                'border-skin-action-accent after:bg-skin-action-accent after:scale-100',
            ]"
          />
          <Icon
            v-if="icon && iconPosition === 'left'"
            :icon="icon"
            class="form-radio-label-icon"
          />
          <span
            v-if="!labelAsTooltip && label"
            class="form-radio-label-text flex items-center gap-2"
            :style="labelStyle"
          >
            {{ $t(label) }}
            <FormHint
              v-if="hint && hintDisplay === 'row'"
              :class="{ disabled: disabled && !checked }"
              >{{ $t(hint) }}</FormHint
            >
          </span>
          <Tooltip
            v-if="tooltip"
            icon="info-circle"
            :message="tooltip.message"
            :position="tooltip.position"
            :image="tooltip.image"
            :align="tooltip.align"
          />
        </label>
      </div>
      <slot
        v-if="$slots['footer']"
        name="footer"
        v-bind="{ ...$props, option, isChecked }"
      />
    </div>
    <FormHint
      v-if="hint && hintDisplay === 'column'"
      :class="{ disabled: disabled && !checked }"
      class="flex place-content-center"
    >
      {{ $t(hint) }}
    </FormHint>
    <div v-if="labelAsTooltip && tooltipIsHover" class="tooltip-message">
      <span class="tooltip-message-text">{{ $t(label) }}</span>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/button/BaseButton.vue';
import ClipboardLink from '@/components/link/ClipboardLink.vue';
import FormHint from '@/components/form/FormHint.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import Icon from '@/components/icon/Icon.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'FormRadio',
  components: {
    BaseButton,
    ClipboardLink,
    Icon,
    FormHint,
    FormInput,
    FormSelect,
    Tooltip,
  },
  props: {
    modelValue: String,
    id: String,
    label: String,
    hint: String,
    hintDisplay: {
      type: String,
      default: 'column',
    },
    display: {
      type: String,
      default: 'row',
    },
    type: String,
    icon: String,
    iconPosition: String,
    size: String,
    checked: Boolean,
    disabled: Boolean,
    image: [Object, String],
    illustration: [Object, String],
    tooltip: Object,
    labelAsTooltip: Boolean,
    labelStyle: Object,
    alignCenter: Boolean,
    option: Object,
    subOptions: {
      type: Array,
      default: () => [],
    },
    subOption: {
      type: Object,
      default: function () {
        return {
          id: 'date_jj_mm_aaaa',
          title: 'JJ/MM/AAAA',
        };
      },
    },
  },
  emits: ['update:model-value', 'updateSubOption'],
  data() {
    return {
      tooltipIsHover: false,
    };
  },
  computed: {
    theme() {
      return this.$route.matched[0].meta.theme;
    },
    isChecked() {
      return this.checked;
    },
    radioClass() {
      return [
        this.theme && `theme-${this.theme}`,
        this.checked && 'checked',
        this.alignCenter && 'align-center',
        this.tooltip && 'tooltip',
        this.tooltip && 'position-top',
        this.display,
      ];
    },
  },
  methods: {
    selectRadio(radioValue) {
      !this.disabled && this.$emit('update:model-value', radioValue);
    },
  },
};
</script>

<style lang="scss">
@import 'form_radio.scss';
</style>
