<template>
  <TableRow
    v-if="display === 'table'"
    class="cursor-pointer w-full"
    option
    @click="clickOption"
  >
    <slot v-bind="option" />
  </TableRow>
  <li
    v-else
    v-tooltip="optionTooltip"
    :class="optionClass"
    tabindex="0"
    @click="clickOption"
  >
    <div class="flex flex-row gap-2 items-center">
      <Checkbox v-if="multiple" :checked="selected" :disabled="disabled" />
      <slot v-bind="option">
        <Image
          v-if="option.image"
          :src="option.image"
          :width="30"
          :height="30"
          rounded
          class="aspect-square"
          :alt="optionTitle"
        />
        <span
          class="inline-flex items-center truncate"
          :class="optionLabelClass"
        >{{ optionTitle }}</span>
      </slot>
    </div>
  </li>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUiElement } from '@/composables/useUiElement.js';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Image from '@/components/image/Image.vue';
import TableRow from '@/components/table/TableRow.vue';

const { t } = useI18n();

const props = defineProps({
  option: { type: Object, default: () => ({}) },
  multiple: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  title: { type: Boolean, default: false },
  active: { type: Boolean, default: false },
  display: { type: String, default: 'classic' },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits(['toggle', 'click']);

const { getUiElementOptionClass } = useUiElement(props);

const optionTitle = computed(() => {
  return props.option.title ? t(props.option.title) : null;
});
const optionLabelClass = computed(() => {
  return props.option.id === -1 ? 'text-skin-muted' : '';
});
const optionDisabled = computed(() => {
  return props.disabled || (props.option.disabled && !props.selected);
});
const optionClass = computed(() => {
  return [
    'inline-flex items-center cursor-pointer',
    {
      checked: props.selected,
      'border border-solid border-skin-ui -mt-[1px]': props.component,
      'text-skin-muted': optionDisabled.value,
    },
    ...getUiElementOptionClass(
      props.multiple,
      props.active,
      props.selected,
      props.disabled,
      props.title
    ),
  ];
});

const optionTooltip = computed(() => {
  if (props.option.disabled && props.option.disabledHint) {
    return {
      content: t(props.option.disabledHint),
      placement: 'right-end',
      html: true,
    };
  }
  return null;
});

const clickOption = () => {
  if (!props.disabled) {
    emit('click');
  }
};
</script>

<style lang="scss">
//@import 'form_single_select_option';
</style>
