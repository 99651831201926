export default class Error {
  /**
   * Create a new Error instance.
   */
  constructor() {
    this.errors = {};
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has(field) {
    return field in this.errors;
  }

  /**
   * Determine if we have any errors.
   */
  any() {
    return Object.keys(this.errors).length > 0;
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   */
  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  set(field, message) {
    this.errors[field] = [message];
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record(errors) {
    this.errors = errors;
  }

  format(errors) {
    if (typeof errors === 'object') {
      if ('error' in errors) {
        if (
          errors.error.indexOf('email') > -1 ||
          errors.error.indexOf('credentials invalid') > -1
        ) {
          errors.email = [errors.error];
        } else if (errors.error.indexOf('password') > -1) {
          errors.password = [errors.error];
        }
        delete errors.error;
      }
      if ('password' in errors) {
        Object.assign(
          errors.password,
          errors.password.map((message) => {
            if (message === 'password.invalid') {
              return 'error.login.password.invalid';
            } else if (message === 'min') {
              return 'error.login.password.min';
            } else {
              return message;
            }
          })
        );
        if (
          errors.password.indexOf('confirmation') > -1 ||
          errors.password.indexOf('confirmed') > -1
        ) {
          if (errors.password.indexOf('confirmation') > -1) {
            errors.password.splice(errors.password.indexOf('confirmation'), 1);
          } else {
            errors.password.splice(errors.password.indexOf('confirmed'), 1);
          }
          errors.password_confirmation = ['error.login.password.match'];
        }
      }
      if ('email' in errors) {
        Object.assign(
          errors.email,
          errors.email.map((message) => {
            if (message === 'unique') {
              return 'error.login.email.unique';
            } else if (
              message === 'invalid' ||
              message.indexOf('credentials invalid') > -1
            ) {
              return 'error.login.email.invalid';
            } else if (
              message.indexOf('No user registered') > -1 ||
              message.indexOf('The selected email is invalid.') > -1
            ) {
              return 'error.login.email.unregistered';
            } else {
              return message;
            }
          })
        );
      }

      if ('status' in errors) {
        delete errors.status;
      }

      for (let field in errors) {
        if (errors[field].length === 0) {
          delete errors[field];
        }
      }
      this.record(errors);
    }
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear(field) {
    if (field) {
      delete this.errors[field];

      return;
    }
    this.errors = {};
  }
}
