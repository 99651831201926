<template>
  <div class="flex flex-col gap-6 theme-purple">
    <PageHeaderRow>
      <template #title>
        <h2 class="page-title text-skin-accent">
          {{ $t('kpis.home.title', { date: displayDate }) }}
          <small class="text-skin-base text-sm font-light"
            >({{ $t('kpis.home.description') }})</small
          >
        </h2>
      </template>
      <template #action-1>
        <router-link to="/statistiques" class="flex">
          <BaseButton
            class="dashboard-header-link"
            weight="medium"
            link
            underline
            with-theme
            :label="$t('kpis.home.see-details')"
          />
        </router-link>
      </template>
    </PageHeaderRow>
    <div class="flex flex-wrap gap-6">
      <router-link to="/statistiques#registered" class="flex flex-1">
        <KpiCard :item="registered" :header="false" kpi-type="registered" />
      </router-link>
      <router-link to="/statistiques#registrations" class="flex flex-1">
        <KpiCard :item="registrations" :header="false" kpi-type="registrations" />
      </router-link>
      <router-link to="/statistiques#subscribed" class="flex flex-1">
        <KpiCard :item="subscribed" :header="false" kpi-type="subscribed" />
      </router-link>
      <router-link to="/statistiques#subscriptions" class="flex flex-1">
        <KpiCard :item="subscriptions" :header="false" kpi-type="subscriptions" />
      </router-link>
      <router-link to="/statistiques#unsubscribed" class="flex flex-1">
        <KpiCard :item="unsubscribed" :header="false" kpi-type="unsubscribed" />
      </router-link>
    </div>
    <div v-if="false" class="flex flex-wrap">
      <Card class="flex-1">
        <router-link
          class="w-full flex flex-wrap"
          to="/statistiques#transactions"
        >
          <KpiCard class="flex flex-1" wrapper :item="transactions" />
          <KpiCard
            class="flex flex-2"
            wrapper
            :item="transactionsRepartition"
          />
        </router-link>
      </Card>
    </div>
    <div class="flex flex-wrap">
      <span class="kpi-summary--hint">* {{ $t('kpis.home.asterisk') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BaseButton from '@/components/button/BaseButton.vue';
import Card from '@/components/card/Card.vue';
import KpiCard from '@/components/dashboard/KpiCard.vue';
import PageHeaderRow from '@/components/page/PageHeaderRow.vue';
import moment from 'moment';

export default {
  name: 'DashboardHome',
  components: { Card, PageHeaderRow, BaseButton, KpiCard },
  computed: {
    displayDate() {
      return moment(this.date).subtract(1, 'days').format('DD/MM/YYYY');
    },
    subscribed() {
      return this.summary?.find((item) => item.id === 'subscribed');
    },
    subscriptions() {
      return this.summary?.find((item) => item.id === 'subscriptions');
    },
    registered() {
      return this.summary?.find((item) => item.id === 'registered');
    },
    registrations() {
      return this.summary?.find((item) => item.id === 'registrations');
    },
    unsubscribed() {
      return this.summary?.find((item) => item.id === 'unsubscribed');
    },
    transactions() {
      return this.summary?.find((item) => item.id === 'transactions');
    },
    transactionsRepartition() {
      return this.summary?.find(
        (item) => item.id === 'transactionsRepartition'
      );
    },
    ...mapState({
      loading: (state) => state.kpis.loading,
      date: (state) => state.kpis.summaryDate,
    }),
    ...mapGetters({
      summary: 'kpis/summary',
    }),
  },
  methods: {
    ...mapActions('kpis', {
      initDashboardSummary: 'initDashboardSummary',
      refreshDashboardSummary: 'refreshDashboardSummary',
    }),
    ...mapMutations('kpis', {
      resetDashboard: 'resetDashboard',
    }),
    async handleVisibilityChange() {
      const now = moment().subtract(1, 'days').format('DD/MM/YYYY')
      const summaryDateIsInThePast = this.displayDate !== now
      if (!document.hidden && summaryDateIsInThePast) {
        await this.refreshDashboardSummary();
      }
    },
  },
  async created() {
    await this.initDashboardSummary();
    document.addEventListener(
      'visibilitychange',
      async () => await this.handleVisibilityChange(),
      false
    );
  },
  beforeRouteLeave(to, from, next) {
    this.resetDashboard();
    next();
  },
};
</script>

<style lang="scss">
@import '@/components/dashboard/dashboard_home.scss';
</style>
