import { GenericStatisticsService } from '@/services/index.js';
import {
  datePresets,
  granularities as granularityArray,
  kpisTypes,
} from '@/data/kpis';
import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';

const summariesService = new GenericStatisticsService('summaries');

const dateIdFormat = 'YYYY-MM-DD';
const endDateObject = moment().subtract(1, 'days')
const startDateObject = endDateObject.clone().startOf('month');
const start = startDateObject.format();
const end = endDateObject.format();
const startDateId = moment(start).format(dateIdFormat);
const endDateId = moment(end).format(dateIdFormat);
const granularity = granularityArray[0].id;
const datePreset = datePresets[0].id;
const granularityMin = granularity;
const granularityMax = granularityArray[granularityArray.length - 1].id;
const summaryDate = moment();
const state = {
  selectedDate: null,
  summaryDate,
  range: {
    start,
    end,
  },
  datePresets,
  datePreset,
  summary: [],
  transitionDuration: 200,
  startDateId,
  endDateId,
  granularity,
  granularityMin,
  granularityMax,
  granularityArray,
  loading: true,
  error: false,
  locale: {
    fr: {
      decimal: '.',
      thousands: ' ',
      grouping: [3],
      currency: ['€', ''],
      dateTime: '%A %e %B %Y à %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      shortDays: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
      months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      shortMonths: [
        'Janv.',
        'Févr.',
        'Mars',
        'Avr.',
        'Mai',
        'Juin',
        'Juil.',
        'Août',
        'Sept.',
        'Oct.',
        'Nov.',
        'Déc.',
      ],
    },
  },
};

const actions = {
  initDashboard({ state, dispatch }) {
    dispatch('updateDateRange', { value: state.range });
  },
  async initDashboardSummary({ dispatch }) {
    await dispatch('search');
  },
  async refreshDashboardSummary({ commit, dispatch }) {
    commit('resetSummaryDate');
    await dispatch('search');
  },
  async search({ state, commit, dispatch }) {
    commit('setLoading', true);
    const date = state.summaryDate.format(dateIdFormat);
    const queryString = `date=${date}`;
    return summariesService.search(queryString).then(
      (summaries) => {
        commit('getAllSuccess', summaries);
        commit('setLoading', false);
        return Promise.resolve(summaries);
      },
      (error) => {
        const alert = {
          id: 'summaries-search-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.search.error',
        };
        commit('setLoading', false);
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  updateDashboard({ commit, dispatch }) {
    const promises = [];
    commit('setLoading', true);
    promises.push(dispatch('subscribedKpis/search', null, { root: true }));
    promises.push(dispatch('subscriptionsKpis/search', null, { root: true }));
    promises.push(dispatch('unsubscribedKpis/search', null, { root: true }));
    promises.push(dispatch('registeredKpis/search', null, { root: true }));
    promises.push(dispatch('registrationsKpis/search', null, { root: true }));
    //promises.push(dispatch('transactionsKpis/search', null, { root: true }));
    promises.push(dispatch('topViewsAggregateKpis/search', null, { root: true }));
    promises.push(dispatch('topViewsKpis/search', null, { root: true }));

    return Promise.all(promises).then(
      (kpis) => {
        commit('setLoading', false);
        return Promise.resolve(kpis);
      },
      (error) => {
        const alert = {
          id: 'kpis-error',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  updateDatePreset({ dispatch, commit, state }, id) {
    commit('setDatePreset', id);
    const value = {
      start: null,
      end: null,
    };
    switch (state.datePreset) {
      case 'custom':
        return;
      case 'yesterday':
        value.start = moment().subtract(1, 'days').format();
        value.end = moment().subtract(1, 'days').format();
        break;
      case 'last-week':
        value.start = moment().subtract(7, 'days').format();
        value.end = moment().subtract(1, 'days').format();
        break;
      case 'last-four-weeks':
        value.start = moment().subtract(4, 'weeks').format();
        value.end = moment().subtract(1, 'weeks').format();
        break;
      case 'last-three-months':
        value.start = moment().subtract(3, 'months').format();
        value.end = moment().subtract(1, 'month').format();
        break;
    }
    dispatch('updateDateRange', { value, isPreset: true });
  },
  updateDateRange({ commit, state, dispatch }, { value, isPreset = false }) {
    let startVal = moment(value.start);
    let endVal = moment(value.end);
    let nbOfDays = Math.abs(startVal.diff(endVal, 'days')) + 1;
    dispatch('setMinMaxGranularity', nbOfDays);
    switch (state.granularity) {
      case 'week':
        startVal = startVal.startOf('isoWeek');
        endVal = endVal.endOf('isoWeek');
        break;
      case 'month':
        startVal = startVal.startOf('month');
        endVal = endVal.endOf('month');
        break;
      case 'year':
        startVal = startVal.startOf('year');
        endVal = endVal.endOf('year');
        break;
    }
    const start = startVal.startOf('day').format();
    const end = endVal.endOf('day').format();

    if (!isPreset) {
      commit('setDatePreset', 'custom');
    }

    commit('updateDateRange', { start, end });
  },
  setGranularity({ commit, getters, state, dispatch }, granularityId) {
    const askedGranularityIndex = state.granularityArray.findIndex(
      (g) => g.id === granularityId
    );
    const granularity =
      askedGranularityIndex >= getters.granularityMinIndex
        ? granularityId
        : state.granularityMin;
    const { start, end } = state.range;
    const value = { start, end };
    commit('setGranularity', granularity);
    dispatch('updateDateRange', { value, isPreset: false });
  },
  setMinMaxGranularity({ commit, getters, state, dispatch }, nbOfDays) {
    const filteredGranularity = state.granularityArray.filter(
      (g) => nbOfDays >= g.range[0] && nbOfDays <= g.range[1]
    );
    const defaultGranularity = filteredGranularity[0].id;
    const min = filteredGranularity[0].id ?? 'days';
    const max =
      filteredGranularity[filteredGranularity.length - 1].id ?? 'years';
    commit('setMinMaxGranularity', { nbOfDays, min, max });
    if (getters.granularityIndex < getters.granularityMinIndex) {
      commit('setGranularity', min);
    } else if (getters.granularityIndex > getters.granularityMaxIndex) {
      commit('setGranularity', min);
    }
  },
};
const mutations = {
  updateField,
  reset(state) {
    state.loading = false;
  },
  resetSummaryDate(state) {
    state.summaryDate = moment();
  },
  resetDashboard(state) {
    const endDateObject = moment().subtract(1, 'days')
    const startDateObject = endDateObject.clone().startOf('month');
    const start = startDateObject.format();
    const end = endDateObject.format();
    state.selectedDate = null;
    state.summaryDate = moment();
    state.range = { start, end }
    state.datePreset = datePresets[0].id;
    state.startDateId = moment(start).format(dateIdFormat);
    state.endDateId = moment(end).format(dateIdFormat);
    state.granularity = granularityArray[0].id;
    state.granularityMin = granularityArray[0].id;
    state.granularityMax = granularityArray[granularityArray.length - 1].id;
  },
  setDatePreset(state, presetId) {
    state.datePreset = state.datePresets.find(
      (preset) => preset.id === presetId
    ).id;
  },
  setMinMaxGranularity(state, { nbOfDays, min, max }) {
    const filteredGranularity = state.granularityArray.filter(
      (g) => nbOfDays >= g.range[0] && nbOfDays <= g.range[1]
    );
    state.granularityMin = filteredGranularity[0].id ?? 'days';
    state.granularityMax =
      filteredGranularity[filteredGranularity.length - 1].id ?? 'years';
    //state.granularity = state.granularityMin;
  },
  setGranularity(state, granularity) {
    state.granularity = granularity;
  },
  updateFilterValue(state, [id, value]) {
    state.filters.find((filter) => filter.id === id).value = value;
  },
  updateDateRange(state, range) {
    const { start, end } = range;
    state.range = { start, end };
    state.startDateId = moment(start).format(dateIdFormat);
    state.endDateId = moment(end).format(dateIdFormat);
  },
  removeFilter(state, id) {
    state.selectedFilters = state.selectedFilters.filter(
      (filter) => filter.id !== id
    );
  },

  selectDate(state, date) {
    state.selectedDate = date;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  getAllSuccess(state, summary) {
    state.summary = summary[0];
  },
};

const getters = {
  getField,
  granularityIndex: (state) => {
    return state.granularityArray.findIndex((g) => g.id === state.granularity);
  },
  granularityMinIndex: (state) => {
    return state.granularityArray.findIndex(
      (g) => g.id === state.granularityMin
    );
  },
  granularityMaxIndex: (state) => {
    return state.granularityArray.findIndex(
      (g) => g.id === state.granularityMax
    );
  },
  granularityArrayFiltered: (state, getters) => {
    return state.granularityArray.map((g, index) => {
      return {
        ...g,
        disabled: !(
          index >= getters.granularityMinIndex &&
          index <= getters.granularityMaxIndex
        ),
      };
    });
  },
  summary: (state, getters, rootState) => {
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    return kpisTypes.map((type) => {
      let value, evolution_value, evolution;
      if (typeof state.summary !== 'undefined') {
        value = state.summary[type.summaryId];
        value = type.valueType === 'currency' ? value / 100 : value;
        evolution_value =
          state.summary[`${type.summaryId}_evolution`] === '0.0'
            ? 0
            : parseFloat(state.summary[`${type.summaryId}_evolution`]);
        evolution =
          evolution_value === 0
            ? 'equal'
            : evolution_value < 0
              ? 'negative'
              : 'positive';
      } else {
        value = 0;
        evolution_value = '0.0';
        evolution = 'equal';
      }

      const sum = {
        ...type,
        type: type.id === 'transactionsRepartition' ? 'pie' : 'summary',
        value,
        label: type.title,
        evolution,
        evolution_value,
        message: type.summaryMessage,
        footer: false,
      };
      if (sum.type === 'pie') {
        sum.data = {
          title: 'kpis.home.share.environments',
          items: [
            {
              key: 'kpis.home.share.types.app',
              value: state.summary?.app_total_transactions ?? 0,
              color: '#b05574',
            },
            {
              key: 'kpis.home.share.types.web',
              value: state.summary?.stripe_total_transactions ?? 0,
              color: '#deba1c',
            },
          ],
        };
      }
      return sum;
    });
  },
  getLoadingKpiType: (state, rootState) => (type) => {
    const kpiType = type + 'Kpis';
    return rootState[kpiType].loading;
  }
};

export const kpis = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
