<template>
  <Transition
    appear-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
    appear-from-class="opacity-0"
    appear-to-class="opacity-100"
    enter-active-class="transform-gpu transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transform-gpu transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <section
      v-if="loaderVisible"
      class="fixed inset-0 top-[60px] text-center z-[1000] backdrop-blur-sm"
    >
      <div
        class="h-full w-full bg-skin-background opacity-80 will-change-auto"
      />
      <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <img src="/src/images/loader/jump.gif" width="220" />
      </div>
    </section>
  </Transition>
</template>

<script>
export default {
  name: 'LoaderFull',
  props: ['loaderVisible'],
  // computed: {
  //   ...mapState({
  //     visible: 'loaderVisible'
  //   }),
  // },
  watch: {
    loaderVisible(value) {
      document.body.classList.toggle('overflow-hidden', value);
    },
  },
};
</script>
