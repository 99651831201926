import {
  cloneDeep,
  find,
  isEmpty,
  isNil,
  isNull,
  isString,
  pickBy,
} from 'lodash';
import {
  picturesCatalogFormats,
  picturesDefaultMandatory,
  picturesDefaultOptional,
  picturesLabels,
  picturesModelList,
  picturesNames,
  picturesSelectionMandatory,
  picturesSelectionOptional,
  picturesTypes,
} from '@/data/pictures';

import { attachmentFilesDefaultMimeTypes } from '@/data/attachmentFiles';
import { colorsHexaCode } from '@/data/constants';
import paginationConfigs from '@/data/paginationConfigs';
import scrollArrowTypes from '@/data/scrollArrowTypes';

export class Platform {
  id = null;
  name = null;
  catalog_source = null;
  children = null;
  family = null;
  front_url = null;
  fo_locale_default = null;
  bo_locale_default = null;
  country_default = null;
  stripe_key = null;
  stripe_secret = null;
  ingest_config = null;
  content_config = {
    rentals: [],
    pricing_type: null,
    social: {
      media: {
        fb: null,
        ig: null,
        tw: null,
        wa: null,
      },
      share: {
        fb: null,
        tw: null,
      },
    },
    catalog: null,
    devices: null,
    picture: null,
    program: {},
    serie: {},
    selection: {},
    colors: {
      default: {
        texts: null,
        primary: null,
        tertiary: null,
        secondary: null,
        'primary-hover': null,
        'secondary-hover': null
      }
    },
    default_color: {},
    dropdown_color: {},
    footer_block_id: null,
    header_block_id: null,
    background_color: null,
    drm_licence_lifespan: null,
    pagination: {},
  };
  search_config = null;
  newsletter_config = null;
  user_config = null;
  tracking_config = null;
  billing_config = null;
  created_at = null;
  updated_at = null;
  deleted_at = null;
  picture = {
    logos: [],
  };
  media = [];
  pictureTypeList = picturesTypes.map((pictureType) => pictureType.id);
  pictureModelList = picturesModelList;
  buttonList = [
    { id: 'play', cta_label: 'voir' },
    { id: 'trailer', cta_label: 'bande-annonce' },
  ];

  constructor({
    id,
    name,
    parent_id,
    platform_type_id,
    catalog_source = '',
    children = {},
    family = {},
    front_url,
    fo_locale_default,
    bo_locale_default,
    country_default,
    country_default_id,
    stripe_key,
    stripe_secret,
    ingest_config,
    content_config,
    search_config,
    newsletter_config,
    user_config,
    tracking_config,
    billing_config,
    created_at,
    updated_at,
    deleted_at,
    picture = {},
    media,
  } = {}) {
    this.id = id;
    this.name = name;
    this.catalog_source = catalog_source;
    this.front_url = front_url;
    this.children = children;
    this.family = family;
    this.is_parent_platform = children.length > 0 || isNull(parent_id);
    this.fo_locale_default = fo_locale_default;
    this.bo_locale_default = bo_locale_default;
    this.country_default = country_default ?? 'fr';
    this.country_default_id = country_default_id ?? 250;
    this.stripe_key = stripe_key;
    this.stripe_secret = stripe_secret;
    this.ingest_config = ingest_config;
    this.content_config = content_config;
    this.search_config = search_config;
    this.newsletter_config = newsletter_config;
    this.user_config = user_config;
    this.tracking_config = tracking_config;
    this.billing_config = billing_config;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.picture = picture;
    this.media = media;

    this.setPlatformType(platform_type_id);
    this.setDefault();
    this.setNewsletterConfig();
    this.setPictures();
    this.setCatalog();
    this.setSelection();
    this.setAvod();
    this.setSeo();
    this.setUser();
    this.setRentals();
    this.setPricingType();
    this.setGeoblocking();
    this.setDefaultColors();
    this.setDevices();
    this.setSocial();
    this.setThumbs();
    this.setButtons();
    this.setScrollArrow();
    this.setFormats();
    this.setAnimation();
    this.setPicturesOptionalMandatory();
    this.setVideo();
    this.setFeature();
    this.setBlock();
    this.setPurchasely();
    this.setTrackingConfig();
    this.setDidomi();
    this.setAttachment();
    this.setSearchConfig();
    this.removeDeprecated();
    this.setPagination();
  }

  setPlatformType(val) {
    switch (val) {
      case 1:
        this.platformType = 'desktop';
        break;
      case 2:
        this.platformType = 'tablet';
        break;
      case 3:
        this.platformType = 'mobile';
        break;
      case 4:
        this.platformType = 'orange';
        break;
      case 5:
        this.platformType = 'free';
        break;
      case 6:
        this.platformType = 'apple_tv';
        break;
      case 7:
        this.platformType = 'android_tv';
        break;
      case 8:
        this.platformType = 'samsung_tv';
        break;
      default:
        this.platformType = 'unknown';
        break;
    }
  }

  setDefault() {
    if (isNil(this.content_config)) {
      this.content_config = [];
    }

    if (isNil(this.billing_config)) {
      this.billing_config = [];
    }

    if (isNil(this.user_config)) {
      this.user_config = [];
    }

    if (isNil(this.newsletter_config) || isEmpty(this.newsletter_config)) {
      this.newsletter_config = {};
    }

    if (isNil(this.children)) {
      this.children = {};
    }

    if (isNil(this.family)) {
      this.family = {};
    }
  }

  setNewsletterConfig() {
    if (isNil(this.newsletter_config.nameFrom)) {
      this.newsletter_config.nameFrom = null;
    }

    if (isNil(this.newsletter_config.emailFrom)) {
      this.newsletter_config.emailFrom = null;
    }

    if (isNil(this.newsletter_config.catchphrase)) {
      this.newsletter_config.catchphrase = null;
    }

    if (isNil(this.newsletter_config.username)) {
      this.newsletter_config.username = null;
    }

    if (isNil(this.newsletter_config.password)) {
      this.newsletter_config.password = null;
    }

    if (isNil(this.newsletter_config.apiKey)) {
      this.newsletter_config.apiKey = null;
    }

    if (isNil(this.newsletter_config.defaultListName)) {
      this.newsletter_config.defaultListName = null;
    }

    if (isNil(this.newsletter_config.block)) {
      this.newsletter_config.block = {
        activated: false,
        template: 'default',
        title: null,
        description: null,
        submit: 'Ok',
        routes: null,
        fields: {
          email: 'Adresse e-mail',
          lastname: null,
          firstname: null,
        },
      };
    }
  }

  setAnimation() {
    var rowBlockNames = [
      'row-banners',
      'row-full-banners',
      'row-posters',
      'row-roundeds',
      'row-posters-xl',
      'row-thumbnails',
      'row-thumbnails-xl',
      'row-mosaic-posters',
      'row-mosaic-thumbnails',
      'row-posters-xl-with-txt',
      'row-thumbnails-xl-with-txt',
    ];

    var carouselBlockNames = ['carousel'];

    var defaultBlock = {
      id: 0,
      label: null,
      checked: false,
      nb_lines: 1,
      differsParent: false,
      duration: false,
      metadatas: [],
    };

    var defaultBlockChecked = cloneDeep(
      find(this.content_config.formats, ['checked', true]) ?? defaultBlock
    );
    delete defaultBlockChecked.id;
    delete defaultBlockChecked.label;
    delete defaultBlockChecked.checked;
    delete defaultBlockChecked.nb_lines;

    /*  For the moment they will change their mind about default block choice
        var defaultBlockPoster = this.content_config.formats.find(x => x.label === 'posters') ?? defaultBlock
        delete defaultBlockPoster.id
        delete defaultBlockPoster.label
        delete defaultBlockPoster.checked
        delete defaultBlockPoster.nb_lines

        var defaultBlockThumbnail = this.content_config.formats.find(x => x.label === 'thumbnails') ?? defaultBlock
        delete defaultBlockThumbnail.id
        delete defaultBlockThumbnail.label
        delete defaultBlockThumbnail.checked
        delete defaultBlockThumbnail.nb_lines*/

    var defaultCarousel = {
      type: 'classic',
      color: '#004C66',
      aspect: 'aspect-gradient-left',
      height: 'lg',
      scroll: {
        color: '#FF0000',
        type: 'controls-button',
        pagination: true,
      },
      text: {
        cta: {
          play: true,
          trailer: true,
          favorite: true,
        },
        color: '#004C66',
        category: true,
        title: true,
        position: 'text-position-left',
        background: 'text-with-background',
        metadata_obj: null,
        metadata_id: {},
      },
    };

    var animation = {};
    var self = this.content_config;
    if (typeof self.animation === 'undefined') {
      self.animation = {};
    }

    animation.spacing_top =
      'spacing_top' in this.content_config.animation
        ? this.content_config.animation.spacing_top
        : 'lg';
    animation.spacing_bottom =
      'spacing_bottom' in this.content_config.animation
        ? this.content_config.animation.spacing_bottom
        : 'lg';

    rowBlockNames.forEach(function (blockName) {
      if (typeof self.animation[blockName] !== 'undefined') {
        var tempBlock = self.animation[blockName];
        if (
          typeof tempBlock.differsParent !== 'undefined' &&
          typeof tempBlock.differsParent !== 'boolean'
        ) {
          tempBlock.duration = true;
        }
        if (
          typeof tempBlock.duration !== 'undefined' &&
          typeof tempBlock.duration !== 'boolean'
        ) {
          tempBlock.duration = false;
        }
        if (
          typeof tempBlock.metadatas !== 'undefined' &&
          !Array.isArray(tempBlock.metadatas)
        ) {
          tempBlock.metadatas = [];
        }
        //if no id, remove it from array
        tempBlock.metadatas = tempBlock.metadatas.filter((element) =>
          Number.isInteger(element.id)
        );

        tempBlock.metadatas.map((element) => {
          element.size =
            typeof element.size === 'undefined' ? 'md' : element.size;
          element.style =
            typeof element.style === 'undefined' ? 'normal' : element.style;
          return element;
        });
        animation[blockName] = tempBlock;
      } else {
        animation[blockName] = cloneDeep(defaultBlockChecked);
      }
    });

    carouselBlockNames.forEach(function (blockName) {
      if (typeof self.animation[blockName] !== 'undefined') {
        var tempBlock = self.animation[blockName];

        if (typeof tempBlock.type !== 'string') {
          tempBlock.type = 'classic';
        }
        if (typeof tempBlock.color !== 'string') {
          tempBlock.color = '#004C66';
        }
        if (typeof tempBlock.aspect !== 'string') {
          tempBlock.aspect = 'aspect-gradient-left';
        }
        if (typeof tempBlock.height !== 'string') {
          tempBlock.height = 'lg';
        }
        if (!Array.isArray(tempBlock.metadatas)) {
          tempBlock.metadatas = [];
        }

        if (typeof tempBlock.text !== 'object') {
          tempBlock.text = {
            cta: { play: true, trailer: true, favorite: true },
            background: 'text-with-background',
            color: '#004C66',
            category: false,
            title: true,
            position: 'text-position-left',
            metadata_obj: null,
            metadata_id: {},
          };
        } else {
          var text = cloneDeep(tempBlock.text);
          text.color =
            typeof text.color === 'undefined' ? '#004C66' : text.color;
          text.category =
            typeof text.category !== 'boolean' ? false : text.category;
          text.title = typeof text.title !== 'boolean' ? true : text.title;
          text.position =
            typeof text.position !== 'string'
              ? 'text-position-left'
              : text.position;
          text.background =
            typeof text.background !== 'string'
              ? text.background === true
                ? 'text-with-background'
                : 'text-without-background'
              : text.background;
          text.metadata_obj =
            typeof text.metadata_obj !== 'number' ? false : text.metadata_obj;
          text.cta = typeof text.cta !== 'object' ? {} : text.cta;
          tempBlock.text = text;

          var cta = cloneDeep(tempBlock.text.cta);
          cta.play = typeof cta.play !== 'boolean' ? false : cta.play;
          cta.trailer = typeof cta.trailer !== 'boolean' ? false : cta.trailer;
          cta.favorite =
            typeof cta.favorite !== 'boolean' ? false : cta.favorite;
          tempBlock.text.cta = cta;
        }

        if (typeof tempBlock.scroll !== 'object') {
          tempBlock.scroll = {
            color: colorsHexaCode.light,
            type: 'controls-button',
            pagination: true,
          };
        } else {
          var scroll = cloneDeep(tempBlock.scroll);
          scroll.color =
            typeof scroll.color !== 'string'
              ? colorsHexaCode.light
              : scroll.color;
          scroll.backgroundcolor =
            typeof scroll.backgroundcolor !== 'string'
              ? colorsHexaCode.light
              : scroll.backgroundcolor;
          scroll.type =
            typeof scroll.type !== 'string' ? 'controls-button' : scroll.type;
          scroll.pagination =
            typeof scroll.pagination !== 'boolean' ? false : scroll.pagination;
          tempBlock.scroll = scroll;
        }

        animation[blockName] = tempBlock;
      } else {
        animation[blockName] = cloneDeep(defaultCarousel);
      }
    });

    this.content_config.animation = animation;
  }

  /**
   * Set SEO
   *
   * @author Thomas TECHER <ttecher@vodfactory.com>
   * @returns void
   */
  setSeo() {
    if (
      isNil(this.content_config.seo) ||
      (Array.isArray(this.content_config.seo) &&
        this.content_config.seo.pages === 0)
    ) {
      this.content_config.seo = {};
    }
    if (
      isNil(this.content_config.seo.json) ||
      (Array.isArray(this.content_config.seo.json) &&
        this.content_config.seo.json.length === 0)
    ) {
      this.content_config.seo.json = {};
    }
    if (
      isNil(this.content_config.seo.pages) ||
      (Array.isArray(this.content_config.seo.pages) &&
        this.content_config.seo.pages.length === 0)
    ) {
      this.content_config.seo.pages = {};
    }
  }

  setUser() {
    if (typeof this.user_config === 'undefined') {
      this.user_config = {};
    }

    ['apple', 'facebook', 'google', 'google_device'].forEach((element) => {
      if (typeof this.user_config[element] === 'undefined') {
        this.user_config[element] = {
          enabled: false,
          client_id: null,
          client_secret: null,
        };
      } else {
        if (typeof this.user_config[element].enabled === 'undefined') {
          this.user_config[element].enabled = false;
        }
        if (typeof this.user_config[element].client_id === 'undefined') {
          this.user_config[element].client_id = null;
        }
        if (typeof this.user_config[element].client_secret === 'undefined') {
          this.user_config[element].client_secret = null;
        }
        if (typeof this.user_config[element].client_token === 'undefined') {
          this.user_config[element].client_token = null;
        }
      }
    });

    if (typeof this.user_config.captcha === 'undefined') {
      this.user_config.captcha = {};
    }

    ['google'].forEach((element) => {
      if (typeof this.user_config.captcha[element] === 'undefined') {
        this.user_config.captcha[element] = {
          enabled: {
            register: false,
            login: false,
          },
          client_id: null,
          client_secret: null,
          version: 'v2',
          min_score: 0.7,
        };
      } else {
        if (typeof this.user_config.captcha[element].enabled !== 'object') {
          this.user_config.captcha[element].enabled = {
            register: false,
            login: false,
          };
        }
        ['register', 'login'].forEach((type) => {
          if (
            typeof this.user_config.captcha[element].enabled[type] !== 'boolean'
          ) {
            this.user_config.captcha[element].enabled[type] = false;
          }
        });
        if (
          typeof this.user_config.captcha[element].client_id === 'undefined'
        ) {
          this.user_config.captcha[element].client_id = null;
        }
        if (
          typeof this.user_config.captcha[element].client_secret === 'undefined'
        ) {
          this.user_config.captcha[element].client_secret = null;
        }
        if (typeof this.user_config.captcha[element].version === 'undefined') {
          this.user_config.captcha[element].version = 'v2';
        }
        if (
          typeof this.user_config.captcha[element].min_score === 'undefined'
        ) {
          this.user_config.captcha[element].min_score = 0.7;
        }
      }
    });

    if (typeof this.user_config.otto === 'undefined') {
      this.user_config.otto = {
        user_info: { birth_year: false, postal_code: false },
        mail_domain: [
          '([a-z0-9._%+-]{2,255})(@)([a-z0-9.-]{1,255})(\\.)([a-z]{2,12})',
        ],
      };
    } else {
      if (typeof this.user_config.otto.user_info === 'undefined') {
        this.user_config.otto.user_info = {
          birth_year: false,
          postal_code: false,
        };
      } else {
        ['birth_year', 'postal_code'].forEach((element) => {
          if (typeof this.user_config.otto.user_info[element] === 'undefined') {
            this.user_config.otto.user_info[element] = false;
          }
        });
      }

      // Set default OTTO forms

      if (
        isNil(this.user_config.otto.forms) ||
        isEmpty(this.user_config.otto.forms)
      ) {
        this.user_config.otto.forms = {
          register: ['email', 'password'],
          account: ['email', 'password'],
        };
      }

      ['register', 'account'].forEach((element) => {
        if (
          isNil(this.user_config.otto.forms[element]) ||
          isEmpty(this.user_config.otto.forms[element])
        ) {
          this.user_config.otto.forms[element] = ['email', 'password'];
        }
      });

      // Set default fields

      if (
        isNil(this.user_config.otto.fields) ||
        isEmpty(this.user_config.otto.fields)
      ) {
        this.user_config.otto.fields = { email: {}, password: {} };
      }

      ['email', 'password'].forEach((element) => {
        if (!this.user_config.otto.forms.register.includes(element)) {
          this.user_config.otto.forms.register.push(element);
        }

        if (!this.user_config.otto.forms.account.includes(element)) {
          this.user_config.otto.forms.account.push(element);
        }

        if (
          isNil(this.user_config.otto.fields[element]) ||
          isEmpty(this.user_config.otto.fields[element])
        ) {
          this.user_config.otto.fields[element] = {};
        }
      });

      if (!this.user_config.otto.fields.email?.domains?.length > 0) {
        this.user_config.otto.fields.email.domains = [
          '([a-z0-9._%+-]{2,255})(@)([a-z0-9.-]{1,255})(\\.)([a-z]{2,12})',
        ];
      } else if (
        typeof this.user_config.otto.fields.email.domain === 'string'
      ) {
        this.user_config.otto.fields.email.domain = [
          this.user_config.otto.fields.email.domain,
        ];
      }

      if (isNil(this.user_config.otto.fields.email.must_be_confirmed)) {
        this.user_config.otto.fields.email.must_be_confirmed = false;
      }

      if (isNil(this.user_config.otto.fields.password.min_length)) {
        this.user_config.otto.fields.password.min_length = 6;
      }

      if (isNil(this.user_config.otto.fields.password.must_be_confirmed)) {
        this.user_config.otto.fields.password.must_be_confirmed = true;
      }

      if (isNil(this.user_config.otto.fields.password.must_have_letter)) {
        this.user_config.otto.fields.password.must_have_letter = false;
      }

      if (isNil(this.user_config.otto.fields.password.must_have_number)) {
        this.user_config.otto.fields.password.must_have_number = false;
      }

      if (isNil(this.user_config.otto.fields.password.must_have_symbol)) {
        this.user_config.otto.fields.password.must_have_symbol = false;
      }

      if (isNil(this.user_config.otto.fields.password.must_be_uncompromised)) {
        this.user_config.otto.fields.password.must_be_uncompromised = false;
      }

      if (isNil(this.user_config.otto.fields.password.must_have_mixed_case)) {
        this.user_config.otto.fields.password.must_have_mixed_case = false;
      }

      if (
        isNil(this.user_config.otto.fields.password.must_not_have_repetition)
      ) {
        this.user_config.otto.fields.password.must_not_have_repetition = false;
      }

      if (
        isNil(this.user_config.otto.user_info_account_disable) ||
        isEmpty(this.user_config.otto.user_info_account_disable)
      ) {
        this.user_config.otto.user_info_account_disable = {};
      }

      if (isNil(this.user_config.otto.user_info_account_disable.email)) {
        this.user_config.otto.user_info_account_disable.email = false;
      }

      if (isNil(this.user_config.otto.user_info_account_disable.firstname)) {
        this.user_config.otto.user_info_account_disable.firstname = false;
      }

      if (isNil(this.user_config.otto.user_info_account_disable.lastname)) {
        this.user_config.otto.user_info_account_disable.lastname = false;
      }

      if (isNil(this.user_config.otto.user_info_account_disable.postal_code)) {
        this.user_config.otto.user_info_account_disable.postal_code = false;
      }

      if (isNil(this.user_config.otto.content_authorization)) {
        this.user_config.otto.content_authorization = {
          enabled: false,
          metadata_detail_id: null,
          modal_title: null,
          modal_button: null,
          modal_link_account: null,
          modal_locked_title: null,
          modal_locked_description: null,
          modal_locked_link_content: null,
          modal_locked_link_account: null,
          account_title: null,
          account_description: null,
        };
      }
    }
  }

  setPictures() {
    if (isNil(this.content_config.picture)) {
      this.content_config.picture = {};
    }

    //available_types
    if (isNil(this.content_config.picture.available_types)) {
      this.content_config.picture.available_types = cloneDeep(
        this.pictureTypeList
      );
    }

    if (isNil(this.content_config.picture.available_types_by_model)) {
      this.content_config.picture.available_types_by_model = {};
    }
    // init default values for available_types_by_model
    for (const property in this.pictureModelList) {
      let list =
        this.content_config.picture.available_types_by_model[property] ?? [];
      this.pictureModelList[property].forEach((pictureType) => {
        if (list.includes(pictureType) === false) {
          list.push(pictureType);
        }
      });
      this.content_config.picture.available_types_by_model[property] = list;
    }

    let retval = {};
    for (var nb = 0; nb < this.pictureTypeList.length; nb++) {
      let type = this.pictureTypeList[nb];
      if (
        !isNil(this.picture[type]) &&
        !isNil(this.picture[type][0]) &&
        !isNil(this.picture[type][0].url)
      ) {
        retval[type] = this.picture[type][0];
      } else {
        retval[type] = { url: '' };
      }
    }
    this.picture = retval;

    // logos size
    if (isNil(this.content_config.logo)) {
      this.content_config.logo = {};
    }

    if (isNil(this.content_config.header_size)) {
      this.content_config.logo.header_size = { height: '40' };
    }

    if (isNil(this.content_config.footer_size)) {
      this.content_config.logo.footer_size = { height: '60' };
    }

    if (isNil(this.content_config.picture.radius)) {
      this.content_config.picture.radius = 0;
    }
  }

  getDefaultColors(
    list = [
      'white',
      'black',
      'custom',
      'primary',
      'secondary',
      'tertiary',
      'custom_hex',
    ]
  ) {
    let returnArray = [];

    if (list.includes('white')) {
      returnArray.push({
        id: 'white',
        title: 'Blanc',
        hex: colorsHexaCode.light,
      });
    }

    if (list.includes('black')) {
      returnArray.push({
        id: 'black',
        title: 'Noir',
        hex: colorsHexaCode.dark,
      });
    }

    if (list.includes('custom')) {
      const layoutBackgroundId = this.content_config.feature
        ?.custom_background_color
        ? this.content_config.colors.layout?.default?.background?.id
        : this.content_config.colors.layout?.['layouts.default'];

      let hex = '#F99895';

      switch (layoutBackgroundId) {
        case 'light':
          hex = colorsHexaCode.light;
          break;
        case 'dark':
          hex = colorsHexaCode.dark;
          break;
        case 'custom':
          hex =
            this.content_config.colors.layout?.default?.background?.hex ??
            '#F99895';
          break;
        default:
          hex = '#F99895';
      }
      returnArray.push({
        id: 'custom',
        title: 'Fond de site',
        hex,
      });
    }

    if (typeof this.content_config !== 'object') {
      return returnArray;
    }

    if (
      typeof this.content_config === 'object' &&
      'colors' in this.content_config
    ) {
      if (
        list.includes('primary') &&
        'primary' in this.content_config.colors.default
      ) {
        returnArray.push({
          id: 'primary',
          title: 'platform.color.options.primary',
          hex: this.content_config.colors.default.primary,
        });
      }
      if (
        list.includes('secondary') &&
        'secondary' in this.content_config.colors.default
      ) {
        returnArray.push({
          id: 'secondary',
          title: 'platform.color.options.secondary',
          hex: this.content_config.colors.default.secondary,
        });
      }
      if (
        list.includes('tertiary') &&
        'tertiary' in this.content_config.colors.default
      ) {
        returnArray.push({
          id: 'tertiary',
          title: 'platform.color.options.tertiary',
          hex: this.content_config.colors.default.tertiary,
        });
      }
      if (list.includes('custom_hex')) {
        returnArray.push({
          id: 'custom_hex',
          title: 'platform.color.options.custom',
          hex: '',
        });
      }
    }

    return returnArray;
  }

  /**
   * Couleurs utilisées pour les row animation
   */
  getDefaultBlockBackgroundColors() {
    let configColor = undefined;

    if (
      this.content_config?.feature?.custom_background_color &&
      this.content_config.colors.layout?.default?.background?.id === 'custom'
    ) {
      configColor = this.content_config.colors.layout.default.background.id;
    } else {
      configColor =
        this.content_config.colors.layout?.['layouts.default'] === 'dark'
          ? 'black'
          : 'white';
    }
    let backgroundColors = this.getDefaultColors([
      configColor,
      'primary',
      'secondary',
      'tertiary',
      'custom_hex',
    ]).map((element) => {
      if (element.id === configColor) {
        element.title = 'platform.color.options.theme';
      }
      return element;
    });
    return backgroundColors;
  }

  setCatalog() {
    var catalog = null;
    if (
      !(
        isNil(this.content_config.catalog) ||
        Array.isArray(this.content_config.catalog)
      )
    ) {
      catalog = cloneDeep(this.content_config.catalog);
    } else {
      catalog = {};
    }
    catalog.title = typeof catalog.title !== 'string' ? '' : catalog.title;
    catalog.description =
      typeof catalog.description !== 'string' ? '' : catalog.description;
    catalog.backdrop_url =
      typeof catalog.backdrop_url !== 'string' ? null : catalog.backdrop_url;
    catalog.template =
      typeof catalog.template !== 'string' ? 'wall-poster' : catalog.template;
    catalog.display_metadata_title =
      typeof catalog.display_metadata_title !== 'boolean'
        ? true
        : catalog.display_metadata_title;
    catalog.display_count =
      typeof catalog.display_count !== 'boolean' ? true : catalog.display_count;
    catalog.available_filters = !Array.isArray(catalog.available_filters)
      ? []
      : catalog.available_filters;
    catalog.dynamic_banner =
      typeof catalog.dynamic_banner !== 'boolean'
        ? true
        : catalog.dynamic_banner;
    catalog.metadata_row =
      typeof catalog.metadata_row !== 'number' ? null : catalog.metadata_row;
    catalog.default_ranking =
      typeof catalog.default_ranking !== 'string'
        ? 'date:asc'
        : catalog.default_ranking;
    catalog.ranking_metadata = !Array.isArray(catalog.ranking_metadata)
      ? []
      : catalog.ranking_metadata;

    this.content_config.catalog = catalog;
  }

  setRentals() {
    if (!this.content_config.rentals || isNil(this.content_config.rentals)) {
      this.content_config.rentals = [];
    }
  }

  setPricingType() {
    if (isNil(this.content_config.pricing_type)) {
      this.content_config.pricing_type = 'svod';
    }
  }

  setGeoblocking() {
    if (isNil(this.content_config.geoblocking)) {
      this.content_config.geoblocking = {
        type: 'all',
        whitelist: [],
        blacklist: [],
      };
    }
  }

  setDefaultColors() {
    let colorList = [
      'primary',
      'secondary',
      'tertiary',
      'primary-hover',
      'secondary-hover',
      'free',
      'color-by-secondary-bg',
      'playback',
    ];
    let deprecatedList = ['secondary-hove', 'test'];
    let layoutList = [
      'layouts.default',
      'layouts.login',
      'layouts.subscribe',
      'layouts.content',
    ];
    let dropdownList = ['fg', 'bg'];

    if (
      isNil(this.content_config.colors) ||
      isEmpty(this.content_config.colors)
    ) {
      this.content_config.colors = {};
    }

    if (isNil(this.content_config.colors.default)) {
      this.content_config.colors.default = {};
    }

    if (isNil(this.content_config.colors.layout)) {
      this.content_config.colors.layout = {};
    }

    if (isNil(this.content_config.colors.dropdown)) {
      this.content_config.colors.dropdown = {};
    }

    layoutList.forEach((element) => {
      // Set element
      if (isNil(this.content_config.colors.layout[element])) {
        this.content_config.colors.layout[element] = 'light';
      }
    });

    colorList.forEach((element) => {
      // Set element
      if (isNil(this.content_config.colors.default[element])) {
        this.content_config.colors.default[element] = null;
      }
    });

    dropdownList.forEach((element) => {
      // Set element
      if (isNil(this.content_config.colors.dropdown[element])) {
        this.content_config.colors.dropdown[element] = null;
      }
    });
  }

  setLayoutColors({ layout, name, value }) {
    if (name === 'dark' || name === 'light') {
      this.content_config.colors.layout[layout] = value;
      if (
        this.content_config.colors.layout[layout.split('.')[1]]?.background?.id
      ) {
        this.content_config.colors.layout[layout.split('.')[1]].background.id =
          value;
      } else {
        this.content_config.colors.layout[layout.split('.')[1]] = {
          background:
            value === 'dark'
              ? { hex: colorsHexaCode.dark, id: 'dark' }
              : { hex: colorsHexaCode.light, id: 'light' },
          text: {
            id: value === 'dark' ? 'light' : 'dark',
          },
        };
      }
    } else if (name === 'custom') {
      this.content_config.colors.layout[layout.split('.')[1]] = {
        background: {
          id: 'custom',
          hex: value.background.hex,
        },
        text: {
          id: value.text.id,
        },
      };
    }
  }

  setLayoutCustomTextColors({ layout, value }) {
    this.content_config.colors.layout[layout.split('.')[1]].text.id = value;
  }

  setDevices() {
    let deviceList = ['ios', 'airplay', 'android', 'chromecast'];

    // check/init devices
    if (
      isNil(this.content_config.devices) ||
      'object' !== typeof this.content_config.devices
    ) {
      this.content_config.devices = {};
    }

    let devices = cloneDeep(this.content_config.devices);

    deviceList.forEach((element) => {
      // Set element
      if (isNil(devices[element]) || 'object' !== typeof devices[element]) {
        devices[element] = {};
      }

      // Set element type
      if (isNil(devices[element].type)) {
        devices[element].type = element;
      }

      // Set element show
      if (isNil(devices[element].show)) {
        devices[element].show = false;
      }

      // Set element rank
      if (isNil(devices[element].rank)) {
        devices[element].rank = 0;
      }

      // Set element url
      if (isNil(devices[element].url)) {
        devices[element].url = '';
      }
    });

    // Remove deprecated values
    devices = pickBy(devices, function (element, key) {
      return deviceList.includes(key);
    });

    this.content_config.devices = devices;
  }

  setSocial() {
    let list = ['fb', 'ig', 'tw', 'wa', 'yt', 'vimeo', 'linkedin', 'tt'];
    let doNotShowList = ['wa'];

    // Set Social
    if (isNil(this.content_config.social)) {
      this.content_config.social = {};
    }

    // Set media
    if (isNil(this.content_config.social.media)) {
      this.content_config.social.media = {};
    }

    // Set Share
    if (isNil(this.content_config.social.share)) {
      this.content_config.social.share = {};
    }

    list.forEach((element) => {
      // Set element in media
      if (isNil(this.content_config.social.media[element])) {
        this.content_config.social.media[element] = {
          url: '',
          show: false,
          rank: 0,
        };
      }

      // Set element in media if was just url before
      if (
        !isNil(this.content_config.social.media[element]) &&
        isString(this.content_config.social.media[element])
      ) {
        this.content_config.social.media[element] = {
          url: this.content_config.social.media[element],
          show: true,
          rank: 0,
        };
      }

      // Set "show" for current element
      if (
        isNil(this.content_config.social.media[element].show) ||
        doNotShowList.includes(element)
      ) {
        this.content_config.social.media[element].show = false;
      }

      // Set "rank" for current element
      if (isNil(this.content_config.social.media[element].rank)) {
        this.content_config.social.media[element].rank = 0;
      }

      // Set "url" for current element
      if (isNil(this.content_config.social.media[element].url)) {
        this.content_config.social.media[element].url = null;
      }
    });
  }

  setThumbs() {
    if (isNil(this.content_config.thumbs)) {
      this.content_config.thumbs = { template: null, duration: null };
    }
  }

  setButtons() {
    if (isNil(this.content_config.buttons)) {
      this.content_config.buttons = {};
    }

    let buttons = cloneDeep(this.content_config.buttons);

    if (isNil(buttons.default) || 'object' !== typeof buttons.default) {
      buttons.default = {};
    }

    if (isNil(buttons.default.radius)) {
      buttons.default.radius = 5;
    }

    this.buttonList.forEach((element) => {
      // Should be an object and not an array
      if (
        isNil(buttons[element.id]) ||
        Array.isArray(buttons[element.id]) ||
        'object' !== typeof buttons[element.id]
      ) {
        buttons[element.id] = {};
      }

      if (
        isNil(buttons[element.id].cta_label) ||
        isEmpty(buttons[element.id].cta_label)
      ) {
        buttons[element.id].cta_label = element.cta_label;
      }
    });

    this.content_config.buttons = buttons;
  }

  setScrollArrow() {
    if (isNil(this.content_config.scroll_arrow)) {
      this.content_config.scroll_arrow = {};
    }

    let scroll_arrow = cloneDeep(this.content_config.scroll_arrow);

    if (
      isNil(scroll_arrow.default) ||
      'object' !== typeof scroll_arrow.default
    ) {
      scroll_arrow.default = {};
    }

    if (isNil(scroll_arrow.default.radius)) {
      scroll_arrow.default.radius = 5;
    }
    if (isNil(scroll_arrow.default.color)) {
      scroll_arrow.default.color = colorsHexaCode.dark;
    }
    if (isNil(scroll_arrow.default.background_color)) {
      scroll_arrow.default.background_color = colorsHexaCode.light;
    }
    if (isNil(scroll_arrow.default.type)) {
      scroll_arrow.default.type = scrollArrowTypes[0].id;
    }

    this.content_config.scroll_arrow = scroll_arrow;
  }

  setFormats() {
    const postersKey = picturesNames.PICTURE_TYPE_POSTERS;
    const thumbnailsKey = picturesNames.PICTURE_TYPE_THUMBNAILS;
    const postersLabel = picturesLabels.PICTURE_TYPE_POSTERS;
    const thumbnailsLabel = picturesLabels.PICTURE_TYPE_THUMBNAILS;
    const list = [postersKey, thumbnailsKey];
    const label = {
      [postersKey]: postersLabel,
      [thumbnailsKey]: thumbnailsLabel,
    };
    const checked = {
      [postersKey]: true,
      [thumbnailsKey]: false,
    };
    const image = {
      [postersKey]: {
        url: new URL(
          '../images/parameters/site/default-format-poster.png',
          import.meta.url
        ).href,
        withDurationUrl: new URL(
          '../images/parameters/site/default-format-poster-duration.png',
          import.meta.url
        ).href,
        width: '102px',
        height: '136px',
        outerHeight: '136px',
      },
      [thumbnailsKey]: {
        url: new URL(
          '../images/parameters/site/default-format-thumbnail.png',
          import.meta.url
        ).href,
        withDurationUrl: new URL(
          '../images/parameters/site/default-format-thumbnail-duration.png',
          import.meta.url
        ).href,
        width: '160px',
        height: '90px',
        outerHeight: '136px',
      },
    };

    if (isNil(this.content_config.formats)) {
      this.content_config.formats = [];
    }

    const formats = [];

    list.forEach((element, index) => {
      let currentElement = {};

      currentElement.id = element;

      currentElement.label = cloneDeep(label[element]);
      currentElement.checked =
        this.content_config.formats?.[index]?.checked ??
        cloneDeep(checked[element]);
      currentElement.nb_lines =
        this.content_config.formats?.[index]?.nb_lines ?? 1;
      currentElement.with_duration =
        this.content_config.formats?.[index]?.with_duration ?? false;
      currentElement.metadatas =
        this.content_config.formats?.[index]?.metadatas ?? [];
      currentElement.image = cloneDeep(image[element]);
      formats.push(currentElement);
    });
    this.content_config.formats = formats;
  }

  setSelection() {
    var selection = null;
    if (isNil(this.content_config.selection)) {
      selection = {};
    } else {
      selection = cloneDeep(this.content_config.selection);
    }
    selection.display_title =
      typeof selection.display_title !== 'boolean'
        ? true
        : selection.display_title;
    selection.display_count =
      typeof selection.display_count !== 'boolean'
        ? true
        : selection.display_count;
    selection.template =
      typeof selection.template !== 'string'
        ? 'big_banner'
        : selection.template;

    this.content_config.selection = selection;
  }

  setAvod() {
    this.content_config.avod = this.content_config?.avod ?? {};
    this.content_config.avod.config = this.content_config?.avod?.config ?? {};
    this.content_config.avod.config.name =
      this.content_config?.avod?.config?.name ?? 'smartadserver';
    this.content_config.avod.config.domain =
      this.content_config?.avod?.config?.domain ?? null;
    this.content_config.avod.config.enabled =
      this.content_config?.avod?.config?.enabled ?? false;
    this.content_config.avod.config.site_id =
      this.content_config?.avod?.config?.site_id ?? null;
    this.content_config.avod.config.network_id =
      this.content_config?.avod?.config?.network_id ?? null;
    this.content_config.avod.ads = this.content_config?.avod?.ads ?? {};
    this.content_config.avod.ads.pages =
      this.content_config?.avod?.ads?.pages ?? {};
    this.content_config.avod.ads.videos =
      this.content_config?.avod?.ads?.videos ?? {};

    ['main', 'trailer', 'bonus'].forEach((type) => {
      this.content_config.avod.ads.videos[type] =
        this.content_config?.avod?.ads?.videos?.[type] ?? {};
      this.content_config.avod.ads.videos[type].mabd =
        this.content_config?.avod?.ads?.videos?.[type]?.mabd ?? 600;
      this.content_config.avod.ads.videos[type].target =
        this.content_config?.avod?.ads?.videos?.[type]?.target ?? 'none';
      this.content_config.avod.ads.videos[type].page_id =
        this.content_config?.avod?.ads?.videos?.[type]?.page_id ?? null;
      this.content_config.avod.ads.videos[type].format_id =
        this.content_config?.avod?.ads?.videos?.[type]?.format_id ?? null;
    });

    [
      'all',
      'selection',
      'serie',
      'program',
      'home',
      'landing',
      'live',
      'page',
      'catalog',
      'all_metadata',
      'one_metadata',
      'one_selection',
      'account',
      'legals',
      'collection',
    ].forEach((type) => {
      this.content_config.avod.ads.pages[type] =
        this.content_config?.avod?.ads?.pages?.[type] ?? {};
      this.content_config.avod.ads.pages[type].page_id =
        this.content_config?.avod?.ads?.pages?.[type]?.page_id ?? null;
      this.content_config.avod.ads.pages[type].formats =
        this.content_config?.avod?.ads?.pages?.[type]?.formats ?? {};
      [
        'megaban_footer',
        'megaban_header',
        'interstitial',
        'banners',
        'paves',
      ].forEach((subType) => {
        this.content_config.avod.ads.pages[type].formats[subType] =
          this.content_config?.avod?.ads?.pages?.[type]?.formats?.[subType] ??
          {};
        this.content_config.avod.ads.pages[type].formats[subType].format_id =
          this.content_config?.avod?.ads?.pages?.[type]?.formats?.[subType]
            ?.format_id ?? null;
        this.content_config.avod.ads.pages[type].formats[subType].target =
          this.content_config?.avod?.ads?.pages?.[type]?.formats?.[subType]
            ?.target ?? 'none';
        this.content_config.avod.ads.pages[type].formats[subType].enabled =
          this.content_config?.avod?.ads?.pages?.[type]?.formats?.[subType]
            ?.enabled ?? false;
      });
    });
  }

  removeDeprecated() {
    delete this.content_config.picture_default;
    delete this.content_config.feature.picture_xl_with_txt;
    delete this.content_config.feature.recommendation_block;
    delete this.content_config.feature.didomi_remove_tracking;

    for (const [key, value] of Object.entries(this.content_config.animation)) {
      delete this.content_config.animation[key].image;
    }

    delete this.content_config.program;
    delete this.content_config.serie;
    delete this.content_config.default_color;
  }

  showAvatar() {
    return true;
  }

  getCatalogUrl() {
    if (!isNil(this.front_url)) {
      return this.front_url + '/catalogue';
    }
    return "l'url du site n'est pas défini (probléme de configuration).";
  }

  /**
   * Set hex hover color from color type
   *
   * @param {string} $type type color : primary, secondary
   * @returns void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  setHoverColor($type) {
    const $color = this.content_config.colors.default[$type];
    if ($color && $color.length >= 7 && $color.substring(0, 1) === '#') {
      const $hexadecimal = $color.substring(7) ? $color.substring(7) : 'FF';
      this.content_config.colors.default[$type + '-hover'] =
        $color.substring(0, 7).toUpperCase() +
        Math.round(parseInt($hexadecimal, 16) * 0.8)
          .toString(16)
          .toUpperCase();
    }
  }

  /**
   * Set pictures to optionnal or mandatory
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   * @returns void
   */
  setPicturesOptionalMandatory() {
    // has format selected ? no, do nothing
    const formatMandatory = this.content_config.formats.find(
      (element) => element.checked === true
    ).id;
    const formatOptional = this.content_config.formats.find(
      (element) => element.checked === false
    ).id;
    if (!formatMandatory && !formatOptional) {
      return false;
    }

    // Program Pictures
    if (isNil(this.content_config.picture.mandatory)) {
      this.content_config.picture.mandatory = [];
    }

    if (isNil(this.content_config.picture.optional)) {
      this.content_config.picture.optional = [];
    }

    // Selection Pictures
    if (isNil(this.content_config.picture.selection)) {
      this.content_config.picture.selection = [];
    }

    if (isNil(this.content_config.picture.selection.mandatory)) {
      this.content_config.picture.selection.mandatory = [];
    }

    if (isNil(this.content_config.picture.selection.optional)) {
      this.content_config.picture.selection.optional = [];
    }

    // Work on clone data
    let optional = cloneDeep(this.content_config.picture.optional);
    let mandatory = cloneDeep(this.content_config.picture.mandatory);

    // Work on clone data
    let selectionOptional = cloneDeep(
      this.content_config.picture.selection.optional
    );
    let selectionMandatory = cloneDeep(
      this.content_config.picture.selection.mandatory
    );

    // Ger selected format
    this.content_config.picture.default = formatMandatory;

    // Remove Posters/Thumbnails from optionnal/madantory
    optional = optional.filter(function (value) {
      return !picturesCatalogFormats.includes(value);
    });
    mandatory = mandatory.filter(function (value) {
      return !picturesCatalogFormats.includes(value);
    });

    selectionOptional = selectionOptional.filter(function (value) {
      return !picturesCatalogFormats.includes(value);
    });
    selectionMandatory = selectionMandatory.filter(function (value) {
      return !picturesCatalogFormats.includes(value);
    });

    // Add good value for optionnal and default values
    optional = [
      picturesCatalogFormats.find((element) => element !== formatMandatory),
      ...picturesDefaultOptional,
    ];
    selectionOptional = [
      picturesCatalogFormats.find((element) => element !== formatMandatory),
      ...picturesSelectionOptional,
    ];
    // Add good value for mandatory and default values
    mandatory = [formatMandatory, ...picturesDefaultMandatory];
    selectionMandatory = picturesSelectionMandatory;

    // Remove double value
    optional = optional.filter(
      (currentElement, index, list) => list.indexOf(currentElement) === index
    );
    mandatory = mandatory.filter(
      (currentElement, index, list) => list.indexOf(currentElement) === index
    );

    selectionOptional = selectionOptional.filter(
      (currentElement, index, list) => list.indexOf(currentElement) === index
    );
    selectionMandatory = selectionMandatory.filter(
      (currentElement, index, list) => list.indexOf(currentElement) === index
    );

    this.content_config.picture.optional = optional;
    this.content_config.picture.mandatory = mandatory;
    this.content_config.picture.selection.optional = selectionOptional;
    this.content_config.picture.selection.mandatory = selectionMandatory;
  }

  /**
   * Set video
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   * @returns void
   */
  setVideo() {
    if (isNil(this.content_config.video)) {
      this.content_config.video = {};
    }

    if (Array.isArray(this.content_config.video)) {
      this.content_config.video = Object.assign(
        {},
        ...this.content_config.video
      );
    }

    // if (isNil(this.content_config.video.cdn)) {
    //   this.content_config.video.cdn = "https://otto-stream.cdn.vodfactory.com/"
    // }

    if (isNil(this.content_config.video.limitations)) {
      this.content_config.video.limitations = {};
    }

    if (isNil(this.content_config.video.limitations.max_width)) {
      this.content_config.video.limitations.max_width = 1920;
    }

    if (isNil(this.content_config.video.limitations.max_height)) {
      this.content_config.video.limitations.max_height = 1080;
    }

    if (isNil(this.content_config.video.limitations.max_bitrate)) {
      this.content_config.video.limitations.max_bitrate = 10000;
    }

    if (isNil(this.content_config.video.main_language)) {
      this.content_config.video.main_language = ['fr', 'fra', 'fre'];
    }

    if (isNil(this.content_config.video.video_completion)) {
      this.content_config.video.video_completion = 95;
    }

    if (isNil(this.content_config.video.chromecast_app_id)) {
      this.content_config.video.chromecast_app_id = null;
    }

    if (isNil(this.content_config.video.drm_licence_lifespan)) {
      this.content_config.video.drm_licence_lifespan = 10800;
    }

    if (isNil(this.content_config.video.simulaneous_playbacks_allowed)) {
      this.content_config.video.simulaneous_playbacks_allowed = 99;
    }

    if (isNil(this.content_config.video.seconds_before_end)) {
      this.content_config.video.seconds_before_end = 0;
    }
  }

  /**
   * Set feature
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   * @returns void
   */
  setFeature() {
    let list = {
      attachment: false,
      custom_background_color: false, // deprecated
      bitmovin_analytics: false,
      block_faq: false,
      block_form: false,
      block_giftcard: false,
      block_newsletter: false,
      collection: false,
      didomi_consent: false,
      didomi_remove_tracking: false,
      display_link_subscribe_later: true,
      force_landing: true,
      freebox_videos: false,
      fuzzy_search: false,
      geoblocking: false,
      hide_front_link: false,
      live: false,
      missing_page_redirector: false,
      multi_profile_light: false,
      pagination: false,
      payment_sepa: false,
      payment_reuse_card: false,
      person_v2: false,
      piwik_pro_tracking: false,
      playback: false,
      playback_block: false,
      playback_history: false,
      playlist_hide_offline_programs: false,
      rating: false,
      recommendation_animation: false,
      recommandation_collection_specific: false,
      recommendation_player: false,
      recommendation_program: false,
      rich_text_editor: false,
      skip_intro_outro: false,
      tracking: true,
      upload_image_on_save: false,
      upload_to_s3: true,
      upsell: false,
      vwo_smartcode_tracking: false,
      weglot: false,
      player_fullscreen: false,
      avod: false,
      user_preferences_audio: true,
      user_preferences_subtitles: true,
      bo_zendesk: false,
      dynamic_filters: false,
      bo_jimo: false,
      export_videos: false,
      display_next_season_episode: false,
      display_breadcrumb: false,
      environment_amazon: false,
      environment_molotov: false,
      environment_betv: false,
      environment_free: false,
      import_users: false,
      apply_prepaid_code_users: false,
      export_users: false,
      import_gar_esidoc: false,
    };

    if (!this.content_config?.amazon_territories) {
      this.content_config.amazon_territories = [];
    }

    if (
      isNil(this.content_config.feature) ||
      isEmpty(this.content_config.feature)
    ) {
      this.content_config.feature = {};
    }

    for (const [key, value] of Object.entries(list)) {
      if (isNil(this.content_config.feature[key])) {
        this.content_config.feature[key] = value;
      }
    }

    if (isNil(this.content_config.maintenance_mode)) {
      this.content_config.maintenance_mode = false;
    }

    if (isNil(this.content_config.bo_maintenance_mode)) {
      this.content_config.bo_maintenance_mode = false;
    }

    if (isNil(this.content_config.all_bo_maintenance_mode)) {
      this.content_config.all_bo_maintenance_mode = false;
    }

    if (isNil(this.content_config.navigation)) {
      this.content_config.navigation = {};
    }

    if (isNil(this.content_config.navigation.focus)) {
      this.content_config.navigation.focus = null;
    }
  }

  /**
   * Set default blocks
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   * @returns void
   */
  setBlock() {
    const blockList = ['header', 'footer'];
    const list = ['default', 'landing'];

    if (
      isNil(this.content_config.blocks) ||
      isEmpty(this.content_config.blocks)
    ) {
      this.content_config.blocks = {};
    }

    list.forEach((element) => {
      if (
        isNil(this.content_config.blocks[element]) ||
        isEmpty(this.content_config.blocks[element])
      ) {
        this.content_config.blocks[element] = {};
      }

      blockList.forEach((block) => {
        if (
          isNil(this.content_config.blocks[element][block]) ||
          isEmpty(this.content_config.blocks[element][block])
        ) {
          this.content_config.blocks[element][block] = 0;
        }
      });
    });
  }

  /**
   * Set Purchasely
   *
   * @returns void
   */
  setPurchasely() {
    if (
      isNil(this.billing_config.purchasely) ||
      isEmpty(this.billing_config.purchasely)
    ) {
      this.billing_config.purchasely = {};
    }
    if (isNil(this.billing_config.purchasely.api_key)) {
      this.billing_config.purchasely.api_key = null;
    }
    if (isNil(this.billing_config.purchasely.enabled)) {
      this.billing_config.purchasely.enabled = false;
    }
    if (isNil(this.billing_config.purchasely.client_webhook_shared_secret)) {
      this.billing_config.purchasely.client_webhook_shared_secret = null;
    }
  }

  /**
   * Set Tracking Config
   *
   * @returns void
   */
  setTrackingConfig() {
    if (isNil(this.tracking_config) || isEmpty(this.tracking_config)) {
      this.tracking_config = {};
    }
    if (isNil(this.tracking_config.gta_id)) {
      this.tracking_config.gta_id = null;
    }
    if (isNil(this.tracking_config.gtm_id)) {
      this.tracking_config.gtm_id = null;
    }
    if (isNil(this.tracking_config.facebook_id)) {
      this.tracking_config.facebook_id = null;
    }
    if (isNil(this.tracking_config.facebook_pixel_id)) {
      this.tracking_config.facebook_pixel_id = null;
    }
    if (isNil(this.tracking_config.facebook_conversion_api_token)) {
      this.tracking_config.facebook_conversion_api_token = null;
    }
    if (isNil(this.tracking_config.app_ads)) {
      this.tracking_config.app_ads = [];
    }
  }

  /**
   * Set Didomi
   *
   * @returns void
   */
  setDidomi() {
    if (
      isNil(this.tracking_config.didomi) ||
      isEmpty(this.tracking_config.didomi)
    ) {
      this.tracking_config.didomi = {};
    }
    if (isNil(this.tracking_config.didomi.key)) {
      this.tracking_config.didomi.key = null;
    }
    if (isNil(this.tracking_config.didomi.remove_tracking)) {
      this.tracking_config.didomi.remove_tracking = false;
    }
    if (isNil(this.tracking_config.didomi.group_enabled)) {
      this.tracking_config.didomi.group_enabled = false;
    }
    if (isNil(this.tracking_config.didomi.group_custom_domain)) {
      this.tracking_config.didomi.group_custom_domain = null;
    }
  }

  /**
   * Set Attachment
   *
   * @returns void
   */
  setAttachment() {
    if (
      isNil(this.content_config.attachment) ||
      isEmpty(this.content_config.attachment)
    ) {
      this.content_config.attachment = {};
    }
    if (isNil(this.content_config.attachment.enabled)) {
      this.content_config.attachment.enabled = false;
    }
    if (isNil(this.content_config.attachment.formats)) {
      this.content_config.attachment.formats =
        attachmentFilesDefaultMimeTypes.join(',');
    }
    if (isNil(this.content_config.attachment.max_size)) {
      this.content_config.attachment.max_size = 5;
    }
    if (isNil(this.content_config.attachment.max_files)) {
      this.content_config.attachment.max_files = 1;
    }
    if (isNil(this.content_config.attachment.label)) {
      this.content_config.attachment.label = 'generic.download.file';
    }
    if (isNil(this.content_config.attachment.restriction_type)) {
      this.content_config.attachment.restriction_type = null;
    }
  }

  setSearchConfig() {
    if (isNil(this.search_config) || isEmpty(this.search_config)) {
      this.search_config = {};
    }
    let list = [
      'tag',
      'gear',
      'user',
      'level',
      'custom',
      'person',
      'country',
      'program',
      'version',
      'category',
      'language',
      'selection',
      'persontype',
      'ratinglvel',
    ];
    list.forEach((label) => {
      if (
        isNil(this.search_config[label]) ||
        isEmpty(this.search_config[label])
      ) {
        this.search_config[label] = {};
      }

      if (isNaN(this.search_config[label].count_per_page)) {
        this.search_config[label].count_per_page = 10;
      }

      if (
        isNil(this.search_config[label].fields) ||
        isEmpty(this.search_config[label].fields)
      ) {
        this.search_config[label].fields = {};
      }

      let entries = Object.entries(this.search_config[label].fields);
      for (const [key, value] of entries) {
        if (isNaN(value) || value < 1) {
          this.search_config[label].fields[key] = 1;
        } else if (value > 5) {
          this.search_config[label].fields[key] = 5;
        } else if (value % 1 !== 0) {
          this.search_config[label].fields[key] = Math.round(value);
        }
      }
    });
  }

  /**
   * Set Pagination
   *
   * @returns void
   */
  setPagination() {
    if (isNil(this.content_config?.pagination)) {
      this.content_config.pagination = {};
    }
    const pagination = paginationConfigs;
    for (const [key, value] of Object.entries(pagination)) {
      if (isNil(this.content_config?.pagination[key])) {
        this.content_config.pagination[key] = value;
      }
    }
  }

  getCountryCode() {
    return this.country_default;
  }

  getCountryId() {
    return this.country_default_id;
  }

  //@todo
  getCurrencyCode() {
    return this.billing_config.default_currency || 'EUR';
  }
}

export function createPlatform(data) {
  return Object.freeze(new Platform(data));
}

export default Platform;
