<template>
  <ul :class="formRadiosWrapperClass">
    <li
      v-for="(option, i) in field.options"
      :key="i"
      :class="formRadiosOptionsClass"
    >
      <FormRadio
        :id="option.id"
        :model-value="option.id"
        :label="option.label"
        :icon="option.icon"
        :size="optionSize"
        :illustration="option.illustration"
        :image="option.image"
        :hint="option.sublabel"
        :hint-display="option.sublabelDisplay"
        :disabled="option.disabled || disabled"
        :type="optionType"
        :checked="option.id === checkedOption"
        :icon-position="setIconPosition(option)"
        :display="option.display || optionDisplay"
        :tooltip="option.tooltip"
        :label-as-tooltip="field.labelAsTooltip"
        :label-style="option.style"
        :align-center="alignCenter"
        :option="option"
        :sub-options="option.subOptions"
        :sub-option="option.subOption"
        class="w-full"
        @update:model-value="selectOption($event)"
      >
        <template v-if="$slots['option-header']" #header="opts">
          <slot name="option-header" v-bind="opts" />
        </template>
        <template v-if="$slots['option-footer']" #footer="opts">
          <slot name="option-footer" v-bind="opts" />
        </template>
      </FormRadio>
    </li>
  </ul>
</template>

<script>
import {
  alignItemsClasses,
  gridTemplateColumnsClasses,
  justifyContentClasses,
  optionsSpacerClasses,
  wrapClasses,
} from '@/utils/styles/styles.js';
import FormRadio from '@/components/form/FormRadio.vue';

export default {
  name: 'FormRadios',
  components: {
    FormRadio,
  },
  props: {
    field: Object,
    modelValue: String,
    display: {
      type: String,
      default: 'row',
    },
    size: String,
    weight: String,
    iconPosition: String,
    verticalAlign: String,
    horizontalAlign: String,
    spacer: {
      type: String,
      default: 'md',
    },
    optionDisplay: {
      type: String,
      default: 'row',
    },
    optionWrap: String,
    optionType: String,
    optionSize: String,
    grid: Number,
    wrap: {
      type: Boolean,
      default: true,
    },
    alignCenter: {
      type: Boolean,
      default: true,
    },
    autoColons: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value', 'updateSubOption'],
  computed: {
    checkedOption: {
      get() {
        return this.modelValue;
      },
      set(checkedOption) {
        this.$emit('update:model-value', checkedOption);
      },
    },
    formRadiosClass() {
      return [this.disabled && `disabled`];
    },
    formRadiosWrapperClass() {
      return [
        this.grid ? 'grid gap-4' : 'flex',
        {
          'flex-col': this.display === 'column',
          'flex-row': this.display === 'row',
          'content-between': this.horizontalAlign === 'justify-between',
        },
        this.horizontalAlign &&
          (this.optionDisplay === 'column'
            ? justifyContentClasses[this.horizontalAlign]
            : alignItemsClasses[this.horizontalAlign]),
        this.verticalAlign &&
          (this.optionDisplay === 'row'
            ? justifyContentClasses[this.verticalAlign]
            : alignItemsClasses[this.verticalAlign]),
        this.optionWrap && wrapClasses[this.optionWrap],
        this.grid && gridTemplateColumnsClasses[this.grid],
        optionsSpacerClasses[this.spacer],
      ];
    },
    formRadiosOptionsClass() {
      return [
        'flex',
        this.grid && 'grow',
        this.horizontalAlign &&
          (this.optionDisplay === 'column'
            ? justifyContentClasses[this.horizontalAlign]
            : alignItemsClasses[this.horizontalAlign]),
        this.verticalAlign &&
          (this.optionDisplay === 'row'
            ? justifyContentClasses[this.verticalAlign]
            : alignItemsClasses[this.verticalAlign]),
      ];
    },
  },
  methods: {
    selectOption(option) {
      this.$emit('update:model-value', option);
    },
    setIconPosition(option) {
      return option.iconPosition ?? this.iconPosition;
    },
  },
};
</script>

<style lang="scss">
//@import 'form_radios.scss';
</style>
