<template>
  <picture
    class="picture inline-block isolation-auto isolate"
    :style="placeholderStyle"
    :class="pictureClass"
  >
    <Icon
      v-if="src && isLoading"
      icon="spinner"
      size="sm"
      class="animate-spin absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
    />
    <span v-else-if="!src && initials" class="alt-initials">{{
      altInitials
    }}</span>
    <img
      v-if="src && !isLoading"
      class="image"
      :class="imageClass"
      :width="width"
      :height="height"
      :src="imageUrl"
      :alt="alt"
      :style="imageStyle"
    />
    <slot />
  </picture>
</template>

<script setup>
import { computed } from 'vue';
import { useImage } from '@vueuse/core';
import Icon from '@/components/icon/Icon.vue';

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];
const props = defineProps({
  src: {
    type: [String, null],
    required: true,
  },
  alt: {
    type: String,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  aspectRatio: {
    type: String,
  },
  width: {
    type: Number,
  },
  height: {
    type: Number,
  },
  border: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  initials: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
  },
  withBackground: {
    type: Boolean,
    default: false,
  },
});
const imageUrl = computed(() => props.src);
const { isLoading } = useImage({ src: imageUrl.value });
const pictureClass = computed(() => {
  return {
    'rounded-full aspect-square': props.rounded,
    'picture--avatar': props.initials,
    'border border-solid border-skin-ui': props.border,
    'bg-skin-action-dashed': isLoading.value || props.withBackground,
  };
});
const imageClass = computed(() => {
  return [
    props.padding && `p-${props.padding}`,
    props.fullWidth && 'w-full',
    props.rounded && `rounded-full aspect-square`,
    props.aspectRatio && `object-contain`,
  ];
});

const imageStyle = computed(() => {
  return [
    props.width &&
      props.height &&
      `aspectRatio: ${props.width}/${props.height}`,
    props.aspectRatio && `aspectRatio: ${props.aspectRatio}`,
    props.bgColor && `backgroundColor: ${props.bgColor}`,
  ];
});

const placeholderStyle = computed(() => {
  /*return props.src
    ? {}
    : */ return {
    width: props.fullWidth ? undefined : `${props.width}px`,
    height: props.fullWidth ? undefined : `${props.height}px`,
    aspectRatio: `${props.width}/${props.height}`,
    backgroundColor:
      (props.initials && colors[(altInitials.value.charCodeAt(0) - 65) % 19]) ||
      '',
  };
});
const altInitials = computed(() => {
  return (
    props.initials &&
    props.alt
      .split(' ')
      .slice(0, 2)
      .map((word) => word.substring(0, 1).toUpperCase())
      .join(' ')
  );
});
</script>

<style lang="scss" scoped>
.picture {
  @extend %with-transition-base;
  color: $white;
  align-items: center;
  justify-content: center;
  .icon.icon-spinner {
    fill: $white;
  }

  .alt-initials {
    font-weight: $font-weight-bold;
    font-size: $text-base;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-align: center;
    letter-spacing: -2px;
    text-indent: -2px;
  }
  &.picture--rounded {
    border-radius: 100%;
    overflow: hidden;
    aspect-ratio: 1;
    .image {
      aspect-ratio: 1;
    }
  }
  &.picture--bordered {
    border: 1px solid $border-color;
  }
  .image {
    object-fit: cover;
    &-placeholder {
    }
  }
}
</style>
