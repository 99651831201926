<template>
  <div
    class="form-switch otto-base-ui-component px-0"
    :class="{ 'opacity-50': disabled }"
  >
    <label
      :for="id"
      class="form-switch-label"
      :class="labelPosition === 'right' && 'flex-row-reverse'"
    >
      <template v-if="labelAsTooltip">
        <Tooltip
          :message="itemLabel"
          :position="labelAsTooltip.position"
          :align="labelAsTooltip.align"
        >
          <span
            class="form-switch-toggler bg-skin-action-muted after:bg-skin-ui after:shadow-sm after:shadow-skin-ui after:border-skin-ui-paper after:border after:border-solid"
            :class="{ checked: checked }"
            @click.stop.prevent="toggle()"
          />
        </Tooltip>
      </template>
      <template v-else>
        <span
          class="form-switch-label-text"
          :class="{ disabled: disabled }"
          @click.stop.prevent="toggle()"
        >
          {{ itemLabel }}
        </span>
        <span
          class="form-switch-toggler bg-skin-action-muted after:bg-skin-ui after:shadow-sm after:shadow-skin-ui after:border-skin-ui-paper after:border after:border-solid"
          :class="{ checked: checked, disabled: disabled }"
          @click.stop.prevent="toggle()"
        />
      </template>
    </label>
    <span v-if="disabled && tooltip" class="form-checkbox-tooltip">
      <Tooltip
        icon="info-circle"
        :message="tooltip.message"
        :position="tooltip.position"
        :image="tooltip.image"
        :align="tooltip.align"
      />
    </span>
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'FormSwitch',
  components: {
    Tooltip,
  },
  props: {
    id: String,
    label: {
      type: String,
      default: null,
    },
    labelTrue: String,
    labelFalse: String,
    checked: Boolean,
    labelPosition: {
      type: String,
      default: 'left',
    },
    labelAsTooltip: Object,
    disabled: { type: Boolean, default: false },
    tooltip: Object,
  },
  emits: ['update:model-value'],
  computed: {
    itemLabel() {
      return this.label
        ? this.label
        : this.checked
        ? this.labelTrue
        : this.labelFalse;
    },
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('update:model-value', !this.checked);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'form_switch.scss';
</style>
