<template>
  <div class="fixed right-8 bottom-8 w-[270px] z-[1000] translate-x">
    <transition-group
      tag="div"
      enter-from-class="opacity-0 -translate-x-full"
      enter-active-class="transition transform ease-out duration-300"
      enter-to-class="opacity-100 translate-x-0"
      leave-from-class="opacity-100 translate-x-0"
      leave-active-class="transition transform ease-in duration-300"
      leave-to-class="opacity-0 -translate-x-full"
      class="flex flex-col gap-3"
    >
      <div
        v-for="alert in alerts"
        :key="alert.id"
        class="flex items-center p-4 bg-skin-action-accent text-white gap-3 rounded-ui-component shadow shadow-skin-ui"
        :class="[
          alert.type === 'valid' && 'theme-success',
          alert.type === 'error' && 'theme-danger',
          alert.type === 'warning' && 'theme-warning',
        ]"
      >
        <Icon v-if="alert.icon" :icon="alert.icon" size="md" />
        <p class="text-sm">
          {{ $t(alert.message) }}
        </p>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue';

export default {
  name: 'Alerts',
  components: {
    Icon,
  },
  data() {
    return {
      alerts: this.$store.state.alerts,
    };
  },
};
</script>

<style lang="scss">
.slide-in-left-enter-active {
  animation: slide-in-left-in 0.5s;
}
.slide-in-left-leave-active {
  animation: slide-in-left-in 0.5s reverse;
}

@keyframes slide-in-left-in {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
