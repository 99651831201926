<template>
  <div
    class="flex flex-col border-solid border border-skin-action-muted rounded-sm bg-skin-action-background hover:border-skin-action-hover hover:focus:border-skin-action-accent focus:border-skin-action-accent focus-within:border-skin-action-accent hover:focus-within:border-skin-action-accent focus-visible:border-skin-action-accent hover:focus-visible:border-skin-action-accent group-focus:border-skin-action-accent"
  >
    <div
      class="flex gap-8 gap-y-2 border-solid border-b border-skin-action-muted divide divide-skin-ui p-1 flex-wrap"
    >
      <div
        v-if="!isEditingLink"
        class="flex flex-1 justify-between gap-y-2 flex-wrap"
      >
        <div class="flex gap-2">
          <BaseButton square nude size="sm" hoverable @click="toggleBold">
            <div
              key="bold"
              :class="
                isSelectionBold === true ? 'fill-skin-accent' : 'fill-skin-base'
              "
              v-html="icons.bold"
            />
          </BaseButton>
          <BaseButton square nude size="sm" hoverable @click="toggleItalic">
            <div
              :class="
                isSelectionItalic === true
                  ? 'fill-skin-accent'
                  : 'fill-skin-base'
              "
              v-html="icons.italic"
            />
          </BaseButton>
          <BaseButton square nude size="sm" hoverable @click="toggleStriked">
            <div
              :class="
                isSelectionStriked === true
                  ? 'fill-skin-accent'
                  : 'fill-skin-base'
              "
              v-html="icons.striked"
            />
          </BaseButton>
        </div>
        <div class="flex gap-2">
          <BaseButton square nude size="sm" hoverable @click="toggleDotList">
            <div
              :class="
                isSelectionUnorderedList === true
                  ? 'fill-skin-accent'
                  : 'fill-skin-base'
              "
              v-html="icons.dotList"
            />
          </BaseButton>
          <BaseButton square nude size="sm" hoverable @click="toggleNumberList">
            <div
              :class="
                isSelectionOrderedList === true
                  ? 'fill-skin-accent'
                  : 'fill-skin-base'
              "
              v-html="icons.numberList"
            />
          </BaseButton>
        </div>
        <div class="flex gap-2">
          <BaseButton square nude size="sm" hoverable @click="toggleIndent">
            <div class="fill-skin-base" v-html="icons.indent" />
          </BaseButton>
          <BaseButton square nude size="sm" hoverable @click="toggleIndentBack">
            <div class="fill-skin-base" v-html="icons.indentBack" />
          </BaseButton>
        </div>
        <div class="flex gap-2">
          <BaseButton
            square
            nude
            size="sm"
            hoverable
            @click="toggleJustifyLeft"
          >
            <div
              :class="
                isSelectionLeft === true ? 'fill-skin-accent' : 'fill-skin-base'
              "
              v-html="icons.justifyLeft"
            />
          </BaseButton>
          <BaseButton
            square
            nude
            size="sm"
            hoverable
            @click="toggleJustifyCenter"
          >
            <div
              :class="
                isSelectionCenter === true
                  ? 'fill-skin-accent'
                  : 'fill-skin-base'
              "
              v-html="icons.justifyCenter"
            />
          </BaseButton>
          <BaseButton
            square
            nude
            size="sm"
            hoverable
            @click="toggleJustifyRight"
          >
            <div
              :class="
                isSelectionRight === true
                  ? 'fill-skin-accent'
                  : 'fill-skin-base'
              "
              v-html="icons.justifyRight"
            />
          </BaseButton>
        </div>
        <div class="flex items-center gap-2">
          <BaseButton
            square
            nude
            size="sm"
            hoverable
            :class="
              isLinkCreationAllowed
                ? 'opacity-100 cursor-pointer'
                : 'opacity-25 cursor-not-allowed'
            "
            @click="toggleLink"
          >
            <div
              :class="
                isSelectionLink === true ? 'fill-skin-accent' : 'fill-skin-base'
              "
              v-html="icons.link"
            />
          </BaseButton>
          <Dropdown
            class="user-nav-dropdown"
            nude
            size="sm"
            :disabled="false && !isEmojiCreationAllowed"
            placement="auto"
            :auto-size="false"
            dropdown-icon=""
            @click="handleAddEmoji"
          >
            <template #title>
              <div
                class="fill-skin-base"
                :class="
                  isEmojiCreationAllowed
                    ? 'opacity-100 cursor-pointer'
                    : 'opacity-25 cursor-not-allowed pointer-events-none'
                "
                v-html="icons.emote"
              />
            </template>
            <Picker :data="emojiIndex" @select="handlePickEmoji" />
          </Dropdown>
        </div>
      </div>
      <div v-else class="flex gap-4">
        <input
          v-model="currentLinkTitle"
          type="text"
          class="border-solid border px-2 py-1 outline-none rounded select-none"
          placeholder="Titre"
        />
        <input
          v-model="currentLinkUrl"
          type="url"
          class="border-solid border px-2 py-1 outline-none rounded select-none"
          placeholder="www.exemple.com"
        />
        <div class="flex gap-2">
          <BaseButton
            square
            nude
            size="sm"
            hoverable
            @click="validateLinkEditing"
          >
            <div class="fill-green-500" v-html="icons.validate" />
          </BaseButton>
          <BaseButton
            square
            nude
            size="sm"
            hoverable
            @click="cancelLinkEditing"
          >
            <div class="fill-red-500" v-html="icons.cancel" />
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      id="editor"
      ref="content"
      class="p-2 output outline-none min-h-[70px]"
      :class="
        editorValue === 'Rédiger votre texte'
          ? 'text-skin-muted'
          : 'text-skin-base'
      "
      contenteditable="true"
      tabindex="0"
      @input="change"
      @click="checkSelection($event)"
      @focusin="onFocus"
      @focusout="onFocusOut"
      @paste="onPaste"
      v-html="editorValue"
    />
  </div>
</template>
<script>
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast/src';
import data from 'emoji-mart-vue-fast/data/all.json';
let emojiIndex = new EmojiIndex(data);
import BaseButton from '@/components/button/BaseButton.vue';
import Dropdown from '@/components/dropdown/Dropdown.vue';

export default {
  name: 'RichTextEditor',
  components: {
    BaseButton,
    Dropdown,
    Picker,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      uid: undefined,
      editorValue: this.modelValue,
      isSelectionBold: false,
      isSelectionItalic: false,
      isSelectionStriked: false,
      isSelectionOrderedList: false,
      isSelectionUnorderedList: false,
      isSelectionLeft: false,
      isSelectionCenter: false,
      isSelectionRight: false,
      isSelectionLink: false,
      isLinkCreationAllowed: false,
      isEmojiCreationAllowed: false,
      emojiIndex: emojiIndex,
      icons: {
        bold: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"/></svg>',
        italic:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"/></svg>',
        striked:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"/></svg>',
        dotList:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/></svg>',
        numberList:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/></svg>',
        indent:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 15h18v2H3v-2zm8-5h10v2H11v-2zm0-5h10v2H11V9zm-4 3.5L3 16V9l4 3.5z"/></svg>',
        indentBack:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 15h18v2H3v-2zm8-5h10v2H11v-2zm0-5h10v2H11V9zm-8 3.5L7 9v7l-4-3.5z"/></svg>',
        justifyLeft:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z"/></svg>',
        justifyCenter:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm2 15h14v2H5v-2zm-2-5h18v2H3v-2zm2-5h14v2H5V9z"/></svg>',
        justifyRight:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm4 15h14v2H7v-2zm-4-5h18v2H3v-2zm4-5h14v2H7V9z"/></svg>',
        emote:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-4-7h8a4 4 0 1 1-8 0zm0-2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>',
        link: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"/></svg>',
        validate:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/></svg>',
        cancel:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg>',
      },
      isEditingLink: false,
      currentLinkTitle: '',
      currentLinkUrl: '',
      lastCarretPosition: null,
      lastCaretStart: null,
      cursorPlacement: 0,
    };
  },
  watch: {
    modelValue(value) {
      this.editorValue = value ? value : 'Rédiger votre texte';
      if (this.cursorPlacement) {
        this.$nextTick(() => {
          this.restoreCaretPosition();
        })

      }
    },
  },
  mounted() {
    this.uid = Math.floor(Math.random() * 1000000);

    document.execCommand('defaultParagraphSeparator', false, 'div');
    document.execCommand('styleWithCSS', false, true);

    if (!this.modelValue) {
      this.editorValue = 'Rédiger votre texte';
    }
  },
  methods: {
    async storeCaretPosition() {
      this.cursorPlacement = this.getCursorPosition();
    },
    restoreCaretPosition() {
      this.setCursorPosition()
    },
    getCursorPosition() {
      let caretOffset = 0;
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(this.$refs.content);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }

      console.log('get cursor position: ', caretOffset);

      return parseInt(caretOffset);
    },
    setCursorPosition() {
      const contentarea = this.$refs.content;
      const index = this.cursorPlacement;
      const range = document.createRange();
      const sel = window.getSelection();

      range.setStart(contentarea?.childNodes[0], index);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      contentarea.focus();
      console.log('set cursor position at: ', this.cursorPlacement);
    },
    handlePickEmoji(emoji) {
      const node = document.createElement('span');
      node.innerHTML = `${emoji.native}`;
      this.lastCarretPosition.lastCursorPos.insertNode(node);

      this.$emit('update:model-value', this.$refs.content.innerHTML);
      this.checkSelection();
    },
    handleAddEmoji() {
      console.log('add emoji');
      if (!this.isEditingLink) {
        const selection = window.getSelection();
        let ranges = [];
        for (let i = 0; i < selection.rangeCount; i++) {
          ranges[i] = selection.getRangeAt(i);
        }
        if (this.lastCarretPosition) {
          this.lastCarretPosition.lastCursorPos = ranges[0];
        }
      }
    },
    onPaste(event) {
      event.preventDefault();
      const text = event.clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, text);
    },
    onFocus() {
      if (this.editorValue === 'Rédiger votre texte') {
        this.editorValue = '<div style="text-align: left;"><br></div>';
      }
      this.isEmojiCreationAllowed = true;
    },
    onFocusOut() {
      if (
        this.modelValue === '' ||
        this.modelValue === '<div style="text-align: left;"><br></div>' ||
        this.modelValue === '<div style="text-align: left;"></div>' ||
        this.modelValue === '<br>'
      ) {
        this.editorValue = 'Rédiger votre texte';
        this.$emit('update:model-value', '');
      }
      this.isEmojiCreationAllowed = false;
    },
    async change(e) {
      await this.storeCaretPosition();
      await this.$emit('update:model-value', e.target.innerHTML);
      this.checkSelection();
    },
    toggleBold() {
      document.execCommand('bold');
      this.checkSelection();
    },
    toggleItalic() {
      document.execCommand('italic');
      this.checkSelection();
    },
    toggleStriked() {
      document.execCommand('strikeThrough');
      this.checkSelection();
    },
    toggleDotList() {
      document.execCommand('insertUnorderedList');
    },
    toggleNumberList() {
      document.execCommand('insertOrderedList');
    },
    toggleIndent() {
      document.execCommand('indent');
    },
    toggleIndentBack() {
      document.execCommand('outdent');
    },
    toggleJustifyLeft() {
      document.execCommand('justifyLeft');
    },
    toggleJustifyCenter() {
      document.execCommand('justifyCenter');
    },
    toggleJustifyRight() {
      document.execCommand('justifyRight');
    },
    toggleLink() {
      this.isEditingLink = true;
      this.currentLinkTitle = this.lastCarretPosition.selectedText;
      this.currentLinkUrl =
        this.lastCarretPosition.range.commonAncestorContainer.parentNode.href;
    },
    resetLinkEditing() {
      this.currentLinkTitle = '';
      this.currentLinkUrl = '';
    },
    cancelLinkEditing() {
      this.isEditingLink = false;
      this.resetLinkEditing();
    },
    validateLinkEditing() {
      this.isEditingLink = false;

      var link = document.createElement('a');
      link.target = '_blank';
      link.href = `//${this.currentLinkUrl.replace(/^https?:\/\//, '')}`;
      link.textContent = this.lastCarretPosition.selectedText;

      var fragment = document.createDocumentFragment();
      fragment.appendChild(link);

      this.lastCarretPosition.range.deleteContents();
      this.lastCarretPosition.range.insertNode(fragment);

      this.resetLinkEditing();

      this.$emit('update:model-value', this.$refs.content.innerHTML);
      this.checkSelection();
    },
    checkSelection() {
      if (this.modelValue === null) {
        if (this.editorValue === '<div>Redigez votre texte</div>') {
          this.editorValue = '<div></div>';
        }
      }

      let selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        if (selection.getRangeAt(0)) {
          let range = selection.getRangeAt(0);
          var selectedText = range.toString();
          this.lastCarretPosition = {
            range,
            selectedText,
          };
        }
      }

      if (selection) {
        this.isEmojiCreationAllowed = true;
      } else {
        this.isEmojiCreationAllowed = false;
      }

      if (this.lastCarretPosition.selectedText) {
        this.isLinkCreationAllowed = true;
      } else {
        this.isLinkCreationAllowed = false;
      }

      if (
        this.lastCarretPosition.range.commonAncestorContainer.parentNode.href
      ) {
        this.isSelectionLink = true;
      } else {
        this.isSelectionLink = false;
      }

      document.queryCommandState('bold', false)
        ? (this.isSelectionBold = true)
        : (this.isSelectionBold = false);
      document.queryCommandState('italic', false)
        ? (this.isSelectionItalic = true)
        : (this.isSelectionItalic = false);
      document.queryCommandState('strikethrough', false)
        ? (this.isSelectionStriked = true)
        : (this.isSelectionStriked = false);

      document.queryCommandState('insertOrderedList', false)
        ? (this.isSelectionOrderedList = true)
        : (this.isSelectionOrderedList = false);
      document.queryCommandState('insertUnorderedList', false)
        ? (this.isSelectionUnorderedList = true)
        : (this.isSelectionUnorderedList = false);

      document.queryCommandState('justifyLeft', false)
        ? (this.isSelectionLeft = true)
        : (this.isSelectionLeft = false);
      document.queryCommandState('justifyCenter', false)
        ? (this.isSelectionCenter = true)
        : (this.isSelectionCenter = false);
      document.queryCommandState('justifyRight', false)
        ? (this.isSelectionRight = true)
        : (this.isSelectionRight = false);
    },
  },
};
</script>