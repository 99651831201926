<template>
  <button
    class="flex items-center disabled:opacity-50 disabled:pointer-events-none"
    :disabled="disabled"
  >
    <Icon icon="close-circle" :size="size" />
  </button>
</template>

<script>
import Icon from '@/components/icon/Icon.vue';

export default {
  name: 'RemoveButton',
  components: {
    Icon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'mlg',
    },
  },
};
</script>
