<template>
  <tr
    @click="handleRowClick" 
    class="group relative table-row w-full transition"
    :class="[
      {
        'opacity-50': isSelected,
        disabled,
        'no-actions': !$slots.actions,
        'table-row--link': link,
        'cursor-move': sortable && draggable,
        grayscale: isRemoveButtonHovered,
      },
      size && `size-${size}`,
      'hover:bg-skin-ui-hover cursor-pointer'
    ]"
    tabindex="0"
  >
    <TableCell 
      v-if="sortable"  
      class="!pr-0" 
      stop-click
      shrink
      @click.stop
    >
      <div class="flex">
        <FormInput
          square
          size="sm"
          :model-value="rank"
          type="number"
          @enter="handleSort($event)"
        />
      </div>
    </TableCell>
    <slot />
    <TableCell 
      v-if="$slots.actions" 
      class="!pr-0" 
      stop-click
      shrink
      @click.stop
    >
      <div class="flex justify-end">
        <slot name="actions" />
      </div>
    </TableCell>
    <TableCell
      v-if="removable"
      ref="removeButtonWrapper"
      class="remove-button-wrapper !pr-0"
      shrink
      stop-click
      @click.stop
    >
      <RemoveButton @click="emit('remove')" />
    </TableCell>
    <TableCell 
      v-if="activable"
      class="!pr-0"
      shrink
      stop-click
      @click.stop
    >
      <FormSwitch
        @click.stop
        :checked="isActive"
        :label-as-tooltip="{
          label: true,
          position: 'left',
          align: 'none',
        }"
        :label-false="toggleLabel?.active ?? $('items.actions.toggle.on')"
        :label-true="toggleLabel?.inactive ?? $('items.actions.toggle.off')"
        class="!pr-0"
        @update:model-value="emit('toggle')"
      />
    </TableCell>
  </tr>
</template>

<script setup>
import { computed, onUpdated, provide, ref, useSlots } from 'vue'
import { useElementHover } from '@vueuse/core';
import { useStore } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import FormSwitch from '@/components/form/FormSwitch.vue';
import RemoveButton from '@/components/button/RemoveButton.vue';
import TableCell from '@/components/table/TableCell.vue';

const removeButtonWrapper = ref();
const isRemoveButtonHovered = useElementHover(removeButtonWrapper);
const slots = useSlots();
const store = useStore();

const emit = defineEmits(['click', 'remove', 'sort', 'toggle']);

const props = defineProps({
  link: [String, Object, Boolean],
  disabled: Boolean,
  isSelected: Boolean,
  selectable: Boolean,
  isActive: Boolean,
  activable: Boolean,
  toggleLabel: Object,
  option: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  sortable: Boolean,
  removable: Boolean,
  index: Number,
  editable: Boolean,
  size: String,
  first: Boolean,
  last: Boolean,
  mode: String,
  draggable: {
    type: Boolean,
    default: true,
  },
});

const rank = ref(props.index + 1);
const isSelectOption = computed(() => {
  return props.option;
});
const handleRowHref = computed(() => {
  return props?.link ?? null;
});
const handleRowClick = () => {
  if (!props?.link) {
    emit('click');
  }
};

provide('isSelectOption', isSelectOption);
provide('routerLink', handleRowHref);
provide('compact', props.compact);

const handleSort = (value) => {
  if (!value || isNaN(value) || parseInt(value) < 1) {
    store.commit('showModal', {
      componentName: 'ModalGenericConfirm',
      size: 'sm',
      closeOnClickOutside: false,
      showCloseButton: false,
      componentParams: {
        icon: 'warning-filled',
        iconClass: 'text-warning-500',
        action: ' ',
        title: 'generic.modal.error.rank.title',
        catchphrase: 'generic.modal.error.rank.message',
        confirmAction: function () {
          rank.value = props.index + 1;
        },
        hasCancel: false,
      },
    });
  } else {
    emit('sort', value);
  }
};
onUpdated(() => {
  rank.value = props.index + 1;
});
</script>
