import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/videos/';

export const videoService = {
  getAll,
  get,
  update,
  //create,
  deleteVideo,
  validateVideo,
  getTimecodes,
  createTimecode,
  updateTimecode,
  deleteTimecode,
};

async function getAll(queryString) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_MEDIA_BASE_URL +
      api_url_module +
      '?' +
      queryString
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function get(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_MEDIA_BASE_URL +
      api_url_module +
      id +
      '?drm=1&with=content-detail'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function getTimecodes(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + id + '/time-codes'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function createTimecode(id, timecode) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + id + '/time-codes',
      timecode,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function updateTimecode(timecodeId, videoId, timecode) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_MEDIA_BASE_URL +
      api_url_module +
      videoId +
      '/time-codes/' +
      timecodeId,
      timecode,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteTimecode(timecodeId, videoId) {
  try {
    const response = await axios.delete(
      import.meta.env.VITE_MEDIA_BASE_URL +
      api_url_module +
      videoId +
      '/time-codes/' +
      timecodeId
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(id, video) {
  const requestOptions = {
    method: 'PUT',
  };

  try {
    const response = await axios.put(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + id,
      video,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteVideo(id) {
  try {
    const response = await axios.delete(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + id
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function validateVideo({ path, origin_server }) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + 'check',
      { path, origin_server },
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
