<template>
  <span
    class="inline-flex gap-1 font-light italic items-center"
    :class="size === 'lg' ? 'text-sm' : 'text-xs'"
  >
    <span v-if="label" class="whitespace-nowrap">{{ label }} :</span>
    <span>
      {{ id }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'Id',
  props: {
    label: {
      type: String,
      default: 'ID',
    },
    id: {
      type: [Number, String],
      required: true,
    },
    size: String,
  },
};
</script>
