<template>
  <div>
    <BaseButton
      v-if="!hasTimecodes"
      nude
      size="xxs"
      class="flex flex-row items-center px-0"
      @click="showModalTimecode()"
      :disabled="disabled"
    >
      <Icon icon="add" size="sm" />
      <strong class="text-xs">{{ $t('videos.timecodes.button.add') }}</strong>
    </BaseButton>
    <BaseButton
      v-else
      link
      :with-theme="false"
      size="xxs"
      class="flex flex-row items-center gap-1"
      @click="showModalTimecode()"
      :disabled="disabled"
    >
      <strong class="text-xs">{{ $t('videos.timecodes.title') }} :</strong>
      <div v-if="skipIntro" class="text-xs gap-1 flex flex-row items-center">
        <Icon icon="stopwatch" size="sm" />
        <time>
          {{ skipIntroStart }} -
          {{ skipIntroEnd }}
        </time>
      </div>
      /
      <div v-if="skipOutro" class="text-xs gap-1 flex flex-row items-center">
        <Icon icon="flag" size="sm" />
        <time>
          {{ skipOutroStart }}
        </time>
      </div>
      <IconButton nude>
        <Icon icon="edit" size="sm" />
      </IconButton>
    </BaseButton>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import IconButton from '@/components/icon/IconButton.vue';
import utils from '@/utils/utils';

export default {
  name: 'VideoTimecodes',
  components: {
    IconButton,
    BaseButton,
    Icon,
  },
  inject: ['serieId', 'seasonId', 'movieType'],
  props: {
    videoData: Object,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    videoTimecodes() {
      return this.savedNewVideoTimecodes.filter(
        (timecode) => timecode.video_id === this.videoData.id
      );
    },
    skipTimecodesType() {
      return ['intro', 'outro'];
    },
    hasTimecodes() {
      return this.videoTimecodes.find((timecode) =>
        this.skipTimecodesType.includes(timecode.type)
      );
    },
    skipIntro() {
      return this.videoTimecodes.find((timecode) => timecode.type === 'intro');
    },
    skipIntroStart() {
      return utils.toTimecode(this.skipIntro.start);
    },
    skipIntroEnd() {
      return utils.toTimecode(this.skipIntro.end);
    },
    skipOutro() {
      return this.videoTimecodes.find((timecode) => timecode.type === 'outro');
    },
    skipOutroStart() {
      return utils.toTimecode(this.skipOutro.start);
    },
    ...mapState({
      savedNewVideoTimecodes: (state) => state.videos.savedNewVideoTimecodes,
    }),
  },
  methods: {
    showModalTimecode() {
      this.$emit('showModalTimecode');
      const videoData = cloneDeep(this.videoData);
      const self = this;

      this.showModal({
        componentName: 'ModalTimecode',
        componentParams: {
          videoData,
          type: this.movieType,
          serieId: this.serieId,
          seasonId: this.seasonId,
        },
        size: 'md',
        showCloseButton: false,
        closeOnClickOutside: false,
      });
    },
    ...mapActions('videos', {
      getTimecodes: 'getTimecodes',
    }),
    ...mapMutations(['showModal']),
  },
  async created() {
    await this.getTimecodes({ id: this.videoData.id, init: false });
  },
};
</script>
