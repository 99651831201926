<template>
  <div>
    <main
      class="flex flex-col flex-1 justify-items-stretch theme-beach bg-skin-background text-skin-base p-0 h-screen"
    >
      <LoaderFull :loader-visible="loaderVisible" />
      <slot />
    </main>
  </div>
</template>
<script>
import LoaderFull from '@/components/loader/LoaderFull.vue';

export default {
  components: {
    LoaderFull,
  },
  props: {
    loaderVisible: Boolean,
  },
};
</script>
