<template>
  <article class="relative">
    <header
      class="flex items-center h-12 border border-solid border-skin-ui bg-skin-action-background"
      :class="{
        'border border-solid border-skin-ui': withDetails,
      }"
    >
      <Image
        :src="coverImage"
        :width="82"
        :height="46"
        class="relative text-skin-inverted"
      >
        <Icon
          icon="play-circle"
          size="lg"
          color="white"
          class="!absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </Image>
      <div
        class="py-2 px-4 flex flex-wrap lg:flex-no-wrap gap-2 flex-1 justify-between self-stretch"
      >
        <h4 class="flex items-center gap-2" :class="{ editable: editable }">
          <span class="font-light text-xs lg:text-sm line-clamp-2">{{
            videoData.name
          }}</span>
          <Icon v-if="editable" icon="edit" size="sm" />
        </h4>
        <div class="flex gap-8 justify-end">
          <Id
            v-if="showId"
            :id="videoData.id"
            label="ID"
            class="lg:basis-[170px]"
          />
          <Tag
            v-if="videoData.with_drm"
            rounded
            size="xs"
            class="bg-action-muted text-skin-inverted"
          >
            <Icon icon="lock-check" size="sm" />DRM
          </Tag>
        </div>
      </div>
      <div class="flex justify-end">
        <Icon v-if="partnerIcon" :icon="partnerIcon" size="lg" class="mr-4"/>
      </div>
    </header>
    <VideoFooter
      v-if="withDetails"
      class="lg:mt-2"
      :justify="showTimecodeInfo ? 'space-between' : 'end'"
    >
      <VideoMetaSection v-if="showTimecodeInfo">
        <VideoMeta>
          <VideoTimecodes :video-data="videoData" video-type="program" />
        </VideoMeta>
      </VideoMetaSection>
      <VideoMetaSection>
        <VideoMeta>
          <VideoAudiotracks
            :video-data="videoData"
            :partner-id="partnerId"
            type="program"
            :videoType="type"
            :show="showAudios"
            @toggle="showAudios = !showAudios"
          />
        </VideoMeta>
        <VideoMeta>
          <VideoSubtitles
            :video-data="videoData"
            :partner-id="partnerId"
            type="program"
            :show="showSubtitles"
            @toggle="showSubtitles = !showSubtitles"
          />
        </VideoMeta>
      </VideoMetaSection>
    </VideoFooter>
    <RemoveButton
      v-if="removable"
      class="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2"
      @click="$emit('remove')"
    />
  </article>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/icon/Icon.vue';
import Id from '@/components/id/Id.vue';
import Image from '@/components/image/Image.vue';
import RemoveButton from '@/components/button/RemoveButton.vue';
import Tag from '@/components/tag/Tag.vue';
import VideoAudiotracks from '@/components/video/VideoAudioTracks.vue';
import VideoFooter from '@/components/video/VideoFooter.vue';
import VideoMeta from '@/components/video/VideoMeta.vue';
import VideoMetaSection from '@/components/video/VideoMetaSection.vue';
import VideoSubtitles from '@/components/video/VideoSubtitles.vue';
import VideoTimecodes from './VideoTimecodes.vue';
import { assetUrl } from '@/data/constants.js';
import { partners }  from '@/data/partners.js';

const store = useStore();

const props = defineProps({
  type: String,
  withDetails: Boolean,
  videoData: Object,
  editable: Boolean,
  removable: Boolean,
  partnerId: {
    type: String,
    default: null,
  },
  showId: {
    type: Boolean,
    default: false,
  },
  displayPartner: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['remove']);

const showAudios = ref(false);
const showSubtitles = ref(false);

const coverImage = computed(() => {
  return props.videoData?.picture?.thumbnails?.[0]?.url ??
    props.videoData?.picture?.backdrops?.[0]?.url ??
    assetUrl(`image-thumbnails-placeholder.png`);
});

const showTimecodeInfo = computed(() => {
  return (
    timecodesActivated.value &&
    props.videoData.id &&
    props.videoData.type === 'main' &&
    props.videoData.group_environment === 'otto'
  );
});

const timecodesActivated = computed(() => 
  store.state.platforms.currentPlatform.content_config.feature.skip_intro_outro
);

const partnerIcon = computed(() => 
  props.displayPartner ? partners.find((partner) => partner.id === props.partnerId)?.icon : null
);
</script>
