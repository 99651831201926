export const buttonColorClasses =
  'btn-plain text-skin-inverted disabled:text-skin-inverted border-skin-action-accent hover:border-skin-action-accent-hover bg-skin-action-accent active:shadow-none active:bg-skin-action-accent-active hover:bg-skin-action-accent-hover hover:shadow-sm hover:shadow-skin-action-accent disabled:bg-skin-action-disabled disabled:border-skin-action-disabled readonly:bg-skin-action-readonly readonly:border-skin-action-readonly';

export const dashedColorClasses =
  'border border-dashed border-skin-ui bg-skin-action-dashed theme-dashed';

export const dashedButtonColorClasses =
  dashedColorClasses +
  ' hover:border-skin-action-hover hover:bg-skin-action-muted/25';

export const outlinedButtonColorClasses =
  'text-skin-accent border-skin-action-accent hover:border-skin-action-accent-hover hover:bg-skin-action-accent-hover hover:text-skin-inverted focus-visible:border-skin-action-accent-hover focus-visible:bg-skin-action-accent-hover focus-visible:text-skin-inverted disabled:border-skin-action-disabled';

export const inputFieldButtonColorClasses =
  'text-skin-base placeholder:text-skin-placeholder placeholder:italic border border-solid border-skin-action-muted hover:border-skin-action-hover hover:focus:border-skin-action-accent focus:border-skin-action-accent focus-within:border-skin-action-accent hover:focus-within:border-skin-action-accent focus-visible:border-skin-action-accent hover:focus-visible:border-skin-action-accent peer-focus:border-skin-action-accent disabled:border-skin-action-muted group-disabled:border-skin-action-muted disabled:text-skin-muted group-disabled:text-skin-muted';

export const inputFieldAddonColorClasses =
  'placeholder:text-skin-placeholder placeholder:italic px-ui-component bg-skin-ui first:rounded-l-sm last:rounded-r-sm first:rounded-r-none last:rounded-l-none border border-solid border-skin-action-muted group-hover:border-skin-action-hover group-hover:border-skin-action-hover group-focus:border-skin-action-accent group-active:border-skin-action-accent inline-flex items-center min-w-fit peer-disabled:text-skin-disabled peer-disabled:cursor-not-allowed group-disabled:text-skin-disabled group-disabled:cursor-not-allowed';

export const nudeButtonColorClasses = 'text-skin-base disabled:text-skin-muted';

export const uiComponentClasses = 'px-ui-component h-ui-component';

export const linkColorClasses = 'text-skin-accent';

export const themeClasses = {
  primary: 'theme-primary',
  secondary: 'theme-secondary',
  tertiary: 'theme-tertiary',
  green: 'theme-green',
  purple: 'theme-purple',
  gray: 'theme-gray',
  red: 'theme-red',
  teal: 'theme-teal',
  yellow: 'theme-yellow',
  success: 'theme-success',
  warning: 'theme-warning',
  danger: 'theme-danger',
  info: 'theme-info',
  disabled: 'theme-disabled',
};

export const fontWeightClasses = {
  thin: 'font-thin',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  heavy: 'font-heavy',
  black: 'font-black',
};

export const fontSizeClasses = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  xxl: 'text-2xl',
  xxxl: 'text-3xl',
  xxxxl: 'text-4xl',
  xxxxxl: 'text-5xl',
  xxxxxxl: 'text-6xl',
};

export const textDecorationClasses = {
  none: 'no-underline',
  underline: 'underline',
  lineThrough: 'ine-through',
  overline: 'overline',
};

export const spacerClasses = {
  xs: 'gap-1',
  sm: 'gap-2',
  md: 'gap-4',
  lg: 'gap-6',
  xl: 'gap-8',
};

export const blockSpacingClasses = {
  sm: 'py-2',
  md: 'py-4',
  lg: 'py-6',
  xl: 'py-10',
  xxl: 'py-20',
};

export const blockPaddingClasses = {
  sm: 'p-2',
  md: 'p-4',
  lg: 'p-6',
  xl: 'p-10',
  xxl: 'p-20',
};

export const optionsSpacerClasses = {
  xs: 'gap-1',
  sm: 'gap-2',
  md: 'gap-4',
  lg: 'gap-6',
  xl: 'gap-8',
  xxl: 'gap-12',
};

export const wrapClasses = {
  wrap: 'flex-wrap',
  nowrap: 'flex-no-wrap',
};

export const textAlignClasses = {
  center: 'text-center',
  left: 'text-left !justify-start',
  right: 'text-right !justify-end',
  justify: 'text-justify',
};

export const gridTemplateColumnsClasses = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
  13: 'grid-cols-13',
  14: 'grid-cols-14',
  15: 'grid-cols-15',
  16: 'grid-cols-16',
};

export const justifyContentClasses = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  'space-between': 'justify-between',
  'space-around': 'justify-around',
};

export const alignItemsClasses = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  between: 'items-between',
  around: 'items-around',
  evenly: 'items-evenly',
};

export const sizeClasses = {
  xxs: 'size-xxs',
  xs: 'size-xs',
  sm: 'size-sm',
  md: 'size-md',
  lg: 'size-lg',
  xl: 'size-xl',
  xxl: 'size-xxl',
};

export const inputIconSize = {
  xxs: 'sm',
  xs: 'sm',
  sm: 'sm',
  md: 'sm',
  lg: 'md',
  xl: 'lg',
  xxl: 'xl',
};
export const inputCaretSize = {
  xs: 'xxs',
  sm: 'xs',
  md: 'xs',
  lg: 'xs',
  xl: 'sm',
};

export const iconsSizes = {
  xxxs: 'w-1 h-1',
  xxs: 'w-1.5 h-1.5',
  xs: 'w-2 h-2',
  s: 'w-2.5 h-2.5',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  mlg: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-8 h-8',
  xlg: 'w-12 h-12',
  xxl: 'w-16 h-16',
  xxxl: 'w-20 h-20',
  full: 'w-full h-full',
  auto: 'w-auto h-full',
};

export const iconColorClasses = {
  primary: 'text-primary-500',
  secondary: 'text-secondary-500',
  tertiary: 'text-tertiary-500',
  green: 'text-green-500',
  gray: 'text-slate-500',
  red: 'text-red-500',
  teal: 'text-teal-500',
  yellow: 'text-yellow-500',
  success: 'text-success-500',
  warning: 'text-warning-500',
  info: 'text-info-500',
  danger: 'text-danger-500',
  disabled: 'text-ui-200',
  purple: 'text-purple-500',
};
