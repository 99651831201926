<template>
  <button
    :id="id"
    ref="button"
    class="btn otto-base-ui-component transition-all ease-in-out select-none inline-flex items-center rounded-ui-component group-last:not(:first-child):rounded-l-none border gap-2 group relative"
    :class="[
      uiElementSizeClass,
      ...uiElementButtonClass,
      ((disabled && loading) || readonly) && 'pointer-events-none',
      size === 'xxl' && 'flex-col',
      fullWidth && 'w-full'
    ]"
    :disabled="disabled && !loading"
    tabindex="0"
  >
    <slot v-bind="{ iconSize: uiElementIconSize }">
      <span v-if="loading" class="btn-loader">
        <Icon icon="spinner" class="btn-icon animate-spin" />
      </span>
      <Icon v-if="iconBefore" :icon="iconBefore" :size="uiElementIconSize" />
      <span
        v-if="label && !loading"
        class="btn-label truncate whitespace-nowrap"
      >
        {{ $t(label) }}
      </span>
      <Icon v-if="iconAfter" :icon="iconAfter" :size="uiElementIconSize" />
    </slot>
    <RemoveButton
      v-if="removable"
      :size="uiElementIconSize"
      class="btn-remove absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
      @click.prevent.stop="emit('remove')"
    />
  </button>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useUiElement } from '@/composables/useUiElement.js';
import Icon from '@/components/icon/Icon.vue';
import RemoveButton from '@/components/button/RemoveButton.vue';

const emit = defineEmits(['remove']);
const props = defineProps({
  id: {
    type: [String, Number],
    default: () => `id_${Math.floor(Math.random() * 10000)}`,
  },
  label: String,
  title: Boolean,
  action: String,
  disabled: Boolean,
  readonly: Boolean,
  fontSize: String,
  textAlign: String,
  fontWeight: {
    type: String,
    default: 'medium',
  },
  size: {
    type: String,
    default: 'md',
  },
  withTheme: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    validator: (value) =>
      ['success', 'warning', 'error', 'info'].includes(value),
  },
  loading: {
    default: false,
    type: Boolean,
  },
  autofocus: Boolean,
  buttonStyle: String,
  decoration: String,
  type: String,
  dashed: Boolean,
  outline: Boolean,
  underline: Boolean,
  ghost: Boolean,
  field: Boolean,
  nude: Boolean,
  link: Boolean,
  hoverable: Boolean,
  justify: {
    type: String,
    default: 'center',
  },
  inline: {
    type: Boolean,
    default: false,
  },
  removable: {
    default: false,
    type: Boolean,
  },
  editable: {
    default: false,
    type: Boolean,
  },
  square: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  iconBefore: String,
  iconAfter: String,
});

let button = ref(null);
const focused = computed(() => {
  return props.autofocus;
});
watch(focused, () => {
  if (focused.value) {
    setTimeout(() => {
      button.value.focus();
    }, 500);
  }
});
onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      button.value.focus();
    }, 200);
  }
});
const { uiElementSizeClass, uiElementIconSize, uiElementButtonClass } =
  useUiElement(props);
</script>
