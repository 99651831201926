import { cloneDeep, isEmpty, isNil, join } from 'lodash';
import { difference } from '@/utils/diffTools';
import { adminService, userService } from '@/services';
import User from '@/models/User';
import utils from '@/utils/utils';
import adminRoles from '@/data/adminRoles';
import { RoleTransformer } from '@/transformers';
import AdminsUtil from '@/utils/AdminsUtil';

const alertSuccess = (action) => {
  return {
    id: 'admins',
    icon: 'check',
    type: 'valid',
    message: `notifications.${action}.success`,
  }
};

const alertFailed = (action) => {
  return {
    id: 'admins',
    icon: 'close',
    type: 'error',
    message: `notifications.${action}.error`,
  }
};

const state = {
  all: [],
  currentNewAdmin: {},
  savedAdmin: {},
  currentMyProfile: {},
  savedMyProfile: {},
  errors: {},
  loading: false,
};

const actions = {
  set({ commit }, admin) {
    commit('setLoading', true);
    commit('setAdmin', admin);
  },

  initNewAdmin({ commit, rootGetters }) {
    commit('setLoading', true);
    let roles = AdminsUtil.adminRolesByPermission(cloneDeep(adminRoles))
    commit('initNewAdmin', roles);
  },

  refresh({ state, dispatch }) {
    const params = {
      page: 1,
      sort: "date:desc",
      count: "10",
    };
    return dispatch('getAll', params).then(() => {
      return Promise.resolve();
    });
  },

  getAll({ commit, rootState, dispatch }, params) {
    commit('setLoading', true);
    let page = 'page' in params ? params.page : 1;
    let sort = 'sort' in params ? params.sort : '';
    let query = 'query' in params && params.query != ''
      ? '&query=' + params.query
      : '';
    let count = '&count=' + ('count' in params ? params.count : 10);

    let queryString = utils.makeQueryString([], sort, page);

    const personalToken = rootState.auth.authUser?.tokens?.personal;

    return adminService
      .getAll(queryString + query + count, personalToken)
      .then(
        (admins) => {
          commit('getAllSuccess', admins);
          return Promise.resolve(admins);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      });
  },

  get({ commit, rootState, dispatch }, { id, refresh = false }) {
    commit('getRequest');
    if (refresh) {
      dispatch('displayLoader', true, { root: true });
    }
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    return adminService
      .get(id, personalToken)
      .then(
        async (admin) => {
          commit('getSuccess', admin);
          return Promise.resolve(admin);
        },
        (error) => {
          commit('getFailure', error);
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      });
  },

  create({ commit, rootState, dispatch }) {
    commit('initRequest');
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    delete state.currentNewAdmin.adminRoles;
    return adminService.create(state.currentNewAdmin, personalToken).then(
      (_) => {
        const success = alertSuccess('create');
        dispatch('displayAlert', success, { root: true });
        return dispatch('refresh');
      },
      (err) => {
        commit('getFailure', err);
        const error = alertFailed('create');
        const message = err?.errors?.error === "An admin already exists with this email"
          ? 'admin.pages.admins.notifications.exists'
          : error.message;
        dispatch(
          'displayAlert',
          {
            ...error,
            message: message,
          },
          { root: true }
        );
        return Promise.reject(err);
      }
    );
  },

  updatePassword({ commit, state, rootState, dispatch }, { id, data }) {
    return userService.update(data, id).then(
      (admin) => {
        const success = alertSuccess('update');
        dispatch('displayAlert', success, { root: true });
        return Promise.resolve(admin);
      },
      (err) => {
        commit('getFailure', err);
        const error = alertFailed('update');
        dispatch('displayAlert', error, { root: true });
        return Promise.reject(err);
      }
    )
  },

  updateMyProfile({ commit, state, rootState, dispatch }) {

    let diffAdmin = difference(state.currentMyProfile, state.savedMyProfile);
    const adminId = state.savedMyProfile.id;
    delete diffAdmin.roles
    delete diffAdmin.adminRoles;
    if (isNil(diffAdmin) || isEmpty(diffAdmin)) {
      return Promise.resolve();
    }

    return userService.update(diffAdmin, adminId).then(
      (_) => {
        commit('updateMyProfileSuccess');
        const success = alertSuccess('update');
        dispatch('displayAlert', success, { root: true });
        return Promise.resolve();
      },
      (err) => {
        commit('getFailure', err);
        const error = alertFailed('update');
        if (error?.errors?.email[0] === 'unique') {
          dispatch(
            'displayAlert',
            {
              ...error,
              message: 'error.user.email.update',
            },
            { root: true }
          );
        } else {
          dispatch('displayAlert', error, { root: true });
        }
        return Promise.reject(err);
      }
    );
  },

  update({ commit, state, rootState, dispatch }) {
    commit('initRequest');

    let diffAdmin = difference(state.currentNewAdmin, state.savedAdmin);
    diffAdmin.roles = state.currentNewAdmin.roles;
    const adminId = state.savedAdmin.id;
    delete diffAdmin.adminRoles;

    if (isNil(diffAdmin) || isEmpty(diffAdmin)) {
      return Promise.resolve();
    }
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    return adminService.update(diffAdmin, adminId, personalToken).then(
      (_) => {
        commit('updateSuccess');
        const success = alertSuccess('update');
        dispatch('displayAlert', success, { root: true });
        return dispatch('refresh');
      },
      (err) => {
        commit('getFailure', err);
        const error = alertFailed('update');
        if (error?.errors?.email[0] === 'unique') {
          dispatch(
            'displayAlert',
            {
              ...error,
              message: 'error.user.email.update',
            },
            { root: true }
          );
        } else {
          dispatch('displayAlert', error, { root: true });
        }
        return Promise.reject(err);
      }
    );
  },

  deleteOne({ commit, rootState, dispatch }, itemId) {
    commit('setLoading', true);
    const personalToken = rootState.auth.authUser?.tokens?.personal;
    return adminService.deleteOne(itemId, personalToken).then(
      (_) => {
        commit('deleteSuccess');
        const success = alertSuccess('delete');
        dispatch('displayAlert', success, { root: true });
        return dispatch('refresh');
      },
      (err) => {
        const error = alertFailed('delete');
        dispatch('displayAlert', error, { root: true });
        return Promise.reject(err);
      }
    );
  },

  resetOne({ commit }) {
    commit('resetOne');
  },

  resetAll({ commit }) {
    commit('resetAll');
  },

  setNewPicture({ commit }, url) {
    commit('setNewPicture', url);
  },
};

const mutations = {

  setLoading(state, value) {
    state.loading = value;
  },
  //---------------------------- ALL admins -----------------

  resetAll(state) {
    state.all = [];
    state.currentNewAdmin = {};
    state.savedAdmin = {};
    state.errors = {};

  },

  getAllSuccess(state, items) {
    let admins = cloneDeep(items);
    admins.data = admins.data.map(element => {
      let admin = new User(element);
      const rolesTrans = new RoleTransformer(element.roles);
      admin.adminRoles = rolesTrans.stringArrayToObjectArray();
      return admin;
    });
    state.all = admins;
    state.loading = false;
  },

  getAllFailure(state, errors = null) {
    state.all = [];
    state.errors = errors;
    state.loading = false;
  },

  //---------------------------- ONE admin -----------------

  setNewPicture(state, url) {
    state.currentNewAdmin.setNewPicture(url);
  },

  setAdmin(state, admin) {
    state.currentNewAdmin = cloneDeep(admin);
    state.savedAdmin = admin;
    state.loading = false;
  },

  updateSuccess(state) {
    state.savedAdmin = cloneDeep(state.currentNewAdmin);
    state.loading = false;
  },

  updateMyProfileSuccess(state) {
    state.savedMyProfile = cloneDeep(state.currentMyProfile);
    state.loading = false;
  },

  initNewAdmin(state, roles) {
    const admin = new User();
    admin.adminRoles = cloneDeep(roles);
    state.currentNewAdmin = cloneDeep(admin);
    state.loading = false;
  },

  initRequest(state) {
    state.loading = true;
    const rolesTrans = new RoleTransformer(
      state.currentNewAdmin.roles, state.currentNewAdmin.adminRoles
    );
    const roles = rolesTrans.objectArrayToStringArray();
    state.currentNewAdmin.roles = roles;
    state.savedAdmin.roles = roles;
  },

  resetOne(state) {
    state.loading = true;
    state.currentNewAdmin = {};
    state.savedAdmin = {};
    state.errors = {};
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
    state.currentNewAdmin = {};
    state.savedAdmin = {};
  },

  getSuccess(state, admin) {
    let newAdmin = new User(admin);
    const rolesTrans = new RoleTransformer(newAdmin.roles);
    newAdmin.adminRoles = rolesTrans.stringArrayToObjectArray();
    state.savedMyProfile = newAdmin;
    state.currentMyProfile = cloneDeep(newAdmin);
    state.loading = false;
  },

  getFailure(state, errors) {
    state.errors = errors;
    state.loading = false;
  },

  deleteSuccess(state) {
    state.currentNewAdmin = {};
    state.savedAdmin = {};
    state.loading = false;
  },
};

const getters = {
  shouldSaved: (state) => {
    const diffAdmin = difference(state.currentNewAdmin, state.savedAdmin);
    return !(isNil(diffAdmin) || isEmpty(diffAdmin)) &&
      !state.currentNewAdmin?.newEmailInvalid
      && state.currentNewAdmin?.email !== ""
  },
  shouldSavedMyProfile: (state) => {
    const diffAdmin = difference(state.currentMyProfile, state.savedMyProfile);
    return !(isNil(diffAdmin) || isEmpty(diffAdmin)) &&
      !state.currentMyProfile?.newEmailInvalid
  },
};

export const admins = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
