import moment from 'moment-timezone';

export class DiscountCampaign {
  id;
  plan_id;
  status;
  name;
  front_name;
  prefix;
  target_type;
  type;
  redemption_type;
  count;
  starts_at;
  ends_at;
  group_plan_id;
  usable_by;

  constructor({
    id = 0,
    plan_id = 0,
    coupon_id = 0,
    status = 'offline',
    name = '',
    front_name = '',
    plan_name = '',
    prefix = '',
    target_type = 'ALL',
    type = 'classic',
    count = 1,
    periods = {
      trial_in_days: 0,
      cycle_count: -1,
      interval_count: 0,
      interval: 'month',
    },
    reduction = {
      percent_off: null,
      amount_off: 0,
      amount_off_with_currency: null,
      standard: 0,
    },
    redemption = 0,
    redemption_user = null, //in the end, will be an integer
    redemption_limit = 0,
    starts_at = null,
    ends_at = null,
    group_plan_id = null,
    usable_by = '',
  }) {
    this.id = id;
    this.plan_id = plan_id;
    this.coupon_id = coupon_id;
    this.status = status;
    this.name = name;
    this.front_name = front_name;
    this.periods = {};
    this.periods.trial_in_days = periods.trial_in_days ?? 0;
    this.periods.cycle_count = periods.cycle_count ?? 0;
    this.periods.interval_count = periods.interval_count ?? 0;
    this.periods.interval = periods.interval ?? null;
    this.reduction = {};
    this.reduction.percent_off = reduction.percent_off;
    this.reduction.amount_off = reduction.amount_off ?? 0;
    this.reduction.amount_off_with_currency =
      reduction.amount_off_with_currency;
    this.reduction.standard = reduction.standard ?? 0;
    this.plan_name = plan_name;
    this.prefix = prefix === null ? '' : prefix;
    this.target_type = target_type;
    this.type = type === 'prepaidcoupon' ? 'prepaid' : 'classic';
    this.count = count;
    this.group_plan_id = group_plan_id;
    this.uses = {
      current: redemption,
      max: redemption_limit,
      per_user: redemption_user,
    };
    this.redemption_type = count > 1 ? 'multiple' : 'uniq';
    this.starts_at =
      starts_at === null
        ? null
        : moment.tz(starts_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.ends_at =
      ends_at === null
        ? null
        : moment.tz(ends_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.usable_by = usable_by;
  }
}
