import { isEmpty, isNull } from 'lodash';
import { Card } from '@/models/billing/Card.js';
import { Customer } from '@/models/billing/Customer.js';
import { Sepa } from '@/models/billing/Sepa.js';
import Picture from '@/models/Picture';

export class User {
  original_avatar_url = '';

  constructor({
    id = 1,
    firstname = '',
    lastname = '',
    name = '',
    email = '',
    photo_url = '',
    description = '',
    customer = {},
    social = '',
    picture = {},
    client_country = {},
    payment_methods = {},
    roles = [],
    created_at = new Date().toISOString(),
  } = {}) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.name = name;
    this.fullname = firstname + ' ' + lastname;
    this.email = email;
    this.email_confirmation = '';
    this.password = '********';
    this.password_confirmation = '';
    this.photo_url = photo_url;
    this.description = description;
    this.created_at = created_at;

    this.customer = new Customer(customer ?? {});

    this.status = this.customer?.status ?? 'not-set';

    this.creditCard = [];

    this.social_connection = social ? social : null; //
    this.avatar = { url: null };

    this.subscriptions = this.customer?.subscriptions ?? []; //offerId, startDate, endDate, status
    this.subscriptionEvents = {};

    this.client_country = client_country ?? {};

    this.watchedVideos = {}; // videoId, date, viewingDuration

    this.payment_methods = {
      sepas: (payment_methods?.sepas ?? []).map((item) => new Sepa(item)),
      cards: (payment_methods?.cards ?? []).map((item) => new Card(item)),
    }

    this.roles = roles; // list of an admin's roles
    
    this.transactions = {};
    this.setVouchers();
    this.setPicture(picture);
    this.setAvatar();
    this.setSubscriptionEvents();
  }

  setAvatar() {
    if (
      this.picture &&
      this.picture.roundeds &&
      this.picture.roundeds[0] &&
      this.picture.roundeds[0].url
    ) {
      this.avatar.url = this.picture.roundeds[0].url;
    }

    if (
      this.avatar &&
      this.avatar.url &&
      this.social_connection &&
      this.social_connection.avatar
    ) {
      this.avatar.url = this.social_connection.avatar;
    }
    this.original_avatar_url = this.avatar.url;
  }

  setNewPicture() {
    if (
      this.avatar &&
      this.avatar.url &&
      this.avatar.url != '' &&
      this.original_avatar_url != this.avatar.url
    ) {
      this.picture = { roundeds: [{ url: this.avatar.url }] };
    } else {
      this.picture = { roundeds: [{ url: '' }] };
    }
  }

  setPicture(picture) {
    this.picture = Picture.getDefaultPicture('user');
    const pictureTypes = Picture.getDefaultPictureTypesList('user');
    if (typeof picture === 'object' && picture !== null) {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = pictureType in picture ? picture[pictureType] : [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }

  setSubscriptionEvents() {
    if (isEmpty(this.subscriptions)) {
      this.subscriptionEvents = {};
      return;
    }

    var items = this.subscriptions.flatMap((subscription) => {
      let retval = [
        {
          id: 'subscribed',
          type: 'subscriber',
          date: subscription.starts_at,
          lang: 'users.pages.users.status.label.subscribed',
        },
      ];

      if (!isNull(subscription.ends_at)) {
        if (new Date().getTime() > new Date(subscription.ends_at).getTime()) {
          retval.push({
            id: 'unsubscribed',
            type: 'unsubscriber',
            date: subscription.ends_at,
            lang: 'users.pages.users.status.label.unsubscribed',
          });
        } else if (!isNull(subscription.canceled_at)) {
          retval.push({
            id: 'ongraceCancel',
            type: 'ongrace',
            date: subscription.canceled_at,
            lang: 'users.pages.users.status.label.ongrace.cancel',
          });
        } else {
          retval.push({
            id: 'ongraceEnd',
            type: 'ongrace',
            date: subscription.ends_at,
            lang: 'users.pages.users.status.label.ongrace.end',
          });
        }
      }

      return retval;
    });

    items.sort(function (first, second) {
      first = new Date(first.date);
      second = new Date(second.date);
      return first > second ? -1 : first < second ? 1 : 0;
    });

    items = items.filter((item) => item.date != null);

    this.subscriptionEvents = items;
  }

  setVouchers() {
    this.vouchers = this.subscriptions
      .filter((subscription) => subscription?.coupon?.campaign?.id
        && subscription.coupon.campaign.id !== 0)
      .map(function (subscription) {
        let voucher = subscription.coupon;
        voucher.redeemed_at = subscription.created_at;
        voucher.subscription_active = subscription.active ?? false;
        voucher.subscription_trial = subscription.trial_ends_at !== null;
        return voucher;
      });
  }
}

export function createUser(data) {
  return Object.freeze(new User(data));
}

export default User;
