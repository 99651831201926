import { DiscountCampaign } from '@/models/billing/DiscountCampaign';
import {
  DiscountCampaignService,
  GenericBillingService,
  couponService,
} from '@/services';
import { Plan } from '@/models/Plan';
import { cloneDeep, isNumber } from 'lodash';
import { difference } from '@/utils/diffTools';
import moment from 'moment-timezone';

const discountCampaignService = new DiscountCampaignService();
const genericBillingService = new GenericBillingService('discount-campaigns');

const state = {
  loading: false,
  all: { data: [], meta: { pagination: { total_pages: 0, per_page: 0 } } },
  allSaved: { data: [], meta: { pagination: { total_pages: 0, per_page: 0 } } },
  formattedCurrentNewDiscountCampaign: null,
  formattedCurrentNewVoucher: null,
  currentNewDiscountCampaign: new DiscountCampaign({}),
  savedNewDiscountCampaign: new DiscountCampaign({}),
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  diff: (state) => {
    let diff = difference(
      state.currentNewDiscountCampaign,
      state.savedNewDiscountCampaign
    );
    let diffFull = {};
    Object.keys(diff).forEach(function (key) {
      if (['starts_at', 'ends_at'].includes(key)) {
        let currentDate = state.currentNewDiscountCampaign[key];
        let savedDate = state.savedNewDiscountCampaign[key];
        if (currentDate.diff(savedDate, 'minutes') !== 0) {
          diffFull[key] = {
            old: state.savedNewDiscountCampaign[key],
            new: state.currentNewDiscountCampaign[key],
          };
        }
      } else {
        diffFull[key] = {
          old: state.savedNewDiscountCampaign[key],
          new: state.currentNewDiscountCampaign[key],
        };
      }
    });
    return diffFull;
  },
  shouldSave: (state) => {
    let diff = difference(
      state.currentNewDiscountCampaign,
      state.savedNewDiscountCampaign
    );
    ['starts_at', 'ends_at'].forEach(function (key) {
      if (key in diff) {
        let currentDate = state.currentNewDiscountCampaign[key];
        let savedDate = state.savedNewDiscountCampaign[key];
        //120 is used to override UTC + paris timezone issue
        if (
          currentDate !== null &&
          savedDate !== null &&
          Math.abs(currentDate.diff(savedDate, 'minutes')) <= 120
        ) {
          delete diff[key];
        }
      }
    });
    return Object.keys(diff).length > 0;
  },
  currentNewDiscountCampaignStatus: (state, getters, rootState) => {
    let now = moment.tz('UTC');
    let brutStartsAtNew = cloneDeep(state.currentNewDiscountCampaign.starts_at);
    let brutEndsAtNew = cloneDeep(state.currentNewDiscountCampaign.ends_at);
    let startsAt =
      brutStartsAtNew === null
        ? null
        : moment.tz(brutStartsAtNew, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    let endsAt =
      brutEndsAtNew === null
        ? null
        : moment.tz(brutEndsAtNew, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    let oldCount = cloneDeep(state.savedNewDiscountCampaign.count) ?? 0;
    let newCount = parseInt(state.currentNewDiscountCampaign.count);

    // Dates are mandatory and start has to be before end
    let validDates = startsAt !== null && endsAt !== null && startsAt < endsAt;

    // To avoid not clean tokens, we recommend to have at least 4 chars in the prefix
    let validPrefix = state.currentNewDiscountCampaign.prefix.length >= 4;

    // Valid interval is a closed list
    let validInterval = ['day', 'week', 'month', 'year'].includes(
      state.currentNewDiscountCampaign.periods.interval
    );

    // Valid target is a closed list
    let validTarget = ['ALL', 'NEW', 'OLD', 'NOW'].includes(
      state.currentNewDiscountCampaign.target_type
    );

    // Interval count has to be a strictly positive number
    let validIntervalCount =
      isNumber(state.currentNewDiscountCampaign.periods.interval_count) &&
      state.currentNewDiscountCampaign.periods.interval_count > 0;

    // A real reduction has to be applied on at least 1 cycle or plan at least one trial day, or there is no real promotion
    let validPromotion =
      (state.currentNewDiscountCampaign.type === 'classic' &&
        isNumber(state.currentNewDiscountCampaign.periods.cycle_count) &&
        ((isNumber(state.currentNewDiscountCampaign.reduction.amount_off) &&
          state.currentNewDiscountCampaign.reduction.amount_off > 0) ||
          (isNumber(state.currentNewDiscountCampaign.reduction.percent_off) &&
            state.currentNewDiscountCampaign.reduction.percent_off > 0)) &&
        state.currentNewDiscountCampaign.periods.cycle_count > 0) ||
      (state.currentNewDiscountCampaign.type === 'prepaid' &&
        isNumber(state.currentNewDiscountCampaign.periods.cycle_count) &&
        state.currentNewDiscountCampaign.periods.cycle_count > 0) ||
      (isNumber(state.currentNewDiscountCampaign.periods.trial_in_days) &&
        state.currentNewDiscountCampaign.periods.trial_in_days > 0);

    // If redemption type is unique, then the max usage must be at least once (or reduction can not be used at all)
    // If redemption type is multiple, then the user can request between 1 and 2E6 tokens. In the case of an update, we can not request less promo code than the already existing count
    let validCount =
      (state.currentNewDiscountCampaign.redemption_type === 'uniq' &&
        isNumber(state.currentNewDiscountCampaign.uses.max) &&
        state.currentNewDiscountCampaign.uses.max > 0) ||
      (state.currentNewDiscountCampaign.redemption_type === 'multiple' &&
        !isNaN(state.currentNewDiscountCampaign.count) &&
        ((newCount > 0 && newCount <= 2e6) ||
          (oldCount > 0 && newCount === oldCount)) &&
        (oldCount === 0 ? true : newCount >= oldCount));

    // A valid plan has be selected for a classic promotion
    let validPlan = false;
    let validReduction = false;
    if (state.currentNewDiscountCampaign.type === 'classic') {
      let hasPlanId =
        isNumber(state.currentNewDiscountCampaign.plan_id) &&
        state.currentNewDiscountCampaign.plan_id > 0;
      if (hasPlanId) {
        let plan = rootState.plans.all.find(
          (x) => x.id === state.currentNewDiscountCampaign.plan_id
        );
        validPlan = typeof plan !== 'undefined';
        if (validPlan) {
          console.log(plan.price);
          let diff =
            plan.price - state.currentNewDiscountCampaign.reduction.amount_off;
          validReduction =
            diff == 0 ||
            diff >= (rootState.currencies.currentOne.min_amount || 50);
        }
      }
    } else {
      validPlan = true;
      validReduction = true;
    }

    if (
      !(
        validDates &&
        validPrefix &&
        validInterval &&
        validIntervalCount &&
        validTarget &&
        validPromotion &&
        validCount &&
        validPlan &&
        validReduction
      )
    ) {
      return 'incomplete';
    } else if (startsAt > now) {
      return 'scheduled';
    } else if (endsAt < now) {
      return 'offline';
    } else {
      return 'online';
    }
  },
};

const actions = {
  search({ commit, dispatch }, { page, keyword, sort, facets, periods }) {
    commit('searchRequest');
    let queryString = 'count=10&page=' + page + '&sort=' + sort;
    if (keyword.length > 0) {
      queryString += '&query=' + keyword;
    }
    if (facets?.length > 0) {
      let filters = [];
      facets.forEach((facet) => {
        if (typeof filters[facet.facet_id] === 'undefined') {
          filters[facet.facet_id] = [];
        }
        filters[facet.facet_id].push(facet.id);
      });
      Object.keys(filters).forEach(function (key) {
        queryString += '&' + key + '=in:' + filters[key].join(',');
      });
    }
    if (periods?.length > 0) {
      periods.forEach((period) => {
        let start = moment(period.start, 'YYYY-MM-DD HH:mm:ss');
        let end = moment(period.end, 'YYYY-MM-DD HH:mm:ss');
        queryString +=
          '&' +
          period.id +
          '=' +
          start.format('x') / 1000 +
          ',' +
          end.format('x') / 1000;
      });
    }
    return discountCampaignService.search(queryString).then(
      (discountCampaigns) => {
        commit('searchSuccess', discountCampaigns);
        return Promise.resolve(discountCampaigns);
      },
      (error) => {
        commit('searchFailure', error);
        return Promise.reject(error);
      }
    );
  },
  switchStatus({ commit, dispatch }, { discountCampaignId, type }) {
    let discountCampaign = state.allSaved.data.find(
      (x) => x.id === discountCampaignId
    );
    const dateNow = moment().utc().format('YYYY-MM-DD');
    const startsAt = moment().format(dateNow + 'T03:00:00');
    discountCampaign.starts_at = moment(startsAt);
    commit('putRequest', { discountCampaign, type });
    return dispatch('updateCampaignOnly');
  },
  store({ commit, dispatch }, name) {
    commit('postRequest');
    return discountCampaignService.create({ name }).then(
      (newDiscountCampaign) => {
        commit('postSuccess', newDiscountCampaign);
        const alert = {
          id: 'discount-campaigns-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(newDiscountCampaign);
      },
      (error) => {
        commit('postFailure', error);
        const alert = {
          id: 'discount-campaigns-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  get({ commit, dispatch }, id) {
    commit('getRequest');
    return discountCampaignService.get(id).then(
      (discountCampaign) => {
        commit('getSuccess', discountCampaign);
        return Promise.resolve(discountCampaign);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
  async update({ commit, dispatch, rootState }) {
    const date =
      state.currentNewDiscountCampaign.starts_at.format('YYYY-MM-DD');
    const startsAt = moment().format(date + 'T03:00:00');
    state.currentNewDiscountCampaign.starts_at = moment(startsAt);
    if (state.savedNewDiscountCampaign.status === 'incomplete') {
      if (state.currentNewDiscountCampaign.type === 'prepaid') {
        let plan = new Plan({
          title: state.currentNewDiscountCampaign.name,
          interval: state.currentNewDiscountCampaign.periods.interval,
          interval_count:
            state.currentNewDiscountCampaign.periods.interval_count,
          trial_period_days:
            state.currentNewDiscountCampaign.periods.trial_in_days,
          group_plan_id: state.currentNewDiscountCampaign.group_plan_id,
          duration: 1,
          price: 0,
          recurrent: 0,
          active: 1,
          type: 'prepaid',
          arguments: ['Résiliable en 1 clic'],
        });
        await dispatch('plans/setCurrentOfferByPlan', plan, { root: true });
        return dispatch('plans/store', null, { root: true }).then(() => {
          commit(
            'setCurrentPlanId',
            cloneDeep(rootState.plans.currentNewPlan.id)
          );
          let input = {
            discountCampaign: state.currentNewDiscountCampaign,
            type: 'initiate',
          };
          commit('putRequest', input);
          return dispatch('createVoucher');
        });
      } else {
        let input = {
          discountCampaign: state.currentNewDiscountCampaign,
          type: 'initiate',
        };
        commit('putRequest', input);
        return dispatch('createVoucher');
      }
    } else {
      let input = {
        discountCampaign: state.currentNewDiscountCampaign,
        type: 'update',
      };
      commit('putRequest', input);
      if (state.formattedCurrentNewVoucher.id !== null) {
        return dispatch('updateVoucher');
      } else {
        return dispatch('updateCampaignOnly');
      }
    }
  },
  createVoucher({ commit, dispatch }) {
    return couponService.create(state.formattedCurrentNewVoucher).then(
      (voucher) => {
        if ('success' === (voucher.status ?? 'error')) {
          return dispatch('updateCampaignOnly');
        } else {
          commit('postFailure');
          const alert = {
            id: 'voucher-not-created',
            icon: 'close',
            type: 'error',
            message: 'notifications.create.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(voucher);
        }
      },
      (error) => {
        commit('postFailure', error);
        const alert = {
          id: 'voucher-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  updateVoucher({ commit, dispatch }) {
    return couponService
      .update(
        {
          coupon_name: state.formattedCurrentNewVoucher.token,
          redemptions_limit: state.formattedCurrentNewVoucher.redemptions_limit,
        },
        state.formattedCurrentNewVoucher.id
      )
      .then(
        (voucher) => {
          return dispatch('updateCampaignOnly');
        },
        (error) => {
          commit('putFailure', error);
          const alert = {
            id: 'voucher-not-created',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  updateCampaignOnly({ commit, dispatch }) {
    return discountCampaignService
      .update(
        state.formattedCurrentNewDiscountCampaign,
        state.formattedCurrentNewDiscountCampaign.id
      )
      .then(
        (discountCampaign) => {
          commit('putSuccess', discountCampaign);
          const alert = {
            id: 'discount-campaign-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(discountCampaign);
        },
        (error) => {
          commit('getFailure', error);
          const alert = {
            id: 'discount-campaign-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  export({ commit, rootState, dispatch }, type) {
    let user_id = rootState.auth.authUser.id;
    let discount_campaign_id = state.currentNewDiscountCampaign.id;
    commit('setLoading', true);
    return couponService
      .exportData({ user_id, discount_campaign_id, type })
      .then(
        (vouchers) => {
          commit('setLoading', false);
          const alert = {
            id: 'discount-campaign-well-exported',
            icon: 'check',
            type: 'valid',
            message: 'marketing.pages.campaign.export.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(vouchers);
        },
        (error) => {
          commit('setLoading', false);
          const alert = {
            id: 'voucher-not-exported',
            icon: 'close',
            type: 'error',
            message: 'marketing.pages.campaign.export.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  deleteCampaign({ commit, dispatch, rootState }, id) {
    commit('deleteRequest');
    return genericBillingService.delete(id).then(
      (response) => {
        commit('deleteSuccess', id);
        commit('resetCurrentDiscountCampaign');
        const alertRemoved = {
          id: 'discount-campaign-deleted',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alertRemoved, { root: true });
        return Promise.resolve();
      },
      (err) => {
        commit('deleteFailure');
        const alertRemovedFailed = {
          id: 'discount-campaign-not-deleted',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alertRemovedFailed, { root: true });
        return Promise.reject(err);
      }
    );
  },
};

const mutations = {
  reset(state) {
    state.all = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.allSaved = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.formattedCurrentNewDiscountCampaign = null;
    state.formattedCurrentNewVoucher = null;
    state.currentNewDiscountCampaign = new DiscountCampaign({});
    state.savedNewDiscountCampaign = new DiscountCampaign({});
    state.loading = false;
  },
  resetCurrentDiscountCampaign(state) {
    state.formattedCurrentNewDiscountCampaign = null;
    state.formattedCurrentNewVoucher = null;
    state.currentNewDiscountCampaign = new DiscountCampaign({});
    state.savedNewDiscountCampaign = new DiscountCampaign({});
  },
  setLoading(state, value) {
    state.loading = value;
  },

  searchRequest(state) {
    state.loading = true;
    state.all = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.allSaved = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
  },
  searchSuccess(state, discountCampaigns) {
    let datasBrut = cloneDeep(discountCampaigns.data);
    let datasClean = [];
    datasBrut.forEach(function (dataBrut) {
      datasClean.push(new DiscountCampaign(dataBrut));
    });
    discountCampaigns.data = datasClean;
    state.all = discountCampaigns;
    state.allSaved = cloneDeep(discountCampaigns);
    state.loading = false;
  },

  searchFailure(state) {
    state.all = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.allSaved = {
      data: [],
      meta: { pagination: { total_pages: 0, per_page: 0 } },
    };
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
    state.currentNewDiscountCampaign = new DiscountCampaign({});
    state.savedNewDiscountCampaign = new DiscountCampaign({});
  },
  getSuccess(state, discountCampaign) {
    state.loading = false;
    state.currentNewDiscountCampaign = new DiscountCampaign(discountCampaign);
    state.savedNewDiscountCampaign = new DiscountCampaign(discountCampaign);
  },
  getFailure(state) {
    state.loading = true;
    state.currentNewDiscountCampaign = new DiscountCampaign({});
    state.savedNewDiscountCampaign = new DiscountCampaign({});
  },

  putRequest(state, input) {
    state.loading = true;
    state.formattedCurrentNewDiscountCampaign = {};
    state.formattedCurrentNewVoucher = {};

    let starts_at = input.discountCampaign.starts_at;
    let ends_at = input.discountCampaign.ends_at;
    const now = moment.utc();

    if (input?.type === 'enable') {
      ends_at = moment('2030-12-31 23:59:59');
      if (starts_at > now) {
        //Campaign was scheduled, move start date to now minus 1 seconde
        //(because starts_at has to be strictly less than ends_at)
        starts_at = cloneDeep(now).subtract(1, 'seconds');
      }
    } else if (input?.type === 'disable') {
      ends_at = cloneDeep(now);
      if (starts_at > now) {
        //Campaign was scheduled, move start date to now minus 1 seconde
        //(because starts_at has to be strictly less than ends_at)
        starts_at = cloneDeep(now).subtract(1, 'seconds');
      }
    }

    state.formattedCurrentNewDiscountCampaign.id = input.discountCampaign.id;
    state.formattedCurrentNewDiscountCampaign.name =
      input.discountCampaign.name;
    state.formattedCurrentNewDiscountCampaign.front_name =
      input.discountCampaign.front_name;
    starts_at.local();
    state.formattedCurrentNewDiscountCampaign.starts_at = starts_at.format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    state.formattedCurrentNewDiscountCampaign.ends_at = ends_at.format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    state.formattedCurrentNewDiscountCampaign.cycle_count =
      input.discountCampaign.periods.cycle_count;
    state.formattedCurrentNewDiscountCampaign.cycle_count =
      input.discountCampaign.periods.cycle_count;
    state.formattedCurrentNewDiscountCampaign.trial_in_days =
      input.discountCampaign.periods.trial_in_days ?? 0;
    state.formattedCurrentNewDiscountCampaign.target_type =
      input.discountCampaign.target_type;

    state.formattedCurrentNewVoucher.id =
      input.discountCampaign.coupon_id ?? null;
    state.formattedCurrentNewVoucher.discount_campaign_id =
      input.discountCampaign.id;
    state.formattedCurrentNewVoucher.plan_id = input.discountCampaign.plan_id;
    state.formattedCurrentNewVoucher.type = input.discountCampaign.type;
    state.formattedCurrentNewVoucher.coupon_provider = 'stripe';
    state.formattedCurrentNewVoucher.discount_campaign_name =
      input.discountCampaign.name;
    state.formattedCurrentNewVoucher.prepaid_name = input.discountCampaign.name;
    state.formattedCurrentNewVoucher.starts_at = starts_at.unix();
    state.formattedCurrentNewVoucher.ends_at = ends_at.unix();
    state.formattedCurrentNewVoucher.cycle_count =
      input.discountCampaign.periods.cycle_count;
    state.formattedCurrentNewVoucher.number = input.discountCampaign.count;
    state.formattedCurrentNewVoucher.prefix = input.discountCampaign.prefix;
    state.formattedCurrentNewVoucher.token = input.discountCampaign.prefix;
    state.formattedCurrentNewVoucher.periodicity = 'repeating';
    state.formattedCurrentNewVoucher.digits = 10; // number of chars if count > 1
    state.formattedCurrentNewVoucher.readable = true; // use only easily readable chars
    state.formattedCurrentNewVoucher.redemptions_by_coupon =
      input.discountCampaign.uses.max ?? 1; // API manage the choice between the two values based on count
    state.formattedCurrentNewVoucher.redemptions_limit =
      input.discountCampaign.uses.max ?? 1; // API manage the choice between the two values based on count
    state.formattedCurrentNewVoucher.reduction_type = 'amount'; // API also accepts 'free' but amount is easier
    state.formattedCurrentNewVoucher.reduction =
      input.discountCampaign.reduction.amount_off ?? 0; // API also accepts 'free' but amount is easier
    state.formattedCurrentNewVoucher.duration_in_months = 1; // old API parameter, is ignored if discount_campaign_id is provided
  },
  putSuccess(state, discountCampaign) {
    state.formattedCurrentNewDiscountCampaign = null;
    state.currentNewDiscountCampaign = new DiscountCampaign(discountCampaign);
    state.savedNewDiscountCampaign = new DiscountCampaign(discountCampaign);

    let tempAll = cloneDeep(state.all.data ?? []);
    let index = tempAll.findIndex((x) => x.id === discountCampaign.id);
    if (index >= 0) {
      tempAll[index] = new DiscountCampaign(discountCampaign);
      state.all.data = tempAll;
      state.allSaved.data = cloneDeep(tempAll);
    }

    state.loading = false;
  },
  putFailure(state) {
    state.formattedCurrentNewDiscountCampaign = null;
    state.loading = false;
  },

  postRequest(state) {
    state.loading = true;
  },
  postSuccess(state, discountCampaign) {
    state.formattedCurrentNewDiscountCampaign = null;
    state.currentNewDiscountCampaign = new DiscountCampaign(discountCampaign);
    state.savedNewDiscountCampaign = new DiscountCampaign(discountCampaign);
    state.loading = false;
  },
  postFailure(state) {
    state.loading = false;
  },

  setCurrentPlanId(state, id) {
    state.currentNewDiscountCampaign.plan_id = id;
  },

  deleteRequest(state) {
    state.loading = true;
  },

  deleteSuccess(state, discountCampaignId) {
    let tempAll = cloneDeep(state.all.data ?? []);
    let index = tempAll.findIndex((x) => x.id === discountCampaignId);
    if (index >= 0) {
      tempAll.splice(index, 1);
      state.all.data = tempAll;
      state.allSaved.data = cloneDeep(tempAll);
    }
    state.loading = false;
  },

  deleteFailure(state) {
    state.loading = false;
  },
};

export const discountCampaigns = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
