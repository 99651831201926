const Jimo = {
  install(app, options) {
    const root = {};
    window.jimo = [];

    root.load = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = "https://undercity.usejimo.com/jimo-invader.js";
      window['JIMO_PROJECT_ID'] = options.key;
      document.getElementsByTagName('head')[0].appendChild(script);
    },
    
    //add user id
    root.addUserId = (id) => {
      window.jimo.push([ "set", "user:id", [id] ])
    }
    //add user  email
    root.addUserEmail = (email) => {
      window.jimo.push([ "set", "user:email", [email] ])
    }
    //add user name
    root.addUserName = (name) => {
      window.jimo.push([ "set", "user:name", [name] ])
    }
    //add user attributes
    root.addTemplate = (template_id, template_name) => {
      window.jimo.push([ "set", "user:attributes", 
        [ {template_id : template_id, template_name: template_name } ] ])
    }

    //Date when the user opened the widget for the last time
    root.getLastlastOpenDate = () => {
      window.jimo.push(['get', 'widget:lastOpenDate'])
    }

    // Open the widget
    root.openWidget = () => {
      window.jimo.push([ "do", "widget:open" ])
    }
    // Open the widget to a specific page
    // - page values : feedback
    root.openWidgetInPage = (page) => {
      window.jimo.push(['do', 'widget:open', [{ page: page }]])
    }

    // Close the widget
    root.closeWidget = () => {
      window.jimo.push([ "do", "widget:close" ])
    }

    app.config.globalProperties.$jimo = root;
  }
};

export default Jimo;