import { difference } from '@/utils/diffTools';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { BlockTransformer } from '@/transformers/BlockTransformer';
import { ItemTransformer } from '@/transformers/ItemTransformer';
import { TemplatesService, BlockService } from '@/services';
import Item from '@/models/Item';
import Block from '@/models/Block';

const blockService = new BlockService('blocks');
const templatesService = new TemplatesService();

export default {
  // Retrieve the block.
  getBlock(
    { commit, state, getters, dispatch },
    { block, platform, refresh = false }
  ) {
    const parentId = block?.linked_block_id ?? block?.id;
    if (parentId && state.downloadingBlocks.includes(parentId)) {
      return Promise.resolve();
    }
    commit('addToDownloadingBlocks', block.id);
    if (block?.linked_block_id) {
      block.placeholder = false;
      commit('updateCurrentAndSavedBlock', block);
    }

    return blockService.get(parentId, platform.id)
      .then((newBlock) => {
        commit('removeToDownloadingBlocks', block.id);
        const _block = new Block(newBlock);
        _block.setDefaultBackgroundColor(platform);
        commit('updateCurrentAndSavedBlock', _block);
        if (refresh) {
          const alert = {
            id: 'refresh-success',
            icon: 'check',
            type: 'valid',
            message: 'notifications.refresh.success',
          };
          dispatch('displayAlert', alert, { root: true });
        }
        return Promise.resolve(_block);
      }).catch((err) => {
        block.error = true
        commit('removeToDownloadingBlocks', block.id);
        commit('updateCurrentAndSavedBlock', block);
        if (refresh) {
          const alert = {
            id: 'refresh-error',
            icon: 'close',
            type: 'error',
            message: 'notifications.refresh.error',
          };
          dispatch('displayAlert', alert, { root: true });
        }
        return Promise.reject(err);
      });
  },

  // Retrieve the template with all platform blocks.
  getTemplatePlatforms(
    { commit, rootState, dispatch },
    { templateName, platformId }
  ) {
    commit('setLoading', true);
    return templatesService.getTemplatePlatforms(templateName, platformId)
      .then((templates) => {
        commit('setTemplateName', templateName);
        Object.keys(rootState.platforms.allPlatforms ?? {})
          .forEach((device) => {
            if (templates?.[device]) {
              commit('setTemplate', { template: templates?.[device], device });
              commit('setCurrentBlocks', { blocks: [], device });
              if (templates[device].blocks.length > 0) {
                templates[device].blocks.forEach((newBlock, index) => {
                  newBlock.modes = newBlock.platform_linked.map((b) => b.platform_type);
                  newBlock.placeholder = true;
                  let block = cloneDeep(newBlock);
                  if (!block.linked_block_id) {
                    block = new Block(cloneDeep(newBlock));
                  }
                  commit('addToCurrentBlocks', { block, device, index });
                });
              }
            }
          });
        commit('setLoading', false);
        commit('setSavedBlocks');
        return Promise.resolve(templates);
      },
        (error) => {
          const alert = {
            id: 'block-template-update',
            icon: 'error',
            type: 'error',
            message: 'error.template.loading',
          };
          dispatch('displayAlert', alert, { root: true });
          commit('setLoading', false);
          return Promise.reject(error);
        });
  },

  // Update the template.
  updateTemplate({ commit, state, rootState, dispatch, getters }) {
    const userId = rootState.auth.authUser?.id;
    const platform = rootState.platforms.currentPlatform;
    const references = rootState.references;
    commit('removeAllErrorsAndWarnings');
    // Add to update blocks rank.
    const addtoUpdateBlocksRanks = async function () {
      if (
        state.toCreateBlocks.length > 0 ||
        state.toCreateChildBlocks.length > 0 ||
        state.toDeleteBlocks.length > 0
      ) {
        await dispatch('addtoUpdateBlocksRanks');
      }
    };

    // Update blocks rank.
    const updateBlocksRankStatus = function () {
      if (state.toUpdateBlocksRank.length > 0) {
        return Promise.resolve(
          blockService.updateAllRank(state.toUpdateBlocksRank, userId)
        ).then((response) => {
          commit('removeToUpdateBlockRanks');
          commit('addToWarnings', response?.errors);
        },
          (e) => {
            commit('addToErrors', [e?.errors?.error]);
            return Promise.reject(e);
          });
      }
    };

    // Create new child blocks.
    const createChildBlocksStatus = function () {
      if (state.toCreateChildBlocks.length > 0) {
        return Promise.resolve(
          blockService.createMany(state.toCreateChildBlocks, userId)
        ).then((response) => {
          commit('removeToCreateChildBlocks');
          const blocks = response?.errors ? response.blocks : response;
          blocks.map((block) => {
            const newBlock = new Block(block);
            newBlock.setDefaultBackgroundColor(platform);
            commit('updateCurrentAndSavedBlock', newBlock);
          });
          commit('addToWarnings', response?.errors);
        },
          (error) => {
            commit('addToErrors', [error?.errors?.error]);
            return Promise.reject(error);
          });
      }
    };

    // Delete parent blocks and child blocks.
    const deleteBlocksStatus = function () {
      if (state.toDeleteBlocks.length > 0) {
        return Promise.resolve(
          blockService.deleteAll(state.toDeleteBlocks, userId)
        ).then((response) => {
          commit('removeToDeleteBlocks');
          commit('addToWarnings', response?.errors);
        },
          (error) => {
            commit('addToErrors', [error?.errors?.error]);
            return Promise.reject(error);
          });
      }
    };

    // Create new blocks.
    const createBlocksStatus = function () {
      if (state.toCreateBlocks.length > 0) {
        return Promise.all(
          state.toCreateBlocks.map((pBlock) => {
            const block = { ...pBlock };
            delete block?.linked_block_id;
            const bTransformer = new BlockTransformer(block, references, true);
            const blockTransformer = bTransformer.transform()
            return blockService.post(blockTransformer, block.platform_id, userId)
              .then((newBlock) => {
                const formattedBlock = new Block(newBlock);
                formattedBlock.setDefaultBackgroundColor(platform);
                formattedBlock.setDefaultSpacing(platform, state.template?.[block.device]);
                commit('updateNewBlockToCurrentAndSavedBlocks',
                  { blockId: block.id, newBlock: formattedBlock }
                );
                commit('removeToCreateBlock', block.id);
                return Promise.resolve();
              },
                (error) => {
                  commit('addToErrors', [error?.errors?.error]);
                  return Promise.reject(error);
                });
          })
        ).catch((error) => {
          Promise.reject(error);
        });
      } else {
        return Promise.resolve();
      }
    };

    // Update existing blocks.
    const updateBlocksStatus = async function () {
      if (state.toUpdateBlocks.length > 0) {
        return Promise.all(
          state.toUpdateBlocks.map(async (pBlock) => {
            const block = { ...pBlock };
            const blockId = pBlock.id;
            if (block.items.length > 0) {
              await dispatch('updateItemsBlocks', block);
            }
            const bTransformer = new BlockTransformer(block, references);
            const blockTransformer = bTransformer.transform();
            return blockService.update(blockTransformer, blockId, blockTransformer.platform_id, userId)
              .then((newBlock) => {
                const _newBlock = new Block(newBlock);
                _newBlock.setDefaultBackgroundColor(platform);
                _newBlock.setDefaultSpacing(platform, state.template?.[block.device]);
                commit('updateCurrentAndSavedBlock', _newBlock);
                commit('removeToUpdateBlock', block.id);
                return Promise.resolve();
              },
                (error) => {
                  commit('addToErrors', [error?.errors?.error]);
                  return Promise.reject(error);
                });
          })
        ).catch((error) => {
          Promise.reject(error)
        });
      } else {
        return Promise.resolve();
      }
    };

    return Promise.all(
      [updateBlocksStatus(), addtoUpdateBlocksRanks()]
    ).then((blocks) => {
      return Promise.all([
        Promise.resolve(blocks[0]),
        updateBlocksRankStatus(),
        deleteBlocksStatus(),
        createBlocksStatus(),
        createChildBlocksStatus()
      ])
    }).then((_) => {
      dispatch('templateAlert', false);
    },
      (error) => {
        dispatch('templateAlert');
      }
    ).catch((_) => {
      dispatch('templateAlert');
    });
  },

  // template alert
  templateAlert({ state, dispatch, commit }, error = true) {
    let alert = null;
    if (state.errors.length > 0 || error) {
      alert = {
        id: 'block-template-update',
        icon: 'error',
        type: 'error',
        message: 'notifications.update.error',
      };
      if (state.errors.length > 0) {
        dispatch('formatErrors');
      }
    } else if (state.warnings.length > 0) {
      alert = {
        id: 'block-template-update',
        icon: 'warning',
        type: 'warning',
        message: 'notifications.update.warning',
      };
      dispatch('formatErrors');
    } else {
      commit('setSavedBlocks');
      alert = {
        id: 'block-template-update',
        icon: 'check',
        type: 'valid',
        message: 'notifications.update.success',
      };
    }
    dispatch('displayAlert', alert, { root: true });
  },

  // Update items within the block.
  updateItemsBlocks({ commit, rootState, getters }, block) {
    const findInSavedBlocks = getters.getSavedBlocksById(block.device, block.id);
    const oldItems = findInSavedBlocks.items ?? [];
    const blockItems = [...block.items];
    const diffblock = oldItems.filter((item) =>
      blockItems.findIndex((i) => i.id === item.id) == -1);
    const items = blockItems.concat(diffblock);
    let newItems = []
    items.forEach((item, index) => {
      let _item = cloneDeep(item);
      if (_item.newItem) {
        _item.rank = index + 1;
        _item.action = 'add'
      } else {
        const deleteItem = diffblock.find((o) => o.id === item.id);
        if (deleteItem) {
          _item.action = 'delete'
        } else {
          _item.rank = index + 1;
          const oldItem = oldItems.find((o) => o.id === item.id);
          if (oldItem) {
            const diffItem = difference(new Item(_item), new Item(oldItem));
            if (Object.keys(diffItem).length == 1 && Object.keys(diffItem)[0] === 'rank') {
              _item.action = 'edit-rank'
            } else if (Object.keys(diffItem).length > 0) {
              _item.action = 'edit'
            }
          }
        }
      }
      const itemTransformer = new ItemTransformer(_item, rootState.references, block.type);
      newItems.push(itemTransformer.transform())
    });
    block.items = newItems;
    commit('addToUpdateBlocks', block);
  },

  // Delete the block.
  deleteBlocks({ commit, state, dispatch, getters }, block) {
    let platformLinked = block.platform_linked;
    if (block.new) {
      commit('removeToCreateBlock', block.id);
    }
    platformLinked.forEach(async (linked) => {
      await commit('removeToCurrentBlocks',
        { parentId: block.id, device: linked.platform_type });
      if (linked.is_parent_block && !block.new) {
        commit('addToDeleteBlock', { ...linked, linked_block_id: block.id });
        commit('removeToUpdateBlock', block.id);
      }
    });
  },

  // Add a block.
  addNewBlocks({ commit, state, dispatch, rootGetters }, block) {
    block.id = uuidv4();
    const linkedBlockId = block.id;
    let parentBlock = null;
    let platformLinked = block.modes.map((device) => {
      return {
        platform_type: device,
        rank: block.rank,
        is_parent_block: device === block.device,
        linked_block_id: linkedBlockId
      }
    });
    block.modes.forEach((device) => {
      const newBlock = cloneDeep(block);
      newBlock.template_id = state.template[device].id;
      newBlock.platform_id = rootGetters['platforms/getPlatformIdByDeviceName'](device);
      newBlock.platform_linked = platformLinked
      if (device === newBlock.device) {
        //parent block
        newBlock.id = linkedBlockId;
        newBlock.is_linked = false;
        newBlock.linked_block_id = null;
        newBlock.new = true
        parentBlock = cloneDeep(newBlock);
      } else {
        //child blocks
        newBlock.id = uuidv4();
        newBlock.is_linked = true;
        newBlock.device = device;
        newBlock.linked_block_id = linkedBlockId;

      }
      commit('addToCurrentBlocks', { block: newBlock, device, index: newBlock.rank - 1 });
    });
    commit('addToCreateBlock', parentBlock);
  },

  // Duplicate the block. 
  duplicateBlocks({ dispatch, commit, state, getters }, { block, device, rank, modes }) {
    const duplicatedBlock = cloneDeep(block);
    delete duplicatedBlock?.platform_linked;
    duplicatedBlock.rank = rank
    duplicatedBlock.device = device;
    duplicatedBlock.modes = modes;
    const items = duplicatedBlock?.items ?? [];
    duplicatedBlock.items = items.map(item => {
      delete item?.id;
      delete item?.block_id;
      const _item = new Item({
        ...item,
        duplicate: true,
      });
      return _item;
    })
    dispatch('addNewBlocks', duplicatedBlock);
    const alert = {
      id: 'block-template-duplicate',
      icon: 'check',
      type: 'valid',
      message: 'notifications.duplicate.success',
    };
    dispatch('displayAlert', alert, { root: true });
  },

  // Multi-devices: New block.
  multiDevicesNewBlock({ commit, getters }, block) {
    const cloneDeepBlock = { ...block };
    const platformLinked = cloneDeepBlock.modes.map((device) => {
      const linked = getters.getPlatformLinkedByDevice(block.platform_linked, device);
      return linked ??
      {
        platform_type: device,
        rank: block.rank,
        is_parent_block: false,
        linked_block_id: block.id
      };
    });
    cloneDeepBlock.platform_linked = platformLinked;
    commit('addToCreateBlock', cloneDeepBlock);
    commit('updateToCurrentBlocks', cloneDeepBlock);
  },

  // Multi-devices: Exiting block.
  multiDevicesExitingBlock({ dispatch, commit, state, getters, rootState, rootGetters }, { block, lastDevices }) {
    const cloneDeepBlock = { ...block };
    const platformLinked = [];
    const findInSavedBlocks = getters.getSavedBlocksById(block.device, block.id);
    const blockDevices = [...block.modes];
    const deleteDevices = lastDevices.filter(device => !blockDevices.includes(device));
    const addDevices = blockDevices.filter(device => !lastDevices.includes(device));
    const platformLinkedSavedBlocks = findInSavedBlocks.platform_linked;
    const linkedParentBlock = getters.getParentPlatformLinked(platformLinkedSavedBlocks);

    Object.keys(rootState.platforms.allPlatforms).forEach((device) => {
      const linked = getters.getPlatformLinkedByDevice(platformLinkedSavedBlocks, device);
      if (addDevices.includes(device)) {
        if (linked) {
          const oldBlock = getters.getSavedBlocksById(device, linked.block_id);
          platformLinked.push(linked);
          commit('removeToDeleteBlock', linked.block_id);
          commit('addToCurrentBlocks', { block: oldBlock, device, index: oldBlock.rank - 1 });
        } else {
          const platformId = rootGetters['platforms/getPlatformIdByDeviceName'](device);
          const newBlock = {
            id: uuidv4(),
            platform_type: device,
            platform_id: platformId,
            linked_block_id: cloneDeepBlock.id,
            platform_linked: [linkedParentBlock],
            rank: cloneDeepBlock.rank,
            active: cloneDeepBlock.active,
            type: cloneDeepBlock.type,
            is_parent_block: false,
          };
          commit('addToCreateChildBlocks', newBlock);
          commit('addToCurrentBlocks', { block: newBlock, device, index: newBlock.rank - 1 });
        }
      } else if (deleteDevices.includes(device)) {
        if (linked) {
          commit('addToDeleteBlock', { ...linked, linked_block_id: block.id });
        } else {
          commit('removeToCreateChildBlock', { parentId: block.id, device });
        }
        commit('removeToCurrentBlocks', { parentId: block.id, device });
      } else if (blockDevices.includes(device) && linked) {
        platformLinked.push(linked);
      }
    });

    cloneDeepBlock.platform_linked = platformLinked;
    commit('updateToCurrentBlocks', cloneDeepBlock);
  },

  // Multi-devices: Add and delete child blocks.
  multiDevicesBlocks({ dispatch }, { block, lastDevices }) {
    if (block.new) {
      dispatch('multiDevicesNewBlock', block);
    } else {
      dispatch('multiDevicesExitingBlock', { block, lastDevices });
    }
  },

  // Add or update the block.
  addOrUpdateBlock({ commit, state, dispatch, getters }, block) {
    delete block?.currentPlatform;
    if (!block.id) {
      // Add a new block.
      dispatch('addNewBlocks', block);
    } else if (block.new) {
      // Update the newly added block.
      commit('addToCreateBlock', block);
      commit('updateToCurrentBlocks', block);
    } else {
      // Update the existing block.
      if (block?.device) {
        const findInSavedBlocks = getters.getSavedBlocksById(block.device, block.id);
        if (findInSavedBlocks) {
          const diffBlocksGlobal = difference(block, findInSavedBlocks);
          if (Object.keys(diffBlocksGlobal).length > 0) {
            let _block = cloneDeep(block);
            if (diffBlocksGlobal?.type?.startsWith('row') && block?.items?.length > 0) {
              _block.items = block.items.map((item) => {
                item.action = 'edit'
                return item;
              });
            }
            commit('addToUpdateBlocks', _block);
            commit('updateToCurrentBlocks', _block);
          } else {
            commit('removeToUpdateBlock', block.id);
            commit('updateToCurrentBlocks', block);
          }

        }
      }
    }
  },

  // Update the Current blocks.
  async updateCurrentBlocks({ commit, dispatch }, { blocks, device }) {
    await commit('setCurrentBlocks', { blocks, device });
    await dispatch('addtoUpdateBlocksRank', device);
  },

  // Update the rank of blocks based on the device.
  async addtoUpdateBlocksRank({ commit, state, dispatch, getters }, device) {
    const blocks = cloneDeep(getters.getCurrentBlocks(device));
    blocks.forEach((block, index) => {
      if (typeof block.id === 'string' && block.rank !== index + 1) {
        commit('updateRanktoCreate', { block, rank: index + 1, device });
      } else {
        block.rank = index + 1;
        const findInSavedBlocks = getters.getSavedBlocksById(device, block.id);
        if (findInSavedBlocks) {
          if (findInSavedBlocks.rank !== block.rank) {
            const { id, rank, platform_id } = block;
            commit('addToUpdateBlockRank', { id, rank, platform_id });
          }
        }
      }
    });
  },

  // Update the rank of blocks for all devices.
  async addtoUpdateBlocksRanks({ commit, state, dispatch, getters, rootState }) {
    await commit('removeToUpdateBlockRanks');
    Object.keys(rootState.platforms.allPlatforms).forEach((device) => {
      dispatch('addtoUpdateBlocksRank', device);
    });
  },

  // clean state
  cleanState({ commit }) {
    commit('resetModified');
    commit('cleanTemplateState');
  },

  // Toggle the template's publish action.
  togglePagePublicationAction({ commit, state, dispatch }, payload) {
    const { isOnline, templateId } = payload;
    const params = { all: true, online: isOnline };
    return templatesService.update(params, templateId).then(
      () => {
        commit('togglePagePublication', isOnline);
        const message = isOnline
          ? 'notifications.publish.online.success'
          : 'notifications.publish.offline.success';
        const alert = {
          id: 'page-published',
          icon: 'rocket',
          type: 'valid',
          message,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'template-published-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.publish.online.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  // Display modal animation errors
  formatErrors({ state, commit }) {
    if (state.errors.length > 0 || state.warnings.length > 0) {
      commit('showModal', {
        componentName: 'ModalAnimationErrors',
        size: 'smd',
        closeOnClickOutside: false,
        sticky: true,
        componentParams: {
          errors: state.errors,
          warnings: state.warnings,
        },
      }, { root: true });
    }
  },
};

