<template>
  <div
    :class="[
      theme ? theme :  uiElementThemeClass,
      checked && 'bg-skin-action-accent border-skin-action-accent',
      !checked && 'bg-skin-ui border-skin-action-hover',
    ]"
    class="peer aspect-square relative inline-flex h-[14px] w-[14px] items-center border border-solid grid place-content-center transition-all text-skin-inverted disabled:border-skin-action-disabled"
  >
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity-100 transform scale-50"
      enter-to-class="opacity-100 transform scale-100"
    >
      <Icon v-if="checked" icon="check" size="xs" />
    </transition>
  </div>
</template>

<script setup>
import { useUiElement } from '@/composables/useUiElement.js';
import Icon from '@/components/icon/Icon.vue';

const props = defineProps({
  checked: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String, 
    default: null
  }
});
const { uiElementThemeClass } = useUiElement(props);
</script>
