<template>
  <section
    v-auto-animate
    class="card-accordion-item"
    :class="[
      ...cardAccordionItemClass,
      !toggleable && 'card-section',
      open ? 'card-accordion-item-opened' : 'card-accordion-item-closed',
    ]"
  >
    <fieldset :disabled="disabled">
      <header
        v-if="hasHeader"
        ref="header"
        class="card-accordion-item-header flex items-start gap-4 bg-skin-ui"
        :class="[
          sticky && 'md:sticky',
          {
            //tooltip: titleTooltip && titleTooltip.image,
          },
        ]"
        @click="toggleItem()"
      >
        <div class="card-accordion-item-header-infos flex flex-col gap-3">
          <h2
            v-if="title || $slots['title']"
            class="card-accordion-item-title inline-flex gap-2"
            :class="size !== 'xs' && 'uppercase'"
          >
            <Icon
              v-if="icon"
              :icon="icon"
              class="card-accordion-item-title-icon"
            />
            <slot name="title">
              {{ title }}
            </slot>
            <Tooltip
              v-if="titleTooltip"
              :icon="titleTooltip.icon"
              :color="titleTooltip.color ?? undefined"
              :position="titleTooltip.position"
              :align="titleTooltip.align"
              :image="titleTooltip.image"
              :message="titleTooltip.message"
            />
          </h2>
          <p
            v-if="showSubtitle"
            class="card-accordion-item-subtitle transition transition-opacity ease-in-out"
            :class="disabled && 'disabled'"
          >
            <slot name="subtitle">
              <span class="leading-tight">
                {{ subtitle }} 
                <a class="text-skin-accent underline text-sm"
                  v-if="link"
                  :href="link.href"
                  target="_blank"
                >
                  {{ t(link.text) }}
                </a>
              </span>
              <Tooltip
                v-if="subtitleTooltip"
                :icon="subtitleTooltip.icon"
                :color="subtitleTooltip.color ?? undefined"
                :position="subtitleTooltip.position"
                :align="subtitleTooltip.align"
                :image="subtitleTooltip.image"
                :message="subtitleTooltip.message"
              />
            </slot>
          </p>
        </div>
        <div
          v-if="toggleable"
          class="card-accordion-item-header-actions flex lg:basis-[140px] justify-end items-start"
        >
          <a
            class="card-accordion-item-header-actions-link flex gap-1 text-skin-accent"
            :class="`theme-${theme}`"
          >
            <span
              v-if="$isDesktop && !hideToggleLabel"
              class="card-accordion-item-header-actions-link-label whitespace-nowrap"
            >
              <template v-if="open">{{ hideLabel }}</template>
              <template v-else>{{ displayLabel }}</template>
            </span>
            <Icon
              :icon="open ? 'chevron-up-sm' : 'chevron-down-sm'"
              :size="$isDesktop ? 'xs' : 'md'"
              class="card-accordion-item-header-actions-link-icon"
            />
          </a>
        </div>
        <div v-if="$slots['actions']" class="card-accordion-item-header-actions">
          <slot name="actions" />
        </div>
      </header>
      <div
        v-if="open"
        class="card-accordion-item-content !z-0"
        :class="!open && '!z-0'"
      >
        <KeepAlive>
          <slot />
        </KeepAlive>
      </div>
    </fieldset>
  </section>
</template>

<script setup>
import { computed, inject, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Icon from '@/components/icon/Icon.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

const { t } = useI18n();
const slots = useSlots();
const route = useRoute();

const props = defineProps({
  size: { type: String, default: 'md' },
  title: String,
  titleTooltip: Object,
  subtitle: String,
  subtitleTooltip: Object,
  toggleDisplayLabel: String,
  toggleHideLabel: String,
  hideToggleLabel: Boolean,
  spacer: String,
  icon: String,
  sticky: {
    type: Boolean,
    default: true,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: null,
    required: false,
  },
  separator: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  itemKey: {
    type: [Number, String],
  },
  link: {
    type: Object, 
    default: null,
  },
});

const $isDesktop = inject('$isDesktop');
const $activeItemKey =
  props.itemKey !== undefined ? inject('$activeItemKey') : null;
const $multiple = props.itemKey !== undefined ? inject('$multiple') : false;
const toggleItemKey =
  props.itemKey !== undefined ? inject('toggleItemKey') : null;

const toggleable = computed(() => {
  return (
    toggleItemKey &&
    typeof $activeItemKey !== 'undefined' &&
    $activeItemKey !== null
  );
});

const hasHeader = computed(() => {
  return (
    props.title ||
    props.subtitle ||
    slots.title ||
    slots.subtitle ||
    toggleable.value
  );
});

const open = computed(() => {
  if (!$activeItemKey || props.itemKey === undefined) {
    return true;
  }
  if ($multiple) {
    return $activeItemKey.value.includes(props.itemKey);
  } else {
    return $activeItemKey.value === props.itemKey;
  }
});

const displayLabel = computed(
  () => props.toggleDisplayLabel || t('section.toggle.show')
);
const hideLabel = computed(
  () => props.toggleHideLabel || t('section.toggle.hide')
);

const showSubtitle = computed(() => {
  return (
    (!props.compact || (props.compact && open.value)) &&
    (props.subtitle || slots.subtitle)
  );
});

const theme = computed(() => {
  return route?.matched[0]?.meta?.theme ?? 'primary';
});
const cardAccordionItemClass = computed(() => {
  return [
    `expandable`,
    props.separator &&
      'border-b [&:not(:last-child)]:border-b-solid border-skin-ui',
    props.spacer && `spacer-${props.spacer}`,
    props.size && `card-accordion-item-${props.size}`,
    props.customClass && `${props.customClass}`,
  ];
});
const toggleItem = () => {
  if (!props.itemKey) {
    return;
  }
  toggleItemKey(props.itemKey);
};
</script>

<style lang="scss">
@import 'card_accordion_item';
</style>
